import svgIcon from './paypal.svg';

export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .paypal_button {
    padding: 5px;
    text-align: center;
    background-color: #ffc439;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .paypal_button:disabled {
    background-color: #E2E5DE;
    color: gray;
  }
  .paypal_button:hover {
    background-color: #E2E5DE;
    border-color: #1482D0;
    color: #fff;
  }
  .paypal_button:active {
    background-image: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    border-color: #006DBC;
    color: #fff;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg class="icon-lofty-component-block" width="50px" height="50px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.961 26.732h-6.739c-0.273 0-0.536-0.12-0.711-0.334s-0.246-0.487-0.197-0.76l4.321-23.505c0.257-1.285 1.313-2.139 2.637-2.139h10.371c4.168 0 6.974 2.664
        6.974 6.63 0 3.999-2.757 11.465-9.392 11.465h-4.535l-1.827 7.926c-0.098 0.421-0.47 0.717-0.903 0.717zM4.332 24.889l4.896-0 1.822-7.926c0.098-0.421 
        0.47-0.717 0.903-0.717h5.273c5.268 0 7.543-6.367 7.543-9.616 0-2.948-1.964-4.781-5.125-4.781h-10.371c-0.257 0-0.711 0.082-0.82 0.64zM13.161 32.005l-6.739 
        0c-0.274 0-0.531-0.12-0.706-0.328s-0.252-0.487-0.202-0.755l0.864-4.923c0.088-0.503 0.563-0.837 1.067-0.749s0.837 0.569 0.749 1.067l-0.673 
        3.84h4.896l1.745-8.003c0.093-0.427 0.47-0.728 0.903-0.728h5.273c5.268 0 7.543-6.367 7.543-9.616 0-2.117-0.892-3.577-2.642-4.338-0.465-0.202-0.684-0.749-0.476-1.214 0.202-0.47 0.749-0.684 1.214-0.481 2.418 1.050 3.752 3.195 3.752 6.034 0 
        3.999-2.757 11.465-9.392 11.465h-4.529l-1.745 8.003c-0.093 0.427-0.47 0.728-0.903 0.728h0zM14.37 12.909h-1.816c-0.274 0-0.531-0.12-0.711-0.334-0.175-0.208-0.252-0.487-0.202-0.755l1.214-6.739c0.077-0.438 0.46-0.76 0.908-0.76h2.937c1.11 0 1.997 0.356 2.56 1.023 0.613 0.728 0.815 1.761 0.596 3.080-0.443 3.134-2.084 4.485-5.486 4.485zM13.659 
        11.060l0.711-0c2.746 0 3.391-0.985 3.665-2.921 0.088-0.542 0.126-1.236-0.18-1.603-0.257-0.306-0.771-0.366-1.154-0.366h-2.166l-0.875 4.89z"></path>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelPaypal}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Boton de metodo de pago Paypal.'},
    disable: opts.isDisabled,
    content: `
      <button
        data-gjs-slugLofty="paypalPaymentButton"
        data-gjs-name="Pago Paypal"
        href="#"
        data-gjs-resizable="{bc: 1}"
        class="paypal_button"
      >
        <div data-gjs-slugLofty="paypalText" data-gjs-name="Texto de Paypal">${opts.defText}</div>
        <img src="${svgIcon}" style="width:40px; height: 40px;" alt="paypal" />
      </button>
    ${style}`,
  });
};
