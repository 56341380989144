/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, {useState} from 'react';
import {
  Dialog, Box, TextField,
  Button, Typography,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import iconsFromJson from '../../util/icongooglefonts.json';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100px',
    fontWeight: 'bold',
  },
  iconSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '400px',
    overflowY: 'scroll',
    flexWrap: 'wrap',
  },
  infoAboutIcons: {
    fontStyle: 'italic',
    color: 'gray',
    fontSize: '16px',
  },
}));

const ModalGoogleIcons = ({
  open, onClose, handleCloseModal, handleEditIcon,
}) => {
  const classes = useStyle();
  const [searchIcons, setSearchIcons] = useState('');
  const [prototype, setPrototype] = useState(iconsFromJson.items);
  function searchForIcons(value) {
    const setToLowerValue = value.toLowerCase();
    setSearchIcons(setToLowerValue);
    const tempState = iconsFromJson.items.filter(
      (index) => ((index.label.toLowerCase()).includes(setToLowerValue)),
    );
    setPrototype(tempState);
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Iconos de Google
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{margin: '15px', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              Seleccionar Icono deseado
            </Typography>
          </Box>
          <Box>
            <div className="my-4">
              <TextField
                fullWidth
                name="searchIcons"
                type="text"
                label="buscar"
                variant="standard"
                value={searchIcons}
                onChange={(e) => searchForIcons(e.target.value)}
              />
            </div>
            <Box className={classes.iconSection}>
              {prototype.map((index) => (
                <Box
                  style={{
                    border: '1px solid gray',
                    borderRadius: '10px',
                    fontSize: '48px',
                    padding: '10px',
                    height: '70px',
                    width: '100px',
                    margin: '20px',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleEditIcon(index.name)}
                >
                  <span className="material-icons">{index.name}</span>
                  <Box style={{fontSize: '11px', textAlign: 'center'}}>
                    {index.label}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { if (handleCloseModal) { handleCloseModal(); } }}
              className={classes.buttonStyle}
            >
              Cerrar
            </Button>
          </Box>
          <Box className={classes.infoAboutIcons}>
            Iconos Proporcionados Por Google Fonts
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalGoogleIcons;
