/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import {listPluginCalendar, listPluginsProject} from 'api-lofty';
import {calendarComponentHtml, calendarFormHtml, calendarSchedule} from './htmlComponents';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  //
  function restoreComponent(component, hide1, hide2, showComponent) {
    const componentData = component.get('components');
    const hideComponents = componentData.filter((index) => (index));
    hideComponents.forEach((index) => {
      if (index.attributes.sluglofty === hide1 || index.attributes.sluglofty === hide2) {
        index.addAttributes({style: 'display: none'});
      }
      if (index.attributes.sluglofty === showComponent) {
        index.setAttributes({style: ' '});
      }
    });
  }

  function hasExistingComponent(component, searcher) {
    let retVal = false;
    const componentData = component.get('components');
    const searchForData = componentData.filter((index) => (index));
    searchForData.forEach((index) => {
      if (index.attributes.sluglofty === searcher) {
        retVal = true;
      }
    });
    return retVal;
  }

  async function hasPluginData() {
    const resList = await listPluginsProject({token: opts.token, idProject: opts.idProject});
    let hasPlugins = false;
    resList.data.forEach((index) => {
      if (index.type === 'calendar') {
        hasPlugins = true;
      }
    });
    return hasPlugins;
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        const pluginExist = hasPluginData();
        if (pluginExist && attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('calendarComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateScript());
        this.listenTo(model, 'change:calendarState', this.configureHtml);
      },
      async updateScript() {
        try {
          const resList = await listPluginsProject({token: opts.token, idProject: opts.idProject});
          const pluginCalendar = resList.data.filter((index) => { if (index.type === 'calendar') { return index; } return null; });
          const listCalendarData = await listPluginCalendar(
            {
              idPluginProject: pluginCalendar[0]._id,
              idProject: opts.idProject,
              token: opts.token,
            },
          );
          if (!this.model.getTrait('calendarState')) {
            const hasValueStored = this.model.attributes.calendarState || '';
            const traitAddition = {
              type: 'select',
              name: 'calendarState',
              label: 'State',
              changeProp: 1,
              options: [{value: 'calendar', name: 'Calendario'}, {value: 'schedule', name: 'Seleccion de Hora'}, {value: 'form', name: 'Formulario'}],
              default: hasValueStored,
            };
            this.model.addTrait(traitAddition);
          }
          if (!this.model.getTrait('calendarPlugin')) {
            const options = listCalendarData.data.map((index) => {
              const temp = {value: index._id, name: index.label};
              return temp;
            });
            const traitPluginCalendar = {
              type: 'select',
              name: 'calendarPlugin',
              label: 'Calendario',
              changeProp: 1,
              options,
            };
            this.model.addTrait(traitPluginCalendar);
          }
        } catch (error) {
          // console.log(error);
        }
      },
      configureHtml() {
        try {
          if (this.model.attributes.sluglofty && this.model.attributes.sluglofty.includes('calendarComponent')) {
            const getSelectionData = this.model.getTrait('calendarState').attributes.value;
            switch (getSelectionData) {
              case 'calendar':
                // restoreComponent(this.model);
                // this.model.append(calendarComponentHtml);
                if (!hasExistingComponent(this.model, 'calendarTable')) {
                  this.model.append(calendarComponentHtml);
                }
                restoreComponent(this.model, 'calendarSchedule', 'calendarForm', 'calendarTable');
                break;
              case 'schedule':
                if (!hasExistingComponent(this.model, 'calendarSchedule')) {
                  this.model.append(calendarSchedule);
                }
                restoreComponent(this.model, 'calendarTable', 'calendarForm', 'calendarSchedule');
                // this.model.append(calendarSchedule);
                break;
              case 'form':
                if (!hasExistingComponent(this.model, 'calendarForm')) {
                  this.model.append(calendarFormHtml);
                }
                restoreComponent(this.model, 'calendarSchedule', 'calendarTable', 'calendarForm');
                // this.model.append(calendarFormHtml);
                break;
              default:
                break;
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
