/* eslint-disable max-len */
import {
  getOneDatabase,
  getOneEndpoint,
  listDatabases,
  listFieldsDatabase,
  listParamsEndpoint,
} from 'api-lofty';
import {restoreEndpointTrait, restoreFilterCustomEndpoint} from '../../util/componentDatabaseTraitAndCustom';
import {databaseFieldsGetComponent, getFieldsBucket} from '../../util/databaseFieldsComponent';
import {validDestinationForComponent} from '../../util/grapeJsCanvasFunctions';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function isValidItemDrop(target) {
    if (target.attributes.sluglofty === 'booleanDynamicComponent') {
      return true;
    }
    return false;
  }

  function draggableDelimiter(model) {
    if (model.attributes.sluglofty === 'itemListEndpointDatabase') {
      model.set({draggable: false});
      const itemsFromList = model.get('components');
      itemsFromList.forEach((value) => value.set({draggable: (e, d) => validDestinationForComponent(e, d, 'reactCarouselComponent')}));
    } else {
      model.set({draggable: (e, d) => validDestinationForComponent(e, d, 'reactCarouselComponent')});
    }
  }

  function setFieldsOnCarousel(model, fields) {
    const modelData = model.get('components');
    modelData?.forEach((index) => {
      if (index.attributes?.sluglofty === 'reactCarouselComponent') {
        index.append(fields);
        const getModelData = index.get('components');
        // getModelData.forEach((value) => value.set({draggable: (e, d) => validDestinationForComponent(e, d, 'reactCarouselComponent')}));
        getModelData.forEach((value) => {
          draggableDelimiter(value);
        });
      } else if (index.attributes?.sluglofty === 'booleanDynamicComponent' || index.attributes?.sluglofty === 'booleanTrueDivDynamic') {
        setFieldsOnCarousel(index, fields);
      }
    });
  }

  async function endpointTraitManager(model, fieldData, typeDB, endpointData) {
    const hasBucket = typeDB.data?.isBucket;
    let fieldFromBucket = '';
    if (hasBucket) {
      fieldFromBucket = getFieldsBucket();
    }
    if (endpointData.data.typeFunction === 'list') {
      const resFieldsReact = databaseFieldsGetComponent(fieldData);
      const structureHtml = `
        <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
          ${resFieldsReact}
          ${fieldFromBucket}
        </div>
      `;
      setFieldsOnCarousel(model, structureHtml);
    } else if (endpointData.data.typeFunction === 'customList') {
      const resFieldsReact = databaseFieldsGetComponent(fieldData);
      const structureHtml = `
        <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
          ${resFieldsReact}
          ${fieldFromBucket}
        </div>
      `;
      setFieldsOnCarousel(model, structureHtml);
      restoreFilterCustomEndpoint(model, endpointData, opts);
      const {token} = opts;
      // componentData.attributes?.attributes?.carouselParams
      const getParamData = await listParamsEndpoint({
        idEndpoint: endpointData.data._id,
        idProject: endpointData.data.idProject,
        token,
      });
      // console.log(getParamData);
      const paramValues = getParamData.data.map((index) => ({paramendpoint: {value: index.name, label: index.label, type: index.type}, state: 'useparams'}));
      model.addAttributes({carouselParams: paramValues});
    }
    // if (endpoint.data.typeFunction.includes('custom')) {
    //   restoreFilterCustomEndpoint(model, endpoint, opts);
    // } else if (model.attributes.typeOfFilterManager) {
    //   // restoreFilterTrait(this.model, type, this.model.attributes.filterAdd);
    //   typeOfFilterSetter(model, model.attributes.typeOfFilterManager, opts);
    //   //this.selectTypeOfReadManager();
    // } else if (this.model.attributes.filterAdd && !this.model.attributes.typeOfFilterManager) {
    //   typeOfFilterSetter(this.model, 'params', opts);
    //   //this.selectTypeOfReadManager();
    // } else if (endpoint.data.typeFunction !== 'list') {
    //   typeOfFilterSetter(this.model, '', opts);
    // }
  }

  async function handleEndpointForRetrieval(model) {
    try {
      const {token, idProject} = opts;
      const idDatabase = model.attributes.dbSelect;
      const endpoint = await getOneEndpoint({token, _id: model.attributes.endpointSelect});
      const typeDB = await getOneDatabase({token, _id: idDatabase});
      if (endpoint.data.typeFunction.includes('custom') && endpoint.data.responseType === 'listdatabase') {
        const fieldList = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
        endpointTraitManager(model, fieldList.data, typeDB, endpoint);
      } else {
        const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
        endpointTraitManager(model, fieldList.data, typeDB, endpoint);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  function addButtonForVariable(model) {
    const traitEliminate = {
      type: 'checkbox',
      name: 'variableButton',
      label: 'Boton para Variable',
      changeProp: 1,
    };
    model.addTrait(traitEliminate);
  }

  function addCompatibleVariables(model) {
    const varData = [];
    const comparison = model.attributes.dbSelect;
    const {localVars, globalVars} = opts;
    localVars?.filter((index) => (index.idDatabase === comparison))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--local`, value: variable._id});
      });
    globalVars?.filter((index) => (index.idDatabase === comparison))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--global`, value: variable._id});
      });
    const traitAddition = {
      type: 'select',
      name: 'arrayOptionState',
      label: 'Estado de Tipo Lista',
      changeProp: 1,
      options: varData,
      default: model?.attributes?.arrayOptionState,
    };
    model.addTrait(traitAddition);
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        // console.log(el);
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'threeSwiperComponent') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        this.listenTo(model, 'change:variableButton', this.addToVariableEnable);
        this.listenTo(model, 'change:dbSelect', this.handleDatabaseSelection);
        this.listenTo(model, 'change:endpointSelect', this.handleEndpointSelection);
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('threeSwiperComponent')) {
            this.model.set({droppable: (e) => isValidItemDrop(e)});
            const {token, idProject} = opts;
            // const idProject = opts.idProject;
            // const idPage = opts.idPage;
            const values = [];
            const resData = await listDatabases({token, idProject});
            resData.data.forEach((index) => values.push({value: index._id, name: index.label}));
            const traitAddition = {
              type: 'select',
              name: 'dbSelect',
              label: 'Seleccion de Coleccion',
              changeProp: 1,
              options: values,
              default: '',
            };
            this.model.addTrait(traitAddition);
            if (this.model.attributes?.dbSelect) {
              restoreEndpointTrait(this.model, 'Read', opts, this.model?.attributes?.endpointSelect);
            }
            if (this.model?.attributes?.endpointSelect) {
              const endpoint = await getOneEndpoint({token, _id: this.model.attributes.endpointSelect});
              if (endpoint.data.typeFunction === 'customList') {
                restoreFilterCustomEndpoint(this.model, endpoint, opts);
              }
              addButtonForVariable(this.model);
            }
            if (this.model?.attributes?.variableButton) {
              addCompatibleVariables(this.model);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async handleDatabaseSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('threeSwiperComponent')) {
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('variableButton');
            this.model.removeTrait('arrayOptionState');
            restoreEndpointTrait(this.model, 'Read', opts, this.model?.attributes?.endpointSelect);
          }
        } catch (error) {
          //
        }
      },
      async handleEndpointSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('threeSwiperComponent')) {
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('variableButton');
            this.model.removeTrait('arrayOptionState');
            handleEndpointForRetrieval(this.model);
            addButtonForVariable(this.model);
          }
        } catch (error) {
          //
        }
      },
      addToVariableEnable() {
        if (this.model.attributes.sluglofty.includes('threeSwiperComponent')) {
          const statusEliminate = this.model.getTrait('variableButton');
          const valueStatus = statusEliminate.attributes.value;
          if (valueStatus) {
            const htmlAdder = `
              <div 
                data-gjs-slugLofty="buttonSectionAddToVar"
                data-gjs-resizable="{bc: 1}"
                data-gjs-name="Contenedor Agregar a Variable"
                data-gjs-draggable="[data-gjs-sluglofty=threeSwiperComponent]"
                data-gjs-droppable="false"
                style="padding: 10px;"
              >
                <button
                  data-gjs-resizable="{bc: 1}"
                  data-gjs-slugLofty="addToVarButton"
                  data-gjs-name="Boton agregar a variable"
                  data-gjs-draggable="false"
                >
                  Agregar
                </button>
              </div>
            `;
            this.model.append(htmlAdder);
            addCompatibleVariables(this.model);
          } else {
            this.model.removeTrait('arrayOptionState');
            const componentData = this.model.get('components');
            const toEliminate = componentData.filter((componentModel) => (componentModel.attributes.sluglofty === 'buttonSectionAddToVar'));
            toEliminate[0].collection.remove(toEliminate[0]);
          }
        }
      },
    }),
  });
};
