/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */

import {listMethodPaymentProject} from 'api-lofty';
import {searchForReadDB} from '../../util/searchParentComponent';
// import {getAllReadFieldsForParams} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function relationSeeker(model) {
    try {
      const searchForParentComponent = searchForReadDB(model);
      if (searchForParentComponent) {
        const searchForObject = model.getTrait('methodPayFunction').attributes.options;
        const valueOfAttribute = model.attributes.methodPayFunction;
        const findData = searchForObject.find((index) => (index.value === valueOfAttribute));
        const idDatabase = searchForParentComponent.attributes.dbSelect;
        return (idDatabase === findData.objectData.idDatabase);
      }
    } catch (error) {
      // console.log(error);
    }
    return false;
  }

  function handleButtonDynamicPayment(varData, fieldData, model) {
    const {handlePaymentMethodDynamic} = opts;
    handlePaymentMethodDynamic(varData, fieldData, model);
  }

  function setDataHandler(model, variableData, fieldData) {
    const fieldsParamsTraits = {
      name: 'paymentMethodHandler',
      type: 'button',
      text: 'Manejar Metodo de Pago',
      command: () => handleButtonDynamicPayment(variableData, fieldData, model),
    };
    model.addTrait(fieldsParamsTraits);
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'paymentMethodButton') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        this.listenTo(model, 'change:methodPayFunction', this.seekData);
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('paymentMethodButton')) {
            this.model.removeTrait('methodPayFunction');
            this.model.removeTrait('paymentMethodHandler');
            const token = opts.token;
            const idProject = opts.idProject;
            const resMethod = await listMethodPaymentProject({project: idProject, token});
            const optionsData = [];
            // console.log(resMethod.data);
            // eslint-disable-next-line array-callback-return, consistent-return
            const filterData = resMethod.data.map((index) => {
              if (index.type === 'service' || index.type === 'shoppingcart') {
                return index;
              }
            });
            // console.log(filterData);
            filterData.forEach((index) => {
              optionsData.push({
                name: index.label,
                value: index._id,
                objectData: index,
              });
            });
            const defaultValue = this.model.attributes.methodPayFunction || '';
            const traitPaymentMethods = {
              type: 'select',
              name: 'methodPayFunction',
              label: 'Metodo de Pago',
              changeProp: 1,
              options: optionsData,
              default: defaultValue,
            };
            this.model.addTrait(traitPaymentMethods);
            if (this.model.attributes.methodPayFunction) {
              // console.log('hey');
              this.seekData();
            }
          }
        } catch (error) {
          //
        }
      },
      async seekData() {
        try {
          if (this.model.attributes.sluglofty === 'paymentMethodButton') {
            this.model.removeTrait('paymentMethodHandler');
            const selectedType = this.model.getTrait('methodPayFunction');
            const traitValue = selectedType.attributes.value;
            const localVars = opts.localVars;
            const globalVars = opts.globalVars;
            const searchForObject = this.model.getTrait('methodPayFunction').attributes.options;
            const findData = searchForObject.find((index) => (index.value === traitValue));
            if (findData.objectData.type === 'service') {
              // console.log('service');
              const isInReadComponent = relationSeeker(this.model);
              // console.log(isInReadComponent);
              if (isInReadComponent) {
                const varData = [];
                localVars.filter((index) => (index.type === 'arrayDatabase' || index.type === 'string' || index.type === 'database'))
                  .forEach((variable) => {
                    varData.push({name: `${variable.label}--${variable.typeValue}`, value: variable._id});
                  });
                globalVars.filter((index) => (index.typeValue === 'arrayDatabase' || index.typeValue === 'string' || index.typeValue === 'database'))
                  .forEach((variable) => {
                    varData.push({name: `${variable.label}--${variable.typeValue}`, value: variable._id});
                  });
                setDataHandler(this.model, varData, []);
              }
            } else {
              const varData = [];
              localVars.filter((index) => (index.typeValue === 'arrayDatabase'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--${variable.typeValue}`, value: variable._id});
                });
              globalVars.filter((index) => (index.typeValue === 'arrayDatabase'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--${variable.typeValue}`, value: variable._id});
                });
              setDataHandler(this.model, varData, []);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
