/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export default (editor, opts) => {
  const c = opts;
  const {blocks} = c;
  const bm = editor.BlockManager;
  const style = `<style>
  .crudContainerDB{
    width: 100%;
    minHeight: 70px;
    padding: 5px;
  }
  </style>
  `;
  const toAdd = (name) => blocks.indexOf(name) >= 0;
  toAdd('ReadDatabase')
    && bm.add('ReadDatabase', {
      label: `
      <div style="display: flex; justify-content: center" id="component-getdb-lofty-editor">
      <svg version="1.1" class="icon-lofty-component-block" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
        <path d="M579.76,590.703H15.895c-4.328,0-7.893-3.538-7.893-7.946V26.022c0-4.354,3.564-7.893,7.893-7.893
          H579.76c4.354,0,7.893,3.538,7.893,7.893v556.734C587.652,587.165,584.113,590.703,579.76,590.703z M23.842,574.863h547.971V33.915
          H23.842V574.863z"/>
      </g>
      <g>
      <path d="M374.242,225.338H70.535c-3.691,0-6.691-2.999-6.691-6.69s3-6.69,6.691-6.69h303.708
        c3.738,0,6.738,2.999,6.738,6.69S377.98,225.338,374.242,225.338z"/>
      <path d="M374.242,346.371H70.535c-3.691,0-6.691-3-6.691-6.691s3-6.691,6.691-6.691h303.708
        c3.738,0,6.738,3,6.738,6.691S377.98,346.371,374.242,346.371z"/>
      <path d="M374.242,467.447H70.535c-3.691,0-6.691-2.998-6.691-6.689s3-6.691,6.691-6.691h303.708
        c3.738,0,6.738,3,6.738,6.691S377.98,467.447,374.242,467.447z"/>
      <path d="M455.869,346.371h-17.58c-3.691,0-6.691-3-6.691-6.691s3-6.691,6.691-6.691h17.58
        c3.691,0,6.691,3,6.691,6.691S459.561,346.371,455.869,346.371z"/>
      <path d="M455.869,467.447h-17.58c-3.691,0-6.691-2.998-6.691-6.689s3-6.691,6.691-6.691h17.58
        c3.691,0,6.691,3,6.691,6.691S459.561,467.447,455.869,467.447z"/>
      <path d="M449.824,229.86c-1.615,0-3.275-0.601-4.568-1.8l-17.719-16.611
        c-2.699-2.492-2.859-6.737-0.322-9.459c2.537-2.723,6.783-2.815,9.459-0.323l13.012,12.182l73.828-73.367
        c2.631-2.584,6.854-2.584,9.459,0.047c2.607,2.63,2.584,6.875-0.045,9.459l-78.396,77.935
        C453.238,229.214,451.531,229.86,449.824,229.86z"/>
      </g>
    </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelGet}
      </div>`,
      category: c.category,
      attributes: {title: 'Visualiza o lee una lista de elementos contenido en la base de datos. ', id: 'component-getdb-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="ReadDBContainer" data-gjs-name="Contenedor de Lectura de Coleccion de Datos" title="crudContainer" style="min-height: 100px; padding: 10px;">
      ${style}`,
    });

  toAdd('AddDatabase')
    && bm.add('AddDatabase', {
      label: `
      <div style="display: flex; justify-content: center" id="component-postdb-lofty-editor">
      <svg class="icon-lofty-component-block" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
    <g> 
    <path d="M299.695,314.78c-1.188,0-2.378-0.266-3.462-0.847L22.43,176.16
    c-2.644-1.321-4.281-4.019-4.229-6.925c0.054-2.96,1.772-5.604,4.441-6.873L296.444,34.104c2.063-1.004,4.441-1.004,6.504,0
    L576.75,162.362c2.643,1.268,4.388,3.912,4.388,6.873c0.053,2.906-1.587,5.604-4.203,6.925L303.134,313.934
    C302.048,314.515,300.885,314.78,299.695,314.78z M43.419,169.552l256.275,128.944l256.226-128.944L299.695,49.488L43.419,169.552z
    "/>
    <path  d="M299.695,402.803c-1.188,0-2.378-0.262-3.462-0.844L22.43,264.185
    c-2.644-1.322-4.281-4.018-4.229-6.926c0.054-2.96,1.772-5.604,4.441-6.874l58.685-27.49c3.806-1.796,8.405-0.158,10.203,3.702
    c1.797,3.807,0.159,8.406-3.701,10.203l-44.409,20.776l256.275,128.945l256.226-128.945l-45.229-21.2
    c-3.833-1.797-5.473-6.343-3.675-10.202c1.797-3.807,6.345-5.446,10.205-3.648l59.528,27.86c2.643,1.27,4.388,3.914,4.388,6.874
    c0.053,2.908-1.587,5.604-4.203,6.926L303.134,401.959C302.048,402.541,300.885,402.803,299.695,402.803z"/>
    <path  d="M299.695,489.929c-1.188,0-2.378-0.262-3.462-0.791L22.43,351.31
    c-2.644-1.321-4.281-4.017-4.229-6.924c0.054-2.96,1.745-5.605,4.441-6.875l58.685-27.49c3.806-1.797,8.405-0.105,10.203,3.701
    c1.797,3.859,0.105,8.405-3.701,10.203l-44.409,20.776L299.695,473.7l256.226-128.999l-45.229-21.148
    c-3.833-1.794-5.473-6.395-3.675-10.202c1.797-3.857,6.345-5.498,10.205-3.7l59.528,27.86c2.643,1.27,4.388,3.915,4.388,6.875
    c0.053,2.907-1.587,5.603-4.203,6.924L303.134,489.138C302.048,489.667,300.885,489.929,299.695,489.929z"/>
    <path  d="M299.695,586.73c-1.188,0-2.378-0.262-3.462-0.845L22.43,448.11
    c-2.644-1.322-4.281-4.018-4.229-6.925c0.054-2.961,1.772-5.604,4.441-6.873l58.685-27.491c3.806-1.797,8.405-0.159,10.203,3.701
    c1.797,3.806,0.159,8.405-3.701,10.204l-44.409,20.776l256.275,128.945l256.226-128.945l-45.229-21.198
    c-3.833-1.8-5.473-6.345-3.675-10.152c1.797-3.859,6.345-5.551,10.205-3.702l59.528,27.862c2.643,1.27,4.388,3.912,4.388,6.873
    c0.053,2.907-1.587,5.603-4.203,6.925L303.134,585.886C302.048,586.469,300.885,586.73,299.695,586.73z"/>
    </g>
    <path d="M377.293,162.678h-71.299V92.094c0-4.506-3.815-8.145-8.51-8.145c-4.695,0-8.508,3.639-8.508,8.145
    v70.584h-71.33c-4.694,0-8.508,3.611-8.508,8.117c0,4.478,3.813,8.117,8.508,8.117h71.33v63.616c0,4.478,3.813,8.117,8.508,8.117
    c4.694,0,8.51-3.639,8.51-8.117v-63.616h71.299c4.694,0,8.51-3.639,8.51-8.117C385.803,166.29,381.987,162.678,377.293,162.678z"/>
    </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelPost}
      </div>`,
      category: c.category,
      attributes: {title: 'Añade nuevos elementos o información a la base de datos de tu página. ', id: 'component-postdb-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="AddDBContainer" data-gjs-name="Contenedor de Agregar a Coleccion de Datos" title="crudContainer" style="min-height: 100px; padding: 10px;">
      </div>
      ${style}`,
    });

  toAdd('UpdateDatabase')
    && bm.add('UpdateDatabase', {
      label: `
      <div style="display: flex; justify-content: center" id="component-putdb-lofty-editor">
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <path d="M355.073,129.168c5.07,9.099,16.64,12.35,25.738,7.279l0,0c9.102-4.939,12.35-16.51,7.279-25.609
        l-12.348-22.357c28.207,11.047,54.077,27.557,76.177,48.877c44.586,43.027,69.676,100.613,70.716,162.488
        c1.04,61.877-21.971,120.375-64.869,164.833c-30.158,31.197-67.596,52.908-109.193,63.436c-9.228,2.471-14.949,11.961-13.258,21.449
        l0,0c1.949,10.92,12.868,17.68,23.527,14.949c69.938-18.068,129.732-63.176,167.953-129.604
        c12.348-21.188,21.316-44.326,26.645-68.246c21.582-96.454-7.668-191.088-75.265-256.476
        c-21.707-20.928-46.536-37.698-73.313-50.178l16.639-2.469c10.139-1.559,17.157-10.789,15.988-20.928l-0.129-1.168
        c-1.301-10.921-11.7-18.462-22.489-16.25l-83.195,16.897c-12.479,2.473-18.979,16.381-12.739,27.56L355.073,129.168z"/>
      <path d="M178.805,548.396l-21.838,4.289c-10.142,1.949-16.771,11.957-14.691,22.098
        c2.08,10.012,11.83,16.51,21.839,14.561l89.695-17.42c12.608-2.471,18.98-16.51,12.869-27.688l-36.269-65.389
        c-5.068-9.096-16.507-12.35-25.606-7.277c-9.102,4.938-12.351,16.51-7.412,25.607l13.129,23.66
        c-29.637-11.053-56.936-27.951-80.075-50.438C85.857,427.504,60.771,369.783,59.73,308.037
        c-1.168-61.874,21.968-120.372,64.866-164.96c30.159-31.069,67.597-52.776,109.194-63.437c9.231-2.341,14.949-11.829,13.258-21.317
        c-1.948-10.92-12.869-17.679-23.526-14.952C153.847,61.312,94.048,106.29,55.832,172.324
        c-12.351,21.192-21.321,44.199-26.781,68.118c-21.838,96.714,7.28,191.611,75.137,257.126
        C126.158,518.885,151.506,535.918,178.805,548.396z"/>
    </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelPut}
      </div>`,
      category: c.category,
      attributes: {title: 'Modifica información existente dentro de la base de datos.', id: 'component-putdb-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="UpdateDBContainer" data-gjs-name="Contenedor de Actualizacion de Coleccion de Datos" title="crudContainer" style="min-height: 100px; padding: 10px;">
      </div>
      ${style}`,
    });

  toAdd('DeleteDatabase')
    && bm.add('DeleteDatabase', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <path d="M355.073,129.168c5.07,9.099,16.64,12.35,25.738,7.279l0,0c9.102-4.939,12.35-16.51,7.279-25.609
        l-12.348-22.357c28.207,11.047,54.077,27.557,76.177,48.877c44.586,43.027,69.676,100.613,70.716,162.488
        c1.04,61.877-21.971,120.375-64.869,164.833c-30.158,31.197-67.596,52.908-109.193,63.436c-9.228,2.471-14.949,11.961-13.258,21.449
        l0,0c1.949,10.92,12.868,17.68,23.527,14.949c69.938-18.068,129.732-63.176,167.953-129.604
        c12.348-21.188,21.316-44.326,26.645-68.246c21.582-96.454-7.668-191.088-75.265-256.476
        c-21.707-20.928-46.536-37.698-73.313-50.178l16.639-2.469c10.139-1.559,17.157-10.789,15.988-20.928l-0.129-1.168
        c-1.301-10.921-11.7-18.462-22.489-16.25l-83.195,16.897c-12.479,2.473-18.979,16.381-12.739,27.56L355.073,129.168z"/>
      <path d="M178.805,548.396l-21.838,4.289c-10.142,1.949-16.771,11.957-14.691,22.098
        c2.08,10.012,11.83,16.51,21.839,14.561l89.695-17.42c12.608-2.471,18.98-16.51,12.869-27.688l-36.269-65.389
        c-5.068-9.096-16.507-12.35-25.606-7.277c-9.102,4.938-12.351,16.51-7.412,25.607l13.129,23.66
        c-29.637-11.053-56.936-27.951-80.075-50.438C85.857,427.504,60.771,369.783,59.73,308.037
        c-1.168-61.874,21.968-120.372,64.866-164.96c30.159-31.069,67.597-52.776,109.194-63.437c9.231-2.341,14.949-11.829,13.258-21.317
        c-1.948-10.92-12.869-17.679-23.526-14.952C153.847,61.312,94.048,106.29,55.832,172.324
        c-12.351,21.192-21.321,44.199-26.781,68.118c-21.838,96.714,7.28,191.611,75.137,257.126
        C126.158,518.885,151.506,535.918,178.805,548.396z"/>
    </svg>

      </div>
      <div class="gjs-block-label">
        ${c.labelDelete}
      </div>`,
      category: c.category,
      attributes: {title: 'Descarta elementos, registros o contenido dentro de la base de datos de forma permanente.', id: 'component-deldb-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="DeleteDBContainer" data-gjs-name="Contenedor de Eliminacion de Coleccion de Datos" title="crudContainer" style="min-height: 100px; padding: 10px;">
      </div>
      ${style}`,
    });
};
