/* eslint-disable no-restricted-globals */
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  // const defaultType = dc.getType('default');
  // const defaultView = defaultType.view;

  // eslint-disable-next-line func-names
  const scripts = function () {
    addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp === 'span_middle' || comp === 'span_before' || comp === 'span_after' || comp === 'menu__btn') {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  };

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
        script: scripts,
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      if (el.className && el.className.includes('contentwrapperanimated')) {
        return {
          type: opts.name,
        };
      }
    },

    // view: defaultView.extend({
    //   init({model}) {
    //     ->listener
    //   },
    // }),
  });
};
