export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .formForgotPasswordDiv{
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .componentInputEmailForgotPassword{
    padding: 5px;
    background: transparent;
    width: auto;
    border: solid black;
    border-radius: 15px;
  }
  .formSubmitButtonForgotPassword {
    padding: 5px 5px;
    text-align: center;
    width: 75px;
    text-decoration: none;
    display: inline-block;
    background-image: radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%);
    border: 1px solid #0077CC;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 5px;
    margin: 4px 2px;
    cursor: pointer;
    color: #fff;
  }
  .formSubmitButtonForgotPassword:disabled {
    background-color: #E2E5DE;
    color: gray;
  }
  .formSubmitButtonForgotPassword:hover {
    background-image: linear-gradient(#51A9EE, #147BCD);
    border-color: #1482D0;
    color: #fff;
  }
  .formSubmitButtonForgotPassword:active {
    background-image: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    border-color: #006DBC;
    color: #fff;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
 <g>
   <g>
     <g>
       <path d="M447.02,206.858c-13.162,0-26.346-4.891-36.548-14.739c-20.868-20.14-21.442-53.507-1.303-74.375
         c20.14-20.868,53.506-21.453,74.396-1.313c20.857,20.15,21.441,53.518,1.303,74.386
         C474.556,201.493,460.798,206.858,447.02,206.858z M447.02,108.104c-12.102,0-24.182,4.715-33.234,14.089
         c-17.689,18.329-17.182,47.632,1.147,65.321c18.328,17.698,47.632,17.181,65.32-1.147c17.688-18.33,17.181-47.622-1.147-65.321
         C470.161,112.4,458.59,108.104,447.02,108.104z"/>
     </g>
     <g>
       <polygon points="480.099,191.146 409.059,122.569 413.52,117.965 484.548,186.532"/>
     </g>
   </g>
   <g>
     <g>
       <g>
         <path d="M441.321,270.423l-81.021-53.815c-3.048-2.031-4.859-5.432-4.859-9.099V76.516
           c0-5.001,3.049-9.484,7.707-11.306l0.818-0.31c53.395-20.879,112.709-20.879,166.127,0l3.489,1.369
           c1.656,0.641,2.462,2.496,1.811,4.152l0,0c-0.641,1.645-2.495,2.451-4.152,1.811l-2.318-0.905
           c-52.666-20.581-111.142-20.581-163.81,0l0,0c-1.965,0.773-3.268,2.672-3.268,4.792v131.049c0,1.733,0.861,3.335,2.297,4.295
           l80.027,53.152c1.723,1.149,3.976,1.149,5.697,0l80.027-53.152c1.436-0.96,2.296-2.562,2.296-4.295v-69.792
           c0-1.767,1.436-3.202,3.203-3.202h0.011c1.768,0,3.19,1.436,3.19,3.202v69.825c0,3.854-1.921,7.442-5.123,9.584l-80.756,53.639
           C449.271,272.708,444.766,272.708,441.321,270.423z"/>
       </g>
     </g>
   </g>
 </g>
 <g>
   <path d="M575.281,220.035c-4.275,0-7.748,3.494-7.748,7.82v337.169H29.455V30.879h327.454
     c4.273,0,7.748-3.466,7.748-7.793c0-4.299-3.475-7.792-7.748-7.792H21.705c-4.274,0-7.749,3.493-7.749,7.792v549.757
     c0,4.301,3.474,7.795,7.749,7.795h553.577c4.275,0,7.75-3.494,7.75-7.795V227.855C583.031,223.528,579.557,220.035,575.281,220.035
     z"/>
 </g>
 <g>
   <path d="M53.092,544.68c2.729,0,4.945-2.218,4.945-4.946V182.198c0-2.728-2.216-4.945-4.945-4.945
     c-2.728,0-4.945,2.217-4.945,4.945v357.536C48.147,542.462,50.364,544.68,53.092,544.68z"/>
   <path d="M116.08,463.003c-2.728,0-4.944,2.217-4.944,4.944c0,2.762,2.216,4.944,4.944,4.944h295.162
     c2.729,0,4.945-2.183,4.945-4.944v-89.043c0-2.762-2.217-4.944-4.945-4.944H97.631c-2.728,0-4.945,2.183-4.945,4.944v45.494
     c0,2.729,2.217,4.944,4.945,4.944c2.729,0,4.944-2.216,4.944-4.944V383.85h303.723v79.153H116.08z"/>
   <path d="M93.913,246.516v45.494c0,2.763,2.217,4.979,4.979,4.979c2.729,0,4.946-2.216,4.946-4.979V251.46
     h213.997v77.96H116.08c-2.728,0-4.944,2.218-4.944,4.945c0,2.729,2.216,4.945,4.944,4.945h206.699c2.728,0,4.945-2.217,4.945-4.945
     v-87.85c0-2.728-2.218-4.945-4.945-4.945H98.892C96.13,241.571,93.913,243.788,93.913,246.516z"/>
 </g>
 </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelFormForgot}
    </div> 
    `,
    category: opts.category,
    attributes: {
      title: 'El usuario podrá reestablecer la contraseña y recibirá instrucciones para restablecerla a su correo electrónico.',
      id: 'component-authrestorepass-lofty-editor',
    },
    content: `
      <form data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="mainFormForgotPassword" data-gjs-name="Formulario Contraseña Olvidada" style="padding: 5px;">
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formPasswordContainer" data-gjs-name="Contenedor Formulario Contraseña Olvidada" class="formForgotPasswordDiv">
          <label data-gjs-slugLofty="labelForInputEmailForgotPassword" data-gjs-name="Etiqueta de Ejemplo Formulario Contraseña Olvidada">${opts.defText}</label>
          <input data-gjs-resizable="{bc: 1}" type="email" data-gjs-slugLofty="formInputEmailForgotPass" data-gjs-name="Entrada de Correo" class="componentInputEmailForgotPassword" name="emailReqPasswordReset" />
          <button
            type="submit"
            href="/"
            data-gjs-slugLofty="formButtonForgotPassword"
            data-gjs-name="Boton del Formulario Contraseña Olvidada"
            class="formSubmitButtonForgotPassword"
            data-gjs-resizable="{bc: 1}"
          >
            <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Submit</div>
          </button>
        </div>
      </form>
    ${style}`,
  });
};
