import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalVariableParameter({
  data, params, variables, editValueHandler, open, onClose, selected,
}) {
  const classes = useStyle();
  const [variableData, setVariableData] = useState([]);
  const [paramData, setParamData] = useState([]);
  const [typeHandler, setTypeHandler] = useState(selected);
  const typesOfFields = [
    {value: 'variable', label: 'Variable'},
    // {value: 'field', label: 'Campo'},
  ];
  const handleState = (value) => {
    setTypeHandler(value);
  };

  useEffect(() => {
    setVariableData(variables);
    setParamData(params);
  });
  useEffect(() => {
    setTypeHandler(selected);
  }, [selected]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Manejar dato con Variable/Parametro
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '275px', overflowY: 'scroll',
          }}
        >
          <div className="my-4">
            <TextField onChange={(e) => handleState(e.target.value)} defaultValue={selected} select fullWidth name="type" type="text" label="Usar Metodo con:" variant="standard">
              {
                typesOfFields.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          {typeHandler === 'field' && (
            <div className="my-4">
              <TextField select fullWidth name="type" onChange={(e) => editValueHandler(e.target.value, typeHandler)} defaultValue={data?.attributes?.attributes?.paramPayment} type="text" label="Campos:" variant="standard">
                {
                  paramData.map(({value, name}) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>
          )}
          {typeHandler === 'variable'
            && (
            <div className="my-4">
              <TextField select fullWidth name="type" onChange={(e) => editValueHandler(e.target.value, typeHandler)} defaultValue={data?.attributes?.attributes?.paramPayment} type="text" label="Variables:" variant="standard">
                {
                variableData.map(({value, name}) => (
                  <MenuItem value={value} key={value}>
                    {name}
                  </MenuItem>
                ))
              }
              </TextField>
            </div>
            )}
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
