import React from 'react';

function TopNav() {
  return (
    <nav className="navbar navbar-light">
      <div className="container-fluid">
        <div className="panel__devices" style={{backgroundColor: '#fff', color: '#000000'}} />
        <div className="panel__editor" style={{backgroundColor: '#fff', color: '#000000'}} />
        <div className="panel__basic-actions" style={{backgroundColor: '#fff', color: '#000000'}} />
      </div>
    </nav>
  );
}

export default TopNav;
