import grapesjs from 'grapesjs';
import loadMainComponents from './mainCarouselComponent';
import loadComponents from './components';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjsThreeCarrusel', (editor, opts = {}) => {
  const exists = opts.plugins?.find((item) => item.type === 'carruselthreesection');
  if (exists) {
    const options = {
      label: 'Tres Carruseles',
      name: 'threeCswiper',
      category: 'Otros',
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in options) {
      if (!(name in opts)) opts[name] = options[name];
    }
    loadBlocks(editor, options);
    loadComponents(editor, opts);
    loadMainComponents(editor, opts);
  }
});
