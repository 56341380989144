import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalLinkParams({
  data, params, editParamValue, open, onClose,
}) {
  const classes = useStyle();
  const [inputs, setInputs] = useState([]);
  const [paramData, setParamData] = useState([]);
  useEffect(() => {
    setInputs(data);
    setParamData(params);
  });
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Link con Parametros
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '400px', overflowY: 'scroll',
          }}
        >
          {
              inputs.map((index) => (
                <Box component="div" className={classes.inputInfoContainer}>
                  <Box component="div" className={classes.inputTitleInfo}>
                    {index.url}
                  </Box>
                  <div className="my-4">
                    <TextField onChange={(e) => editParamValue(index, e.target.value)} value={index.param} select fullWidth name="type" type="text" label="Usar Relacion con:" variant="standard">
                      {
                          paramData.map(({name, value}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </div>
                </Box>
              ))
            }
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
