export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .inputContainer{
    width: 100px;
    padding: 5px;
  }
  .formInput{
    padding: 5px;
    margin-bottom: 10px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
        <path d="M561.337,220.38c-4.175,0-7.568,3.56-7.568,7.968V571.93H28.214V27.627h319.832
          c4.177,0,7.569-3.532,7.569-7.941c0-4.381-3.393-7.941-7.569-7.941H20.645c-4.177,0-7.569,3.56-7.569,7.941v560.214
          c0,4.38,3.392,7.941,7.569,7.941h540.692c4.177,0,7.569-3.562,7.569-7.941V228.349C568.906,223.94,565.514,220.38,561.337,220.38z"
        />
      </g>
      <g>
        <path d="M417.501,185.503c-0.644-0.137-1.226-0.452-1.7-0.938c-0.752-0.76-1.121-1.849-0.972-2.899
          l5.803-39.116c0.105-0.723,0.44-1.397,0.959-1.912L546.695,15.253c1.34-1.326,3.508-1.347,4.834-0.007l33.218,33.14
          c0.656,0.645,1.006,1.488,1.005,2.402s-0.353,1.776-0.993,2.41L459.65,178.604c-0.519,0.516-1.19,0.854-1.912,0.965l-39.015,5.953
          C418.327,185.582,417.893,185.586,417.501,185.503z M427.227,144.672l-4.95,33.405l33.325-5.092L577.527,50.796l-28.398-28.318
          L427.227,144.672z M457.229,176.191l0.046,0.01L457.229,176.191z"/>
      </g>
      <g>
        <g>
          <g>
            <path d="M360.961,249.001c-6.139,0-11.125-4.949-11.125-11.127v-21.563H80.799v21.563
              c0,6.178-4.988,11.127-11.203,11.127c-6.139,0-11.051-4.949-11.051-11.127v-32.689c0-6.139,4.912-11.127,11.051-11.127h291.365
              c6.139,0,11.127,4.988,11.127,11.127v32.689C372.088,244.052,367.1,249.001,360.961,249.001z"/>
          </g>
          <g>
            <path d="M215.316,508.445c-6.139,0-11.127-4.988-11.127-11.127V205.185
              c0-6.139,4.988-11.127,11.127-11.127s11.127,4.988,11.127,11.127v292.134C226.443,503.457,221.456,508.445,215.316,508.445z"/>
          </g>
          <g>
            <path d="M256.371,508.445h-82.108c-6.215,0-11.204-4.988-11.204-11.127s4.988-11.127,11.204-11.127h82.108
              c6.139,0,11.127,4.988,11.127,11.127S262.51,508.445,256.371,508.445z"/>
          </g>
        </g>
      </g>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelFormInput}
    </div> 
    `,
    category: opts.category,
    attributes: {
      title: 'Escribe y envía texto dentro de tu página web por medio de las variables creadas. Puedes editar el contenido a tu gusto.',
      id: 'component-inputtext-lofty-editor',
    },
    content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formInputContainer" data-gjs-name="Contenedor de Entrada" class="inputContainer">
        <div data-gjs-slugLofty="sampleTextInput" data-gjs-name="Texto de Ejemplo para Entrada">${opts.defText}</div>
        <input type="text" data-gjs-slugLofty="formInput" data-gjs-name="Entrada de Texto" class="formInput" />
      </div>
    ${style}`,
  });
};
