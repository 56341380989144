export const DELETE_TOKEN = 'DELETE_TOKEN';

export const ADD_TOKEN = 'ADD_TOKEN';

export const ADD_USER = 'ADD_USER';

export const ADMIN_DELETE_TOKEN = 'ADMIN_DELETE_TOKEN';

export const ADMIN_ADD_TOKEN = 'ADMIN_ADD_TOKEN';

export const ADMIN_ADD_USER = 'ADMIN_ADD_USER';
