/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {imageIconSVG} from './imageSvg';

export default (editor, opts) => {
  const c = opts;
  // const {blocks} = c;
  const bm = editor.BlockManager;
  const style = `<style>
  .imgDynamics{
    width: 100px;
    minHeight: 70px;
    padding: 5px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
      <div style="display: flex; justify-content: center">
      ${imageIconSVG}
      </div>
      <div class="gjs-block-label">
        ${c.labelDynImg}
      </div>`,
    category: c.category,
    attributes: {title: 'Carga imágenes desde la base de datos. ', id: 'component-dynimage-lofty-editor'},
    content: `
      <img data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="dynImage" data-gjs-name=" Imagen Dinamica" style="padding: 5px; width: 100px; height: auto" alt="" src="https://placehold.co/100x50"/>
      ${style}`,
  });
};
