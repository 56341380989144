export const calendarFormHtml = `
    <form data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="calendarForm" data-gjs-name="Calendar Form">
      <div data-gjs-slugLofty="calendarFormTitle" data-gjs-name="Titulo de Formulario" data-gjs-resizable="{bc: 1}">
        <i class="fas fa-pencil-alt"></i> 
        <h2>Formulario</h2>
      </div>
      <div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="calendarFormComponentFields"
        data-gjs-name="Seccion de campos del Formulario del Calendario"
        style="display: flex; flex-direction: column; padding: 20px;"
        data-gjs-resizable="{bc: 1}"
      >
        <div data-gjs-slugLofty="calendarFormFieldTitle" data-gjs-name="Field Title" data-gjs-resizable="{bc: 1}">Nombre</div>
        <input
          data-gjs-resizable="{bc: 1}"
          type="text"
          data-gjs-slugLofty="calendarFormInput"
          data-gjs-name="Entrada del Forumlario del Calendario"
          style="padding: 5px;margin-bottom: 10px;background: transparent;border: none;border-bottom: 1px solid #000;width: auto;"
          name="nameInputCalendar"
        />
        <div data-gjs-slugLofty="calendarFormFieldTitle" data-gjs-name="Field Title">Correo</div>
        <input
          data-gjs-resizable="{bc: 1}"
          type="email"
          data-gjs-slugLofty="calendarFormInput"
          data-gjs-name="Entrada del Forumlario del Calendario"
          style="padding: 5px;margin-bottom: 10px;background: transparent;border: none;border-bottom: 1px solid #000;width: auto;" 
          name="emailInputCalendar"
        />
        <div data-gjs-slugLofty="calendarFormFieldTitle" data-gjs-name="Field Title">Mensaje</div>
        <input
          data-gjs-resizable="{bc: 1}"
          type="text"
          data-gjs-slugLofty="calendarFormInput"
          data-gjs-name="Entrada del Forumlario del Calendario"
          style="padding: 5px;margin-bottom: 10px;background: transparent;border: none;border-bottom: 1px solid #000;width: auto;"
          name="messageInputCalendar"
        />
        <div data-gjs-slugLofty="calendarFormFieldTitle" data-gjs-name="Field Title">Teléfono</div>
        <input
          data-gjs-resizable="{bc: 1}"
          type="text"
          data-gjs-slugLofty="calendarFormInput"
          data-gjs-name="Entrada del Forumlario del Calendario"
          style="padding: 5px;margin-bottom: 10px;background: transparent;border: none;border-bottom: 1px solid #000;width: auto;" 
          name="phoneInputCalendar"
        />
        <div data-gjs-slugLofty="calendarFormFieldTitle" data-gjs-name="Field Title">Dirección</div>
        <input
          data-gjs-resizable="{bc: 1}"
          type="text"
          data-gjs-slugLofty="calendarFormInput"
          data-gjs-name="Entrada del Forumlario del Calendario"
          style="padding: 5px;margin-bottom: 10px;background: transparent;border: none;border-bottom: 1px solid #000;width: auto;"
          name="dirInputCalendar"
        />
        <button
          type="submit"
          href="/"
          data-gjs-slugLofty="calendarFormButton"
          data-gjs-name="Componnete Boton del Forumlario del Calendario"
          style="padding: 5px 5px;text-align: center;text-decoration: none; background-image: radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); border: 1px solid #0077CC;cursor: pointer;"
          data-gjs-resizable="{bc: 1}"
        >
          Submit
        </button>
      </div>
    </form>
  `;

export const calendarComponentHtml = `
<table data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="calendarTable" data-gjs-name="Calendar Table" class="calendarTable">
<thead data-gjs-slugLofty="calendarHeadSection" data-gjs-name="Seccion de Cabecera">
  <tr data-gjs-slugLofty="calendarTitleRow" data-gjs-name="Fila de Titulo del Calendario" class="gjs-jsCalendar-title-row">
    <th data-gjs-slugLofty="calendarThTitleComponent" data-gjs-name="TH Titulo del Calendario" colspan="7" class="gjs-jsCalendar-title">
      <div data-gjs-slugLofty="calendarSectionLeft" data-gjs-name="Seccion Izquierda del calendario" class="gjs-jsCalendar-title-left">
        <div data-gjs-slugLofty="calendarLeftArrow" data-gjs-name="Flecha Izquierda del Calendario" class="gjs-jsCalendar-nav-left"></div>
      </div>
      <div data-gjs-slugLofty="calendarTitleName" data-gjs-name="Nombre del Titulo del Calendario" class="gjs-jsCalendar-title-name">December</div>
      <div data-gjs-slugLofty="calendarSectionRight" data-gjs-name="Seccion Derecha del calendario" class="gjs-jsCalendar-title-right">
        <div data-gjs-slugLofty="calendarRightArrow" data-gjs-name="Flecha Derecha del Calendario" class="gjs-jsCalendar-nav-right"></div>
      </div>
    </th>
  </tr>
  <tr data-gjs-slugLofty="calendarWeekDays" data-gjs-name="Dias de la Semana" class="gjs-jsCalendar-week-days">
    <th data-gjs-slugLofty="calendarSunday" data-gjs-name="Domingo">D</th>
    <th data-gjs-slugLofty="calendarMonday" data-gjs-name="Lunes">L</th>
    <th data-gjs-slugLofty="calendarTuesday" data-gjs-name="Martes">M</th>
    <th data-gjs-slugLofty="calendarWednesday" data-gjs-name="Miercoles">M</th>
    <th data-gjs-slugLofty="calendarThursday" data-gjs-name="Jueves">J</th>
    <th data-gjs-slugLofty="calendarFriday" data-gjs-name="Viernes">V</th>
    <th data-gjs-slugLofty="calendarSaturday" data-gjs-name="Sabado">S</th>
  </tr>
</thead>
<tbody data-gjs-slugLofty="calendarDaySection" data-gjs-name="Seccion de los dias">
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-previous">27</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-previous">28</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-previous">29</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-previous">30</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">1</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">2</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">3</td>
  </tr>
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">4</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">5</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">6</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">7</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">8</td>
    <td>9</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">10</td>
  </tr>
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">11</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">12</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">13</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">14</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-current">15</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">16</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">17</td>
  </tr>
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">18</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">19</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">20</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">21</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">22</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">23</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">24</td>
  </tr>
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">25</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">26</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">27</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">28</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">29</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">30</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha">31</td>
  </tr>
  <tr data-gjs-slugLofty="calendarDaysArea" data-gjs-name="Section de fechas">
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">1</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">2</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">3</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">4</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">5</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">6</td>
    <td data-gjs-slugLofty="calendarDayPoint" data-gjs-name="Fecha" class="gjs-jsCalendar-next">7</td>
  </tr>
</tbody>
</table>
`;

export const calendarSchedule = `
<div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="calendarSchedule" data-gjs-name="Horario del Calendario" class="scheduleBasicStyle">
  <div data-gjs-slugLofty="scheduleLabel" data-gjs-name="Etiqueta del Horario" data-gjs-resizable="{bc: 1}">Seleccione una hora:</div>
  <div 
    data-gjs-slugLofty="mainScheduleBlock" 
    data-gjs-name="Bloque de horario" 
    style="display: flex; flex-direction: row; justify-content: center; width: auto"
    data-gjs-resizable="{bc: 1}"
  >
    <div 
      data-gjs-slugLofty="columnAM" 
      data-gjs-name="AM Columna" 
      style="display: flex; flex-direction: column; justify-content: space-between; margin: 5px; min-height: 200px; width: auto"
      data-gjs-resizable="{bc: 1}"
    >
      <label data-gjs-slugLofty="scheduleLabel" data-gjs-name="Etiqueta de Horario>AM</label>
      <div
        data-gjs-slugLofty="AMButtonSection"
        data-gjs-name="Seccion AM"
        style="display: flex; flex-direction: column; justify-content: space-between; margin: 5px; min-height: 200px"
        data-gjs-resizable="{bc: 1}"
      >
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          10:30
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          11:00
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          11:30
        </button>
      </div>
    </div>
    <div 
      data-gjs-slugLofty="columnPM" 
      data-gjs-name="PM Columna" 
      style="display: flex; flex-direction: column; justify-content: space-between; margin: 5px; min-height: 200px"
      data-gjs-resizable="{bc: 1}"
    >
      <label data-gjs-slugLofty="scheduleLabel" data-gjs-name="Etiqueta de Horario">PM</label>
      <div
        data-gjs-slugLofty="PMButtonSection"
        data-gjs-name="Seccion PM"
        style="display: flex; flex-direction: column; justify-content: space-between; margin: 5px; min-height: 200px"
        data-gjs-resizable="{bc: 1}"
      >
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          12:30
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Schedule Hour Button Component"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          01:00
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          01:30
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          02:00
        </button>
        <button
          type="button"
          data-gjs-slugLofty="scheduleHourButton"
          data-gjs-name="Boton de Hora"
          class="gjs-scheduleButton"
          data-gjs-resizable="{bc: 1}"
        > 
          02:30
        </button>
      </div>
    </div>
  </div>
</div>
`;
