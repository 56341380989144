/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */

// eslint-disable-next-line no-unused-vars
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  // function validOnlyForCarousel(target) {
  //   if (target.attributes.sluglofty === 'threeSwiperComponent'
  // || target.attributes.sluglofty === 'booleanDynamicComponent' ) {
  //     return true;
  //   }
  //   return false;
  // }

  function validOnlyForCarousel(target, destination) {
    try {
      if (destination.attributes.sluglofty === 'threeSwiperComponent') {
        return true;
      }
      if (destination.attributes.sluglofty === 'booleanTrueDivDynamic') {
        const parentComponent = destination.parent();
        const originComponent = parentComponent.parent();
        if (
          parentComponent !== undefined && parentComponent.attributes.sluglofty === 'booleanDynamicComponent'
          && originComponent?.attributes?.sluglofty === 'threeSwiperComponent'
        ) {
          return true;
        }
        return false;
      }
      return false;
    } catch (error) {
      // console.log(error);
      return false;
    }
  }

  dc.addType('reactCarouselComponent', {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'reactCarouselComponent') {
          return {
            type: 'reactCarouselComponent',
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('reactCarouselComponent')) {
            // console.log(this.model);
            this.model.set({draggable: (e, d) => validOnlyForCarousel(e, d)});
            this.model.set({droppable: false});
            this.model.set({stylable: false});
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
