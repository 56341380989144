/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function handlerButtonModalIcons(model) {
    const {handleCustomGoogleIcons} = opts;
    handleCustomGoogleIcons(
      model,
    );
  }

  function modalButtonForIcons(model) {
    const traitCustomEndpointHandler = {
      name: 'buttonGoogleIcons',
      type: 'button',
      text: 'Seleccionar Icono',
      full: true,
      command: () => handlerButtonModalIcons(model),
    };
    model.addTrait(traitCustomEndpointHandler);
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (
          attr['data-gjs-sluglofty']
          && (attr['data-gjs-sluglofty'].value.includes('googleFontIcon'))) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateScript());
      },
      updateScript() {
        try {
          if (this.model.attributes.sluglofty === 'googleFontIcon') {
            this.model.removeTrait('buttonGoogleIcons');
            modalButtonForIcons(this.model);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
