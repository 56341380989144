import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';
import videoComponents from './videoComponent';

export default grapesjs.plugins.add('gjs-blocks-basic', (editor, opts = {}) => {
  const config = {
    blocks: [
      'column1',
      'column2',
      'column3',
      'column3-7',
      'text',
      'link',
      'image',
      'video',
      'map',
      'container',
    ],
    flexGrid: 0,
    stylePrefix: 'gjs-',
    addBasicStyle: true,
    name: 'basicComponents',
    category: 'Básicos',
    labelColumn1: 'Bloque',
    labelColumn2: 'Columna 2',
    labelColumn3: 'Columna 3',
    labelContainer: 'Enlace Contenedor',
    labelText: 'Texto',
    labelLink: 'Enlace',
    labelImage: 'Imagen',
    labelVideo: 'Video',
    labelMap: 'Mapa',
    rowHeight: 75,
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  if (opts.token) {
    loadComponents(editor, opts);
    videoComponents(editor, opts);
  }
});
