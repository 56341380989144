import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-db-component', (editor, opts) => {
  const config = {
    blocks: [
      'ReadDatabase',
      'AddDatabase',
      'UpdateDatabase',
      'DeleteDatabase',
    ],
    name: 'dbComponent',
    stylePrefix: 'gjs-',
    labelGet: 'Leer/Listar',
    labelPost: 'Agregar',
    labelPut: 'Actualizar',
    labelDelete: 'Eliminar',
    defText: 'Sample',
    category: 'Base de Datos',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
