import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-paypal-button', (editor, opts) => {
  const isDisabled = false;
  if (opts.paypalPlugin) {
    const config = {
      blocks: [
        'paypalButton',
      ],
      name: 'paypalButton',
      stylePrefix: 'gjs-',
      labelPaypal: 'Paypal',
      defText: 'Pagar con: ',
      category: 'Pagos',
      isDisabled,
      ...opts,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in config) {
      if (!(name in opts)) opts[name] = config[name];
    }
    loadBlocks(editor, config);
    loadComponents(editor, opts);
  }
});
