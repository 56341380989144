/* eslint-disable camelcase */
// Inputs
export const main_block_1 = [
  'main_block_children',
];

export const operation_1 = [
  'custom_variables',
  'string',
  'number',
  'boolean',
  'operation',
];

export const operation_2 = [...operation_1];

export const assign_variable_1 = [
  'custom_variables',
  'string',
  'number',
  'boolean',
  'operation',
];

export const assign_variable_2 = [
  'custom_variables',
];

export const navigator_redirect_1 = [
  'string',
  'custom_variables',
  'operation',
];

// Outputs
export const operation = [
  'operation',
];

export const general_number = [
  'number',
];

export const general_string = [
  'string',
];

export const general_boolean = [
  'boolean',
];

export const general_param = [
  'param',
];

export const custom_variables = [
  'custom_variables',
];

export const general_arithmetic = [
  'arithmetic',
];

export const db_field_inputs = [
  'comparison',
  ...general_param,
  ...general_boolean,
  ...general_number,
  ...general_string,
];

export const db_comparison_inputs = [
  'Variable',
  ...general_param,
  ...general_arithmetic,
  ...general_boolean,
  ...general_number,
  ...general_string,
];

export const if_statement_inputs = [
  'Variable',
  ...general_param,
  ...general_arithmetic,
  ...general_boolean,
  ...general_number,
  ...general_string,
  ...custom_variables,
];
