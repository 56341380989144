/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('video');
  const defaultView = defaultType.view;
  const dModel = defaultType.model;
  // eslint-disable-next-line consistent-return

  function typeTester(model) {
    const {openForm} = opts;
    // console.log(openForm);
    openForm(model);
  }

  dc.addType('video', {
    model: dModel.extend(
      {
        init() {
          dModel.prototype.init.apply(this);
          this.setAttributes({src: opts.urlVideoAsset});
          this.listenTo(this, 'change:provider', this.updateTypeTraits);
          let typeTrait = this.get('traits').find((el) => el.get('name') === 'type');
          if (!typeTrait) {
            typeTrait = this.get('traits').add({
              type: 'button',
              text: 'Subir video',
              name: 'uploadVideoForBackground',
              changeProp: 1,
              command: () => typeTester(this),
            });
          }
        },
      },
    ),

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('basicVideo')) {
          return {
            type: 'video',
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:uploadVideoForBackground', this.seekFieldsFromRead());
      },
      async seekFieldsFromRead() {
        // console.log(this.model);
      },
    }),
  });
};
