import React, {useEffect, useState} from 'react';
// import {getUrlApi} from 'api-lofty';
import {connect} from 'react-redux';
import LoadingPage from './LoadingPage';
import {urlBackend} from '../config/axios';
import {addAdminToken} from '../actions/admin.auth.actions';
import {validTokenAdminEditor} from '../api/user.admin.api';
import Editor from '../components/EditorAdmin';

function EditorAdminPage({
  admintoken, addAdminTokenApp, history, match,
}) {
  const [loading, setLoading] = useState(true);
  const {params} = match;
  const {idPage} = params;
  const [url, setUrl] = useState('');
  const [token, setToken] = useState(admintoken);
  useEffect(async () => {
    try {
      if (!admintoken) {
        const params = new URLSearchParams(window.location.search);
        const tokenEditor = params.get('accesstoken');
        const resEditor = await validTokenAdminEditor({tokenEditor});
        addAdminTokenApp(resEditor);
        admintoken = resEditor.token;
        setToken(resEditor.token);
      }
      // const resUrl = await getUrlApi({token: adminToken});
      setUrl(urlBackend);
    } catch (error) {
      history.push('/error404');
      // console.log(error);
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div>
      <Editor
        url={url}
        _id={idPage}
        token={token}
      />
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addAdminTokenApp: (doc) => dispatch(addAdminToken(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorAdminPage);
