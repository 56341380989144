/* eslint-disable arrow-body-style */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  // TextField,
} from '@material-ui/core';
import ListVariables from './ListVariables';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    // margin: '5px',
    height: '500px',
  },
  rootTitle: {
    display: 'flex',
    width: '100%',
    background: '#14569E',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nameContainer: {
    display: 'flex',
    padding: '10px',
    width: '30%',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
    width: '30%',
  },
  typeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
    width: '20%',
  },
  optionsContainer: {
    display: 'flex',
    padding: '10px',
    width: '15%',
  },
  rowTitleStyle: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

export default function TableVariables({
  data, deleteVariable, variableType,
}) {
  const classes = useStyles();
  // const [searchVariable, setSearchVariable] = useState('');
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.searchContainer}>
        {/* <Box className={classes.search}>
          <TextField
            size="small"
            placeholder="Buscar.." classes={{root: classes.searchTextField}}
            onChange={(e) => setSearchVariable(e.target.value)}
          />
        </Box> */}
      </Box>
      <Box className={classes.rootTitle}>
        <Box className={classes.nameContainer}>
          <Typography className={classes.rowTitleStyle}>
            Nombre
          </Typography>
        </Box>
        <Box className={classes.labelContainer}>
          <Typography className={classes.rowTitleStyle}>
            Etiqueta
          </Typography>
        </Box>
        <Box className={classes.typeContainer}>
          <Typography className={classes.rowTitleStyle}>
            Tipo
          </Typography>
        </Box>
        <Box className={classes.optionsContainer}>
          <Typography className={classes.rowTitleStyle}>
            Acciones
          </Typography>
        </Box>
      </Box>
      <Box style={{overflowY: 'scroll', height: '400px', background: '#D7EAFF'}}>
        {data.map((index) => (
          <ListVariables
            label={index.label}
            name={index.name || index.slug}
            type={index.type || index.typeValue}
            deleteVariable={(e) => deleteVariable(e)}
            idVariable={index._id}
            variableType={variableType}
          />
        ))}
      </Box>
    </Box>
  );
}
