import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-user-component', (editor, opts) => {
  const config = {
    blocks: [
      'Authentication',
      'Register',
      'RestorePassword',
      'Logout',
      'ChangePassword',
      'UpdateUserInfo',
    ],
    name: 'userAuthComponent',
    stylePrefix: 'gjs-',
    labelAuth: 'Iniciar Sesión',
    labelLogout: 'Cerrar Sesion',
    labelRegister: 'Registrar Usuario',
    labelRestore: 'Contenedor de Recuperación de Contraseña',
    labelUpdate: 'Actualizar Contraseña',
    labelUpdateUserInfo: 'Actualizar Informacion de Usuario',
    defText: 'Sample',
    category: 'Interacción de Usuario',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
