/* eslint-disable no-else-return */

import {addSingleField} from './databaseFieldsComponent';

/* eslint-disable no-undef */
export function validDestinationForComponent(target, destination, section) {
  try {
    if (destination.attributes.sluglofty.includes(section)) {
      return true;
    }
    const parentComponent = destination.parent();
    if (parentComponent !== undefined && parentComponent.attributes.sluglofty.includes(section)) {
      return true;
    } else if (parentComponent === undefined) {
      return false;
    } else {
      return validDestination(target, parentComponent, section);
    }
  } catch (error) {
    // console.log(error);
    return false;
  }
}

/**
    =========================================================
    * Search For Inner Components
    =========================================================

    * Search for an inner component related to the parent.
    * If there is an inner component, returns true.
    * If there is no match, search through the inner component's components.
    * If there is no match at all, return false.

    =========================================================
  */
export function searchInnerComponentInfo(component, target) {
  try {
    if (component.attributes.sluglofty && component.attributes.sluglofty.includes(target)) {
      return true;
    }
    const componentData = component.get('components');
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < componentData.length; index++) {
      const retRec = searchInnerComponentInfo(componentData.models[index], target);
      if (retRec) {
        return retRec;
      }
    }
    return false;
  } catch (error) {
    // console.log(error);
    return false;
  }
}

/**
    =========================================================
    * Search For Inner Components (STRICT)
    =========================================================
    *  --  USE ONLY IF UPPER FUNCTION GIVES ERRORS   --
    * Search for ANY inner component.
    * There may be an inner component that is not related to the parent component
    * but as long as there is an inner component, the return value will be true.

    =========================================================
  */

export function strictSearchHasInnerComponent(component) {
  try {
    const componentData = component.get('components');
    if (componentData.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

/**
    =========================================================
    * Eliminate Inner Component
    =========================================================

    * Search for an inner component and eliminates it.
    * If there is an inner component, eliminate it.
    * If there is no match, search through the inner component's components.
    * If there is no match at all, return false.

    =========================================================
  */
export function eliminateInnerComponent(component, valueCompare) {
  try {
    const componentData = component.get('components');
    // eslint-disable-next-line max-len
    const toEliminate = componentData.filter((componentModel) => (componentModel.attributes.valuelofty === valueCompare || componentModel.attributes.sluglofty === valueCompare));
    // eliminateComponent.map((index) => (index.collection.remove(index)));
    if (toEliminate.length > 0) {
      // console.log(toEliminate);
      toEliminate[0].collection.remove(toEliminate[0]);
    } else {
      componentData.forEach((index) => (
        eliminateInnerComponent(index, valueCompare)
      ));
    }
  } catch (error) {
    // return false;
    // console.log(error);
  }
}

/**
    =========================================================
    * Restore Inner Component
    =========================================================

    * Adds inner component to the designated component.
    * Search through the main root until it finds the designated area.
    * If there is no match, search through the component's components.
    * If there is no match at all, return false.

    =========================================================
  */
export async function restoreInnerComponent(
  component,
  relationInfo,
  valueCompare,
  type,
  token,
  idProject,
) {
  try {
    const componentData = component.get('components');
    // eslint-disable-next-line max-len
    const toAddInner = componentData.filter((componentModel) => (componentModel.attributes.sluglofty === valueCompare));
    if (toAddInner.length > 0) {
      const htmlAdder = await addSingleField(relationInfo, type, token, idProject);
      toAddInner[0].append(htmlAdder);
      // console.log(toAddInner, htmlAdder);
    } else {
      componentData.forEach((index) => (
        restoreInnerComponent(index, relationInfo, valueCompare, type, token, idProject)
      ));
    }
  } catch (error) {
    // return false;
    // console.log(error);
  }
}

export function restoreComponentToDefault(component) {
  const componentData = component.get('components');
  const eliminateComponent = componentData.filter((index) => (index));
  eliminateComponent.map((index) => (index.collection.remove(index)));
}
