/* eslint-disable no-else-return */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import {listMethodPaypalPlugin, getOnePaypalPlugin, listFieldsDatabase} from 'api-lofty';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  // Var for avoiding trait restoration cloning
  let flagOnce = true;

  // Search for parent read db type List
  function searchForReadDB(component) {
    const parentComponent = component.parent();
    if (parentComponent !== undefined && parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
      return parentComponent;
    } else if (parentComponent === undefined) {
      return null;
    } else {
      return searchForReadDB(parentComponent);
    }
  }

  // Function handler of initial trait of component
  function addInitialTrait(defValue) {
    const traitAddition = {
      type: 'select',
      name: 'typePaypal',
      label: 'Tipo de Paypal',
      changeProp: 1,
      options: [{value: 'custom', name: 'Personalizado'}, {value: 'payment', name: 'Metodo de Pago'}],
      default: defValue,
    };
    return traitAddition;
  }

  // custom trait 'Amount'
  function addCustomAmounTrait(defValue) {
    const traitMoneyAmount = {
      type: 'number',
      min: 0,
      name: 'amountPaypal',
      label: 'Monto',
      changeProp: 1,
      default: defValue,
    };
    return traitMoneyAmount;
  }

  // custom trait 'Description for Payment'
  function addCustomDescTrait(defValue) {
    const traitDescPaypal = {
      type: 'text',
      name: 'descPaypal',
      label: 'Descripcion del pago',
      changeProp: 1,
      default: defValue,
    };
    return traitDescPaypal;
  }

  // payment trait to capture and add all payment methods
  async function addPaymentMethodTrait(defValue) {
    const resPluginProject = await getOnePaypalPlugin(
      {
        token: opts.token,
        idPluginProject: opts.paypalPlugin[0]._id,
        idProject: opts.idProject,
      },
    );
    const resListMethodPaypal = await listMethodPaypalPlugin({
      idPaypalPlugin: resPluginProject.data._id,
      token: opts.token,
      idPluginProject: opts.paypalPlugin[0]._id,
      idProject: opts.idProject,
    });
    const selectionPaypalMethod = [];
    resListMethodPaypal.data.forEach(
      (index) => (selectionPaypalMethod.push(
        {value: JSON.stringify(index), name: index.label},
      )),
    );
    const traitPaypalMethods = {
      type: 'select',
      name: 'paypalMethodSelection',
      label: 'Metodos de Pago',
      changeProp: 1,
      options: selectionPaypalMethod,
      default: defValue,
    };
    return traitPaypalMethods;
  }

  // Payment traits of value field and description field for paypal payments
  async function addFieldsForPayment(component, valueAmount, valueDesc) {
    /*
      -- SEARCH FOR DB ID and FIELDS --
    */
    const res = searchForReadDB(component);
    const fieldList = await listFieldsDatabase(
      {
        token: opts.token,
        idDatabase: res.attributes.dbSelect,
        idProject: opts.idProject,
      },
    );
    const optionsAmount = [];
    const optionsDesc = [];
    /*
      -- Capture fields of string and number for each respective field --
    */
    fieldList.data.forEach((index) => {
      if (index.type === 'string') {
        optionsDesc.push({value: index._id, name: index.label});
      } else if (index.type === 'number') {
        optionsAmount.push({value: index._id, name: index.label});
      }
    });

    /*
      Create the respective traits for the component paypal method
    */
    const traitOptionsAmount = {
      type: 'select',
      name: 'paypalMethodAmount',
      label: 'Variable de Monto',
      changeProp: 1,
      options: optionsAmount,
      default: valueAmount,
    };
    const traitOptionsDesc = {
      type: 'select',
      name: 'paypalMethodDesc',
      label: 'Variable de Descripcion de Pago',
      changeProp: 1,
      options: optionsDesc,
      default: valueDesc,
    };
    component.addTrait(traitOptionsAmount);
    component.addTrait(traitOptionsDesc);
  }

  // Restore all existing traits for the component
  async function restoreTraitData(component) {
    // var to get the component attributes
    const componentData = component.model.attributes;

    // Always add the initial trait
    if (!component.model.getTrait('typePaypal')) {
      component.model.addTrait(addInitialTrait(componentData.typePaypal));
    }

    // Verify the type of paypal component (either custom or payment)
    if (componentData.typePaypal === 'custom') {
      if (!component.model.getTrait('amountPaypal')) {
        component.model.addTrait(addCustomAmounTrait(componentData.amountPaypal));
      }
      if (!component.model.getTrait('descPaypal')) {
        component.model.addTrait(addCustomDescTrait(componentData.descPaypal));
      }
    } else if (componentData.typePaypal === 'payment') {
      if (!component.model.getTrait('paypalMethodSelection')) {
        const traitPaypalMethods = await addPaymentMethodTrait(componentData.paypalMethodSelection);
        component.model.addTrait(traitPaypalMethods);
      }
      if (componentData.paypalMethodSelection) {
        if (!component.model.getTrait('paypalMethodAmount')) {
          // eslint-disable-next-line max-len
          addFieldsForPayment(component.model, componentData.paypalMethodAmount, componentData.paypalMethodDesc);
        }
      }
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('paypalPaymentButton')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateScript());
        this.listenTo(model, 'change:typePaypal', this.configurePaypalButton);
        this.listenTo(model, 'change:paypalMethodSelection', this.getMethodFieldsPaypal);
      },
      /*
              -- INITIAL TRAIT --
        Adds a trait for the paypal button.
        It is a select with types custom or payment.
        Custom doesn't require a read db type list component.
        Payment requires a Read DB type list component.
      */
      async updateScript() {
        if (this.model.attributes.sluglofty.includes('paypalPaymentButton')) {
          if (this.model.attributes.typePaypal && flagOnce) {
            restoreTraitData(this);
            flagOnce = false;
          }
          if (!this.model.getTrait('typePaypal') && !this.model.attributes.typePaypal) {
            const traitAddition = addInitialTrait('');
            this.model.addTrait(traitAddition);
          }
        }
      },

      /*
                  -- CONFIGURE TRAITS --
        Configure the traits according to button selection.
        Custom only has 2 traits:
          *amountPaypal: the amount of currency for the payment.
          *descPaypal: the description for the reason of the payment.
        Payment has 1 trait:
          *paypalMethodSelection: captures all paypal methods created in the platform
      */
      async configurePaypalButton() {
        try {
          if (this.model.attributes.sluglofty.includes('paypalPaymentButton')) {
            // Captures data from trait typePaypal
            const traitValue = this.model.getTrait('typePaypal').attributes.value;

            if (traitValue === 'custom') {
              // ELIMINATE Traits that are not part of custom
              this.model.removeTrait('paypalMethodSelection');
              this.model.removeTrait('paypalMethodAmount');
              this.model.removeTrait('paypalMethodDesc');

              // Adds custom specific traits
              this.model.addTrait(addCustomAmounTrait(0));
              this.model.addTrait(addCustomDescTrait(''));
            } else if (traitValue === 'payment') {
              // ELIMINATE all traits that are not part of payment
              this.model.removeTrait('amountPaypal');
              this.model.removeTrait('descPaypal');

              // Search first if button is in a list read DB component
              const res = searchForReadDB(this.model);
              if (res !== undefined) {
                const traitPaypalMethods = await addPaymentMethodTrait('');
                this.model.addTrait(traitPaypalMethods);
              }
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },

      /* Get Fields for Paypal Method payment */
      async getMethodFieldsPaypal() {
        try {
          if (this.model.attributes.sluglofty.includes('paypalPaymentButton')) {
            if (!this.model.getTrait('paypalMethodAmount')) {
              addFieldsForPayment(this.model, '', '');
            }
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
