export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .gjs-jsCalendar *{
    margin:0;
    padding:0
  }
  .gjs-jsCalendar table,
  .gjs-jsCalendar table td,
  .gjs-jsCalendar table th{
    border:0
  }
  .gjs-jsCalendar table{
    background-color:#fff;
    border-collapse:collapse;
    border-radius:4px;
    box-shadow:0 0 2px rgba(0,0,0,.4);
    color:#000;
    font-family:Tahoma,Geneva,sans-serif;
    margin:5px
  }
  .gjs-jsCalendar thead .gjs-jsCalendar-title{
    height:40px;
    line-height:40px
  }
  .gjs-jsCalendar thead .gjs-jsCalendar-title-left{
    float:left
  }
  .gjs-jsCalendar thead .gjs-jsCalendar-title-right{
    float:right
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-left,
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-right{
    border-radius:10px;
    color:#999;
    cursor:pointer;
    font-family:"Courier New",Courier,monospace;
    font-size:12px;
    font-weight:700;
    height:20px;
    line-height:20px;
    margin:10px 8px;
    text-align:center;
    transition:color .2s,background-color .2s;
    width:20px
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-right:hover{
    background-color:#e2e2e2;
    color:#000
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-left{
    float:left
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-right{
    float:right
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-title-name{
    cursor:default;
    float:left;
    font-size:18px;
    font-weight:lighter;
    padding:0 20px
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-left:after{
    content:"<"
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-nav-right:after{
    content:">"
  }
  .gjs-jsCalendar thead
  .gjs-jsCalendar-week-days th{
    text-shadow:0 0 1px rgba(0,0,0,.2)
  }
  .gjs-jsCalendar tbody td,
  .gjs-jsCalendar thead
  .gjs-jsCalendar-week-days th{
    border-radius:18px;
    cursor:default;
    display:inline-block;
    font-size:12px;
    font-weight:lighter;
    height:36px;
    line-height:36px;
    margin:1px 2px;
    text-align:center;
    transition:color .1s,background-color .2s;
    width:36px
  }
  .gjs-jsCalendar tbody td:hover{
    background-color:#e6e6e6
  }
  .gjs-jsCalendar tbody td.gjs-jsCalendar-selected{
    background-color:#fff;
    border:2px solid #e6e6e6;
    box-sizing:content-box;
    height:32px;
    line-height:32px;
    width:32px
  }
  .gjs-jsCalendar tbody td.gjs-jsCalendar-current{
    background-color:#52c9ff;
    border-radius:18px;
    color:#fff
  }
  .gjs-jsCalendar tbody td.gjs-jsCalendar-next,
  .gjs-jsCalendar tbody td.gjs-jsCalendar-previous{
    color:#cacaca;
  }
  .gjs-jsCalendar tbody td.gjs-jsCalendar-next:hover,
  .gjs-jsCalendar tbody td.gjs-jsCalendar-previous:hover{
    color:#fff
  }
  .gjs-jsCalendar thead{
    display:block;
    margin:4px 4px 0 4px
  }
  .gjs-jsCalendar tbody{
    display:block;
    margin:0 4px 4px 4px
  }
  .gjs-jsCalendar ::-moz-selection{
    background:#83d8ff
  }
  .gjs-jsCalendar ::selection{
    background:#83d8ff
  }
  .gjs-jsCalendar.yellow tbody td.gjs-jsCalendar-current{
    background-color:#ffe31b
  }
  .gjs-jsCalendar.yellow ::-moz-selection{
    background:#fde74c
  }
  .gjs-jsCalendar.yellow ::selection{
    background:#fde74c
  }
  .gjs-jsCalendar.orange tbody td.gjs-jsCalendar-current{
    background-color:#ffb400
  }
  .gjs-jsCalendar.orange ::-moz-selection{
    background:#ffb400
  }
  .gjs-jsCalendar.orange ::selection{
    background:#ffb400
  }
  .gjs-jsCalendar.red tbody td.gjs-jsCalendar-current{
    background-color:#f6511d
  }
  .gjs-jsCalendar.red ::-moz-selection{
    background:#f6511d
  }
  .gjs-jsCalendar.red ::selection{
    background:#f6511d
  }
  .gjs-jsCalendar.green tbody td.gjs-jsCalendar-current{
    background-color:#7fb800
  }
  .gjs-jsCalendar.green ::-moz-selection{
    background:#7fb800
  }
  .gjs-jsCalendar.green ::selection{
    background:#7fb800
  }
  .gjs-jsCalendar.material-theme table{
    border-radius:0
  }
  .gjs-jsCalendar.material-theme thead{
    background-color:#52c9ff;
    color:#fff;
    margin:0;
    padding:4px 4px 0 4px
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title{
    display:block;
    position:relative
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-name{
    border-bottom:1px solid rgba(255,255,255,.4);
    color:#fff;
    font-size:16px;
    left:15px;
    position:absolute;
    right:15px;
    text-align:center
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-left,
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-right{
    position:absolute;
    z-index:1
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-left,
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-right{
    color:#fff
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-right:hover{
    background-color:#03a9f4
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-right{
    right:0
  }
  .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-week-days th{
    font-size:14px;
    text-shadow:none
  }
  .gjs-jsCalendar.material-theme.yellow thead{
    background-color:#ffe31b
  }
  .gjs-jsCalendar.material-theme.yellow thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.material-theme.yellow thead .gjs-jsCalendar-nav-right:hover{
    background-color:#e2ca23
  }
  .gjs-jsCalendar.material-theme.orange thead{
    background-color:#ffb400
  }
  .gjs-jsCalendar.material-theme.orange thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.material-theme.orange thead .gjs-jsCalendar-nav-right:hover{
    background-color:#d49600
  }
  .gjs-jsCalendar.material-theme.red thead{
    background-color:#f6511d
  }
  .gjs-jsCalendar.material-theme.red thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.material-theme.red thead .gjs-jsCalendar-nav-right:hover{
    background-color:#bb3d16
  }
  .gjs-jsCalendar.material-theme.green thead{
    background-color:#7fb800
  }
  .gjs-jsCalendar.material-theme.green thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.material-theme.green thead .gjs-jsCalendar-nav-right:hover{
    background-color:#639000
  }
  .gjs-jsCalendar.classic-theme table,
  .gjs-jsCalendar.classic-theme tbody td,
  .gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-current,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days th{
    border-radius:0
  }
  .gjs-jsCalendar.classic-theme thead{
    background-color:#52c9ff;
    margin:0;
    padding:4px 4px 0 4px
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-row{
    display:block;width:100%
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title{
    position:relative
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-name{
    color:#fff;
    font-size:16px;
    left:41px;
    position:absolute;
    right:41px;
    text-align:center;
    text-shadow:none
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-left,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-right{
    position:absolute;
    z-index:1
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-right{
    right:0
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right{
    color:#fff;
    text-shadow:none
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right:hover{
    background-color:#03a9f4
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days{
    background-color:#fff;
    display:block;
    margin:0 -4px
  }
  .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days th{
    font-size:10px;
    height:20px;
    line-height:20px;
    text-shadow:none
  }
  .gjs-jsCalendar.classic-theme tbody td{
    border-left:1px solid #dadada;
    border-top:1px solid #dadada;
    margin:-1px 0 0 -1px;
    padding:1px 1px 0 1px;
    width:38px
  }
  .gjs-jsCalendar.classic-theme tbody{
    margin:0
  }
  .gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-selected{
    background-color:#ccefff;
    border-bottom:0;
    border-left:1px solid #dadada;
    border-right:0;
    border-top:1px solid #dadada;
    height:36px;
    line-height:36px
  }
  .gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-current.gjs-jsCalendar-selected{
    background-color:#52c9ff;
    text-shadow:0 0 3px #000
  }
  .gjs-jsCalendar.classic-theme.yellow thead{
    background-color:#ffe31b
  }
  .gjs-jsCalendar.classic-theme.yellow thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.classic-theme.yellow thead .gjs-jsCalendar-nav-right:hover{
    background-color:#e2ca23
  }
  .gjs-jsCalendar.classic-theme.orange thead{
    background-color:#ffb400
  }
  .gjs-jsCalendar.classic-theme.orange thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.classic-theme.orange thead .gjs-jsCalendar-nav-right:hover{
    background-color:#d49600
  }
  .gjs-jsCalendar.classic-theme.red thead{
    background-color:#f6511d
  }
  .gjs-jsCalendar.classic-theme.red thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.classic-theme.red thead .gjs-jsCalendar-nav-right:hover{
    background-color:#bb3d16
  }
  .gjs-jsCalendar.classic-theme.green thead{
    background-color:#7fb800
  }
  .gjs-jsCalendar.classic-theme.green thead .gjs-jsCalendar-nav-left:hover,
  .gjs-jsCalendar.classic-theme.green thead .gjs-jsCalendar-nav-right:hover{
    background-color:#639000
  }
  .gjs-scheduleButton {
    width: 75px;
    border: 1px solid gray;
    border-radius:10px;
    background-color:#fff;
    cursor:pointer;
    color: #000;
  }
  .gjs-scheduleButton:active {
    background-color: #D6A419;
    color: #fff;
  }
  .scheduleBasicStyle {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 325px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve"
    class="icon-lofty-component-block"
    >
 <g>
   <path d="M570.729,582.406H21.925c-4.211,0-7.682-3.443-7.682-7.734V91.401c0-4.238,3.471-7.682,7.682-7.682
     h548.805c4.238,0,7.682,3.443,7.682,7.682v483.271C578.411,578.963,574.968,582.406,570.729,582.406z M29.659,566.989h533.336
     V99.136H29.659V566.989z"/>
   <path d="M435.317,140.406c-4.238,0-7.682-3.443-7.682-7.682V32.807c0-4.238,3.443-7.682,7.682-7.682
     s7.682,3.443,7.682,7.682v99.918C442.999,136.963,439.556,140.406,435.317,140.406z"/>
   <path d="M153.417,140.406c-4.238,0-7.682-3.443-7.682-7.682V32.807c0-4.238,3.443-7.682,7.682-7.682
     c4.266,0,7.684,3.443,7.684,7.682v99.918C161.101,136.963,157.683,140.406,153.417,140.406z"/>
   <g>
     <path d="M160.411,438.729v-10.913c3.496-3.021,8.688-7.523,15.576-13.404
       c6.939-5.934,12.186-10.489,15.84-13.668c3.656-3.231,8.371-7.47,14.252-12.874c5.828-5.403,10.383-9.854,13.641-13.456
       c3.26-3.55,6.914-7.788,10.941-12.715c4.025-4.928,7.1-9.378,9.219-13.351c4.873-9.536,7.258-18.914,7.258-28.079
       c0-9.219-2.703-17.006-8.16-23.47c-5.455-6.463-14.145-9.695-25.959-9.695c-11.867,0-20.926,3.603-27.23,10.702
       c-6.305,7.152-9.748,16.477-10.332,28.025h-15.045c0.742-16.688,5.828-29.615,15.258-38.728
       c9.43-9.059,21.615-13.615,36.555-13.615c14.941,0,27.047,4.186,36.291,12.609c9.271,8.423,13.881,20.29,13.881,35.654
       c0,6.675-1.379,13.615-4.08,20.82c-2.754,7.205-5.828,13.51-9.324,18.914c-3.496,5.351-8.529,11.602-15.045,18.648
       c-6.518,7.046-11.814,12.449-15.789,16.158c-4.053,3.708-9.906,8.953-17.693,15.682c-7.736,6.781-12.662,11.125-14.781,13.086
       h82.17v13.668H160.411z"/>
     <path d="M326.817,281.753c9.748-7.205,21.959-10.808,36.66-10.808c14.729,0,26.438,3.974,35.178,11.974
       c8.742,7.946,13.086,18.118,13.086,30.356c0,8.688-2.543,16.423-7.629,23.258c-5.086,6.834-11.867,11.549-20.344,14.092v1.166
       c9.695,2.437,17.271,7.522,22.729,15.258c5.51,7.734,8.211,17.801,8.211,30.251c0,12.449-4.449,22.992-13.43,31.628
       c-8.953,8.688-21.377,12.979-37.217,12.979c-15.842,0-28.795-3.974-38.834-11.92c-10.014-8-15.787-19.072-17.297-33.377h14.807
       c1.061,9.112,5.191,16.688,12.396,22.675c7.205,5.987,16.689,9.007,28.449,9.007c11.762,0,20.822-3.126,27.125-9.377
       c6.279-6.199,9.432-14.305,9.432-24.317c0-13.245-4.186-22.569-12.637-28.132c-8.396-5.563-21.059-8.317-37.906-8.317h-4.979
       v-13.403h5.191c31.416-0.159,47.15-10.119,47.15-29.827c0-9.271-2.967-16.636-8.9-22.093c-5.881-5.456-14.305-8.211-25.139-8.211
       s-19.521,2.331-25.932,6.939c-6.465,4.662-10.385,11.285-11.762,19.92H309.97C311.507,298.865,317.122,289.011,326.817,281.753z"
       />
   </g>
   <path d="M570.729,175.001H27.54c-4.291,0-7.682-3.443-7.682-7.682s3.391-7.682,7.682-7.682h543.189
     c4.238,0,7.682,3.443,7.682,7.682S574.968,175.001,570.729,175.001z"/>
 </g>
 </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.label}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Agrega un calendario y hora a tu página que podrán ser ingresados por el usuario, (por ejemplo, para agendar citas, reuniones, etc.).', id: 'component-calendar-lofty-editor'},
    content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="calendarComponent" data-gjs-name="Contenedor de Calendario" class="auto-jsCalendar gjs-jsCalendar" style="padding: 10px" gjs-jscalendar-loaded="true">
      
      </div>
    ${style}`,
  });
};
