/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {
  linkIconSvg, mapIconSvg, linkContainerIconSvg,
  videoIconSvg, textIconSvg,
} from './iconsSVG';

import {imageIconSVG} from '../dynamicImage/imageSvg';

export default (editor, opt = {}) => {
  const c = opt;
  const bm = editor.BlockManager;
  const {blocks} = c;
  const {stylePrefix} = c;
  const {flexGrid} = c;
  const basicStyle = c.addBasicStyle;
  const {rowHeight} = c;
  const clsRow = `${stylePrefix}row`;
  const clsCell = `${stylePrefix}cell`;
  const clsContainer = `${stylePrefix}container`;
  // estilo par ala raiz
  const styleRow = `
  .${clsRow} {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 10px;
  }`;
  // estilos para las celdas
  const styleClm = `
  .${clsCell} {
    min-height: ${rowHeight}px;
    width: 50%;
  }`;
  const styleContainer = `
    .${clsContainer} {
      padding: 10px;
      min-height: 100px;
      display: block;
    }
  `;

  const step = 0.2;
  const minDim = 1;
  const currentUnit = 1;
  const resizerBtm = {
    tl: 0,
    tc: 0,
    tr: 0,
    cl: 0,
    cr: 0,
    bl: 0,
    br: 0,
    minDim,
  };
  const resizerRight = {
    ...resizerBtm,
    cr: 1,
    bc: 1,
    currentUnit,
    minDim,
    step,
  };

  // Flex elements do not react on width style change therefore I use
  // 'flex-basis' as keyWidth for the resizer on columns
  if (flexGrid) {
    resizerRight.keyWidth = 'flex-basis';
  }

  const rowAttr = {
    class: clsRow,
    'data-gjs-droppable': `.${clsCell}`,
    'data-gjs-resizable': resizerBtm,
    'data-gjs-name': 'Row',
    'data-gjs-slugLofty': 'basicRow',
  };

  const colAttr = {
    class: clsCell,
    'data-gjs-draggable': `.${clsRow}`,
    'data-gjs-resizable': resizerRight,
    'data-gjs-name': 'Cell',
    'data-gjs-slugLofty': 'basicCell',
  };

  if (flexGrid) {
    colAttr['data-gjs-unstylable'] = ['width'];
    colAttr['data-gjs-stylable-require'] = ['flex-basis'];
  }

  // Make row and column classes private
  const privateCls = [`.${clsRow}`, `.${clsCell}`];
  editor.on(
    'selector:add',
    (selector) => privateCls.indexOf(selector.getFullName()) >= 0
      && selector.set('private', 1),
  );

  const attrsToString = (attrs) => {
    const result = [];

    for (const key in attrs) {
      let value = attrs[key];
      const toParse = value instanceof Array || value instanceof Object;
      value = toParse ? JSON.stringify(value) : value;
      result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`);
    }

    return result.length ? ` ${result.join(' ')}` : '';
  };

  const toAdd = (name) => blocks.indexOf(name) >= 0;
  const attrsRow = attrsToString(rowAttr);
  const attrsCell = attrsToString(colAttr);

  toAdd('column1')
    && bm.add('column1', {
      label: c.labelColumn1,
      category: c.category,
      attributes: {class: 'gjs-fonts gjs-f-b1', title: 'Agrupa y organiza el contenido de tu página.', id: 'component-block-lofty-editor'},
      content: `
        <div
          style="padding: 10px; min-height: 100px;"
          data-gjs-name="Bloque" data-gjs-slugLofty="div"
          data-gjs-type="default"
          draggable="true"
          data-gjs-resizable="{bc: 1}"
        >
        </div>
      `,
    });

  toAdd('column2')
    && bm.add('column2', {
      label: c.labelColumn2,
      attributes: {class: 'gjs-fonts gjs-f-b2', title: 'Divide el contenido de tu página en secciones verticales.', id: 'component-column2-lofty-editor'},
      category: c.category,
      content: `<div ${attrsRow}>
        <div ${attrsCell}></div>
        <div ${attrsCell}></div>
      </div>
      ${
  basicStyle
    ? `<style>
          ${styleRow}
          ${styleClm}
        </style>`
    : ''
}`,
    });

  toAdd('text')
    && bm.add('text', {
      label: `
        <div style="display: flex; justify-content: center">
          ${textIconSvg}
        </div>
        <div class="gjs-block-label">
          ${c.labelText}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Comunica los mensajes que quieres en tu página, y cambia el estilo, color y formato.', id: 'component-text-lofty-editor'},
      content: {
        type: 'text',
        slugLofty: 'basicText',
        content: 'Insert your text here',
        style: {padding: '10px', display: 'block'},
        activeOnRender: 1,
      },
    });

  toAdd('link')
    && bm.add('link', {
      label: `
        <div style="display: flex; justify-content: center">
          ${linkIconSvg}
        </div>
        <div class="gjs-block-label">
          ${c.labelLink}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Navega hacia otras páginas o recursos mediante un hipervínculo.', id: 'component-link-lofty-editor'},
      content:
          `<a
          style="color: #d983a6; padding: 5px; width: auto; height: auto; display: block"
          data-gjs-name="Link"
          data-gjs-slugLofty="basicLink"
          href="#"
          data-gjs-draggable="true"
          data-gjs-droppable="true"
          type="button"
          data-gjs-resizable="ResizerOptions:{keepAutoHeight: true, autoHeight: true}"
        >
          <div data-gjs-slugLofty="linkText" data-gjs-name="Link Text Section" style="width: auto">Link</div>
        </a>`
      ,
    });

  toAdd('image')
    && bm.add('image', {
      label: `
        <div style="display: flex; justify-content: center">
          ${imageIconSVG}
        </div>
        <div class="gjs-block-label">
          ${c.labelImage}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Agrega elementos visuales atractivos a tu página para representar lo que quieres.', id: 'component-image-lofty-editor'},
      content: {
        style: {color: 'black'},
        type: 'image',
        slugLofty: 'basicImage',
        activeOnRender: 1,
      },
    });

  toAdd('video')
    && bm.add('video', {
      label: `
        <div style="display: flex; justify-content: center">
          ${videoIconSvg}
        </div>
        <div class="gjs-block-label">
          ${c.labelVideo}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Agrega contenido audiovisual atractivo para transmitir la información que quieres de manera dinámica.', id: 'component-video-lofty-editor'},
      content: {
        type: 'video',
        src: 'img/video2.webm',
        slugLofty: 'basicVideo',
        style: {
          height: '350px',
          width: '615px',
        },
      },
    });

  toAdd('map')
    && bm.add('map', {
      label: `
        <div style="display: flex; justify-content: center">
          ${mapIconSvg}
        </div>
        <div class="gjs-block-label">
          ${c.labelMap}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Agrega información geográfica sobre una ubicación específica para orientar y dirigir a tus usuarios.', id: 'component-map-lofty-editor'},
      content: {
        type: 'map',
        slugLofty: 'basicMap',
        style: {height: '350px'},
      },
    });

  toAdd('container')
    && bm.add('container', {
      label: `
        <div style="display: flex; justify-content: center">
          ${linkContainerIconSvg}
        </div>
        <div class="gjs-block-label">
          ${c.labelContainer}
        </div>
      `,
      category: c.category,
      attributes: {title: 'Dirígete a otra página mediante un enlace que contiene diferentes elementos en tu página actual.', id: 'component-acontainer-lofty-editor'},
      content: `
        <a
          style="width: 100%; min-height: 100px; display: block;"
          data-gjs-name="Contenedor con Link"
          data-gjs-slugLofty="aContainer"
          href="#"
          data-gjs-draggable="true"
          data-gjs-droppable="true"
          data-gjs-resizable="{bc: 1}"
        >
        </a>
      ${
  basicStyle
    ? `<style>
          ${styleContainer}
        </style>`
    : ''
}`,
    });
};
