export const steps = [
  {
    title: 'Bienvenido al Editor de Lofty Apps!',
    content: 'Se hara una breve explicacion de los componentes y opciones en nuestro editor..',
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Componentes',
    content: 'Aquí están todos los componentes que puedes utilizar para crear tu página web.',
    target: '#block-tab',
    placement: 'top',
  },
  {
    title: 'Capas',
    content: 'Permite visualizar como se van integrando los componentes dentro del canvas.',
    target: '#layer-tab',
    placement: 'top',
  },
  {
    title: 'Estilos',
    content: 'Sección que permite personalizar el estilo de los componentes.',
    target: '#style-tab',
    placement: 'top',
  },
  {
    title: 'Traits/Ajustes',
    content: 'Ajustes para editar el funcionamiento de los componentes o editar atributos.',
    target: '#trait-tab',
    placement: 'top',
  },
  {
    title: 'Bloque',
    content: 'Componente para agrupar y organizar el contenido de tu página.',
    target: '#component-block-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Columna 2',
    content: 'Divide el contenido de tu página en secciones verticales.',
    target: '#component-column2-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Texto',
    content: 'Comunica los mensajes que quieres en tu página, y cambia el estilo, color y formato.',
    target: '#component-text-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Enlace',
    content: 'Componente para navegar hacia otras páginas o recursos mediante un hipervínculo.',
    target: '#component-link-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Imagen',
    content: 'Agrega elementos visuales atractivos a tu página para representar lo que quieres.',
    target: '#component-image-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Video',
    content: 'Agrega contenido audiovisual atractivo para transmitir la información que quieres de manera dinámica.',
    target: '#component-video-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Mapa',
    content: 'Agrega información geográfica sobre una ubicación específica para orientar y dirigir a tus usuarios.',
    target: '#component-map-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Enlace Contenedor',
    content: 'Dirígete a otra página mediante un enlace que contiene diferentes elementos en tu página actual.',
    target: '#component-acontainer-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Lista',
    content: 'Componente para crear listas básicas.',
    target: '#component-list-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Contenedor con Animacion',
    content: 'Contenedor que tiene animacion cuando aparece en pantalla o durante la navegacion de la pagina.',
    target: '#component-animated-scroll-container-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Modal/Dialogo',
    content: 'Un dialogo que aparece en pantalla.',
    target: '#component-modal-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Carrusel',
    content: 'Muestra contenido secuencial, como imágenes o texto, en un área visible de tu página web.',
    target: '#component-carousel-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Botón Hamburguesa con Menu',
    content: 'Menú desplegado visible más el menú hamburguesa que te muestra opciones de navegación dentro de tu página.',
    target: '#component-hamburger-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Botón Estandar',
    content: 'Componente para realizar funciones específicas dentro de tu página.',
    target: '#component-standardbutton-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Botón Enlace',
    content: 'Botón para navegar fuera de tu página o un recurso externo con un solo clic.',
    target: '#component-linkbutton-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Contenedor de Formulario',
    content: 'Estructura los campos y elementos de un formulario en tu página web.',
    target: '#component-mainform-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Entrada de Texto',
    content: 'Entrada de texto para escribir y envíar texto en un formulario o área designada dentro de tu página web.',
    target: '#component-inputtext-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Entrada de Contraseña',
    content: 'Ingresa contraseñas únicas para proteger tu privacidad.',
    target: '#component-inputpassword-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Entrada de Correo',
    content: 'Componente de entrada para ingresar correos electrónicos.',
    target: '#component-inputemail-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Entrada de multiples lineas',
    content: 'Ingresa múltiples líneas de texto en un solo campo.',
    target: '#component-textarea-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Casilla de Selección',
    content: 'Componente de tipo "checkbox" para elegir opciones predefinidas.',
    target: '#component-checkbox-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Lectura de Colección de Datos',
    content: 'Componente para recuperar la informacion de una coleccion de datos. Dicha coleccion debe tener el servicio de lectura habilitado, o no se podra utilizar el componente',
    target: '#component-getdb-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Agregar a Colección de Datos',
    content: 'Componente para enviar informacion a una coleccion de datos. Dicha coleccion debe tener el servicio de escritura habilitado, o no se podra utilizar el componente',
    target: '#component-postdb-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Actualizar Colección de Datos',
    content: 'Componente para actualizar un dato de una coleccion de datos. Dicha coleccion debe tener el servicio de actualizar habilitado, o no se podra utilizar el componente',
    target: '#component-putdb-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Eliminar de Colección de Datos',
    content: 'Componente para eliminar un dato de una coleccion de datos. Dicha coleccion debe tener el servicio de eliminar habilitado, o no se podra utilizar el componente',
    target: '#component-deldb-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Imagen de Colección de Datos',
    content: 'Componente de imagen de una colección de datos. Este debe estar dentro de un componente tipo Leer/Listar para su funcionamiento. La colección de datos tiene que ser almacenador de archivos.',
    target: '#component-dynimage-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Video de Colección de Datos',
    content: 'Componente de video donde utiliza los campos de tipo [cadena] de una colección de datos. Este debe estar dentro de un componente tipo Leer/Listar para su funcionamiento.',
    target: '#component-dynvideo-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Iniciar Sesión',
    content: 'Autentica una cuenta con tu nombre de usuario y contraseña únicos.',
    target: '#component-authlogin-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Cerrar Sesión',
    content: 'Cerrar la sesion del usuario actual.',
    target: '#component-authlogout-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Registrar Usuario',
    content: 'Componente para crear una cuenta o perfil en tu página.',
    target: '#component-authregister-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Contenedor para recuperar contraseña',
    content: 'Contiene el formulario para reestablecer la contraseña.',
    target: '#component-authforgotpass-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Formulario para actualizar contraseña',
    content: 'Formulario donde el usuario logeado puede actualizar su contraseña',
    target: '#component-authupdatepass-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Formulario para actualizar informacion de usuario',
    content: 'Formulario donde el usuario logeado puede actualizar su informacion.',
    target: '#component-updateuser-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Formulario para recuperar contraseña',
    content: 'Componente formulario para que el usuario pueda reestablecer la contraseña; recibiendo instrucciones para restablecerla a su correo electrónico.',
    target: '#component-authrestorepass-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Verificar correo',
    content: 'Contenedor que permite verificar el correo de la cuenta del usuario creado',
    target: '#component-verify-email-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Calendario',
    content: 'Agrega un calendario y hora a tu página que podrán ser ingresados por el usuario, (por ejemplo, para agendar citas, reuniones, etc.).',
    target: '#component-calendar-lofty-editor',
    placement: 'right',
  },
  {
    title: 'Componente de Variables',
    content: 'Contenedor de las variables creadas localmente y globalmente. Muestra la variable seleccionada en el contenedor',
    target: '#component-variable-lofty-editor',
    placement: 'top-end',
  },
  {
    title: 'Componente Boolean',
    content: 'Contenedor dinamico que permite mostrar 2 estados segun una variable booleana (estado: verdadero o falso)',
    target: '#component-dynamic-boolean-lofty-editor',
    placement: 'top-end',
  },
  {
    title: 'Orientacion segun dispositivos',
    content: 'Adaptar la pagina segun la orientacion deseada (Escritorio/laptop, tablet, y movil)',
    target: '.panel__devices',
    placement: 'top',
  },
  {
    title: 'Acciones del editor',
    content: 'Las acciones básicas del editor: Eliminar o limpiar el canvas, deshacer cambios, rehacer cambios, vista previa y guardar los cambios realizados.',
    target: '.panel__editor',
    placement: 'top',
  },
];
