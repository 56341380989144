// routesjs
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getLoggedUser, validTokenEditor} from 'api-lofty';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {getAdminLoggedUser, validTokenAdminEditor} from './api/user.admin.api';
import {addUser, addToken, deletetoken} from './actions/auth.actions';
import {addAdminUser, deleteAdmintoken} from './actions/admin.auth.actions';
// Pages
import LoadingPage from './pages/LoadingPage';
import Error404 from './pages/Error404';
// import EditorPage from './pages/EditorPage';
import EditorNormalPage from './pages/EditorNormalPage';
import EditorAdminPage from './pages/EditorAdminPage';

function App({
  addUserApp, addTokenApp, deletetokenApp, addAdminUserApp, deleteAdmintokenApp,
}) {
  const [loading, setLoading] = useState(true);
  useEffect(async () => {
    try {
      const queryParam = new URLSearchParams(window.location.search);
      const getToken = queryParam.get('accesstoken');
      if (window.location.pathname.includes('/admin/')) {
        const resEditor = await validTokenAdminEditor({tokenEditor: getToken});
        const getAdminUser = await getAdminLoggedUser({token: resEditor.token});
        if (getAdminUser) {
          addAdminUserApp(getAdminUser.user);
        } else {
          deleteAdmintokenApp();
        }
      } else {
        const resEditor = await validTokenEditor({tokenEditor: getToken});
        const getEditorUser = await getLoggedUser({token: resEditor.token});
        if (getEditorUser) {
          addUserApp(getEditorUser.user);
          addTokenApp({token: getEditorUser.token, user: getEditorUser.user});
        } else {
          deletetokenApp();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/page/:idProject/:idPage" component={EditorNormalPage} />
        <Route exact path="/admin/:idPage" component={EditorAdminPage} />
        {/* Pagina de 404 */}
        <Route exact path="*" component={Error404} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  addTokenApp: (token, user) => dispatch(addToken(token, user)),
  deletetokenApp: () => dispatch(deletetoken()),
  addAdminUserApp: (adminUser) => dispatch(addAdminUser(adminUser)),
  deleteAdmintokenApp: () => dispatch(deleteAdmintoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
