export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
        <path d="M53.689,377.033c-4.745,0-8.598,3.854-8.598,8.6c0,4.801,3.854,8.596,8.598,8.596h513.213
          c4.744,0,8.598-3.795,8.598-8.596V230.808c0-4.803-3.854-8.598-8.598-8.598H21.609c-4.744,0-8.599,3.795-8.599,8.598v79.101
          c0,4.746,3.855,8.6,8.599,8.6c4.743,0,8.598-3.854,8.598-8.6v-70.502h528.096v137.627H53.689z"/>
      </g>
      <path d="M29.391,376.507c0.754,0.775,1.992,0.788,2.759,0.035l42.457-41.414L87.61,368.64
        c0.283,0.74,0.996,1.225,1.777,1.245c0.78,0.04,1.508-0.438,1.831-1.164l38.808-87.854c0.309-0.733,0.162-1.582-0.391-2.154
        c-0.377-0.383-0.888-0.592-1.4-0.592c-0.256,0-0.498,0.047-0.74,0.148L38.41,314.755c-0.727,0.303-1.211,1.017-1.211,1.804
        c0,0.794,0.471,1.508,1.198,1.811l33.478,13.966l-42.457,41.413C28.65,374.502,28.637,375.733,29.391,376.507z M124.494,283.72
        l-34.945,79.117l-12.371-31.875l0,0c-0.094-0.235-0.229-0.458-0.417-0.646v-0.014l0,0l-0.027-0.013
        c-0.175-0.182-0.39-0.323-0.619-0.425l-0.014-0.006l0,0l-31.835-13.273L124.494,283.72z"/>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelComponent}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente para verificar correo de usuario.', id: 'component-verify-email-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="verifyUserEmail"
        data-gjs-name="Componente De verificacion de correo"
        style="padding: 10px; min-height: 100px"
        data-gjs-resizable="{bc: 1}"
      >
      </div>
    ${style}`,
  });
};
