export const trueStateDivBoolean = (value) => {
  const varRet = `
    <div data-gjs-resizable="{bc: 1}" style="padding: 10px" data-gjs-slugLofty="booleanTrueDivDynamic" data-gjs-name="Contenedor Verdadero">
      <div data-gjs-slugLofty="basicText" data-gjs-resizable="{bc: 1}" data-gjs-name="Texto">
        {{${value}}}
      </div>
    </div>
  `;
  return varRet;
};

export const falseStateDivBoolean = (value) => {
  const varRet = `
    <div data-gjs-resizable="{bc: 1}" style="padding: 10px" data-gjs-slugLofty="booleanFalseDivDynamic" data-gjs-name="Contenedor Falso">
      <div data-gjs-slugLofty="basicText" data-gjs-resizable="{bc: 1}" data-gjs-name="Texto">
        {{False ${value}}}
      </div>
    </div>
  `;
  return varRet;
};
