/* eslint-disable max-len */
import {
  listEndpoint, getOnePageApiPages,
  getOneDatabase, listParamsEndpoint,
} from 'api-lofty';
import {searchForReadDB} from './searchParentComponent';
import {getAllFieldsForCustomParams} from './databaseFieldsComponent';

/** ------------------------------------------------------------------------------ */
/**
   * ===================================
   * ==== CORE FUNCTIONS ===
   * ===================================
   * Has Params
   * Get All Page Params
   * Buttons Handlers
   */
async function hasParamsInPage(token, idPage) {
  const resPage = await getOnePageApiPages({token, _id: idPage});
  const urlParamsRaw = resPage.data.url.split('/');
  const hasParams = urlParamsRaw.some((index) => (index.includes(':')));
  return hasParams;
}

async function getAllPageParams(token, idPage) {
  const resPage = await getOnePageApiPages({token, _id: idPage});
  const urlParamsRaw = resPage.data.url.split('/');
  const filterData = [];
  urlParamsRaw.forEach((index) => {
    if (index.includes(':')) {
      const temp = index.split(':');
      const paramData = temp[1];
      filterData.push({value: paramData, name: paramData});
    }
  });
  return filterData;
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
export async function restoreEndpointTrait(model, type, opts, keyData) {
  try {
    const traitValue = model.attributes.dbSelect;
    const {token} = opts;
    const idDatabase = traitValue;
    const resData = await listEndpoint({token, idDatabase});
    const values = [];
    let isTypeBucket = false;
    if (type === 'Read') {
      // isTypeBucket = await getOneDatabase({token, _id: idDatabase});
      if (resData.data.length > 0) {
        // eslint-disable-next-line array-callback-return
        resData.data.forEach((index) => {
          if (
            index.typeFunction === 'list' || index.typeFunction === 'one'
            || index.typeFunction === 'customList' || index.typeFunction === 'customOne'
          ) {
            values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
          }
        });
      }
    } else if (type === 'AddDBContainer') {
      if (resData.data.length > 0) {
        isTypeBucket = await getOneDatabase({token, _id: idDatabase});
        // eslint-disable-next-line array-callback-return
        resData.data.map((index) => {
          if (index.typeFunction === 'create' || index.typeFunction === 'customCreate') {
            values.push({value: index._id, name: index.typeFunction});
          }
        });
        if (isTypeBucket.data.isBucket) {
          const traitAddition = {
            type: 'select',
            name: 'allowMultipleAttachment',
            label: 'Subir Archivos',
            changeProp: 1,
            options: [{value: 'single', name: '1 Archivo'}, {value: 'multiple', name: 'Multiples Archivos'}],
            default: model.attributes.allowMultipleAttachment || 'single',
          };
          model.addTrait(traitAddition);
        }
      }
    } else if (type === 'Update') {
      if (resData.data.length > 0) {
        // eslint-disable-next-line array-callback-return
        resData.data.map((index) => {
          if (index.typeFunction === 'update') {
            values.push({value: index._id, name: index.typeFunction});
          }
        });
      }
    } else if (type === 'Delete') {
      if (resData.data.length > 0) {
        // eslint-disable-next-line array-callback-return
        resData.data.map((index) => {
          if (index.typeFunction === 'delete') {
            values.push({value: index._id, name: index.typeFunction});
          }
        });
      }
    }
    const traitEndpoint = {
      type: 'select',
      name: 'endpointSelect',
      label: 'Seleccion de Servicio',
      changeProp: 1,
      options: values,
      default: keyData,
    };
    model.addTrait(traitEndpoint);
    const searchForLoggedUserTrait = model.attributes.useCurrentUserAddDatabase;
    // const searchForComponentsRelation = model.attributes.attributes.relationInputs;
    if (searchForLoggedUserTrait) {
      const traitUseLoggedUser = {
        type: 'select',
        name: 'useCurrentUserAddDatabase',
        label: 'Usar usuario activo',
        changeProp: 1,
        options: [{value: 'nouser', name: 'No'}, {value: 'usecurrent', name: 'Usar Usuario Activo'}],
        default: searchForLoggedUserTrait,
      };
      model.addTrait(traitUseLoggedUser);
    }
    // if (searchForComponentsRelation) {
    //   restoreInputManager(model);
    // }
  } catch (error) {
    // console.log(error);
  }
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
/**
    =========================================================
    * Type of Filter Setter
    =========================================================

    * Sets the type of Filter if passes the given validations.
    * @param {*default value from call*} defaultValueSet
    * May either be saved attribute or param if old version
    * @param {*the current component*} model
    =========================================================
  */

export async function typeOfFilterSetter(model, defaultValueSet, opts) {
  const {
    token, idPage, localVars, globalVars,
  } = opts;
  // const token = opts.token;
  // const idPage = opts.idPage;
  const findParams = await hasParamsInPage(token, idPage);

  // Search for Variables
  let hasVariables = false;
  // const localVars = opts.localVars;
  // const globalVars = opts.globalVars;
  if (localVars.length > 0 || globalVars.length > 0) {
    hasVariables = true;
  }

  // Search for Parent Component
  const findReadDBParent = searchForReadDB(model);

  // if has value
  const traitTypeFilterValue = defaultValueSet;

  if (findParams || findReadDBParent || hasVariables) {
    const typeOfFilterOptions = [];
    if (findParams) {
      typeOfFilterOptions.push({name: 'Parametros', value: 'params'});
    }
    if (findReadDBParent) {
      typeOfFilterOptions.push({name: 'Por Componente de Lectura', value: 'readcomponent'});
    }
    if (hasVariables) {
      typeOfFilterOptions.push({name: 'Por Variables', value: 'variables'});
    }
    const traitTypeFilter = {
      type: 'select',
      name: 'typeOfFilterManager',
      label: 'Tipo de filtro',
      changeProp: 1,
      options: typeOfFilterOptions,
      default: traitTypeFilterValue,
    };
    model.addTrait(traitTypeFilter);
  }
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
/**
    =========================================================
    * Trait Button to Call Modal Custom Endpoint
    =========================================================

    * Creates the trait for the component
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */

export function modalCustomEndpointCall(model, variables, paramData, fieldData, handleButtonClickOpenCustomEndpointManager) {
  const traitCustomEndpointHandler = {
    name: 'buttonCustomEndpointModal',
    type: 'button',
    text: 'Manejar Parametros de Servicio',
    full: true,
    command: () => handleButtonClickOpenCustomEndpointManager(variables, fieldData, paramData, model),
  };
  model.addTrait(traitCustomEndpointHandler);
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
/**
    =========================================================
    * Trait Button to Call Modal Custom Endpoint
    =========================================================

    * Creates the trait for the component
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */

export function modalCarouselCustomEndpointCall(model, variables, paramData, fieldData, handleButtonClickOpenCustomEndpointManager) {
  const traitCustomEndpointHandler = {
    name: 'buttonCustomEndpointModal',
    type: 'button',
    text: 'Manejar Parametros de Servicio',
    full: true,
    command: () => handleButtonClickOpenCustomEndpointManager(model, variables, paramData, fieldData),
  };
  model.addTrait(traitCustomEndpointHandler);
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
/**
    =========================================================
    * Handle Capture Data for Custom Endpoint
    =========================================================

    * Captures all data if found.
    * @param {*Verifies if parent is a ReadDB Component*} findReadDBParent
    * @param {*current Component*} model
    * @param {*verifies if variables exists*} hasVariables
    * @param {*verifies if page has params*} findParams
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */
export async function captureDataForCustomEndpointCall(
  model,
  findParams,
  hasVariables,
  findReadDBParent,
  opts,
) {
  try {
    model.removeTrait('buttonCustomEndpointModal');
    const {
      idPage,
      token,
      idProject,
      globalVars,
      localVars,
    } = opts;
    // const {token} = opts;
    // const {idProject} = opts;
    let getPageParams = [];
    let variableData = [];
    let relationFields = [];
    if (findParams) {
      getPageParams = await getAllPageParams(token, idPage);
    }
    if (findReadDBParent) {
      const findParent = searchForReadDB(model);
      relationFields = await getAllFieldsForCustomParams(findParent, token, idProject);
    }
    if (hasVariables) {
      const varData = [];
      // const {localVars} = opts;
      // const {globalVars} = opts;
      // 'boolean' | 'string' | 'number' | 'arrayDatabase' | 'database'
      localVars.filter((index) => (index.type !== 'arrayDatabase'))
        .forEach((variable) => {
          varData.push({name: `${variable.label}--${variable.type}`, type: variable.type, value: variable._id});
        });
      globalVars.filter((index) => (index.typeValue !== 'arrayDatabase'))
        .forEach((variable) => {
          varData.push({name: `${variable.label}--${variable.typeValue}`, type: variable.typeValue, value: variable._id});
        });
      variableData = varData;
    }
    const functionCall = opts?.handleButtonClickOpenCustomEndpointManager || opts?.handleOpenCarouselCustomEndpointManager;
    modalCustomEndpointCall(model, variableData, getPageParams, relationFields, functionCall);
  } catch (error) {
    // console.log(error);
  }
}
/** ------------------------------------------------------------------------------ */

/** ------------------------------------------------------------------------------ */
/**
    =========================================================
    * Restore Custom Endpoint Filters
    =========================================================

    * Search for all possible filters for custom endpoint
    * Endpoint must have at least one parameter
    * @param {*the current custom endpoint*} endpointValue
    * @param {*the current component*} model
    * Only for custom

    =========================================================
  */
export async function restoreFilterCustomEndpoint(model, endpointValue, opts) {
  const {token} = opts;
  const {idPage} = opts;
  const getParamData = await listParamsEndpoint({
    idEndpoint: endpointValue.data._id,
    idProject: endpointValue.data.idProject,
    token,
  });
  /**
        * SEARCH FOR FILTER TYPES
        *
        * If Has : then params exists
        * If has variables (local/global) then can use vars
        * If Parent Component is ReadDB use parentComponent
      */

  const findParams = await hasParamsInPage(token, idPage);

  // Search for Variables
  let hasVariables = false;
  const {localVars} = opts;
  const {globalVars} = opts;
  if (localVars.length > 0 || globalVars.length > 0) {
    hasVariables = true;
  }

  // Search for Parent Component
  const findReadDBParent = searchForReadDB(model);

  if ((findParams || hasVariables || findReadDBParent) && getParamData.data.length > 0) {
    captureDataForCustomEndpointCall(model, findParams, hasVariables, findReadDBParent, opts);
  }
}
/** ------------------------------------------------------------------------------ */
