/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {
  listPage,
} from 'api-lofty';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function getPagesData() {
    const token = opts.token;
    const idProject = opts.idProject;
    const resData = await listPage({token, idProject});
    const noParamsPage = resData.data.filter((index) => (!index.url.match(/:|\*/)));
    const retVal = noParamsPage.map((index) => ({value: index.url, name: index.url}));
    return retVal;
  }

  async function restoreResponseLogout(model) {
    if (model.attributes.actionLogout === 'message') {
      const alertMessage = {
        type: 'text',
        name: 'alertMessage',
        label: 'Mensaje',
        changeProp: 1,
      };
      model.addTrait(alertMessage);
    } else {
      const options = await getPagesData();
      const hrefRedirect = {
        type: 'select',
        name: 'redirectPage',
        label: 'Redireccionar',
        changeProp: 1,
        options,
        default: model.attributes.redirectPage,
      };
      model.addTrait(hrefRedirect);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('mainFormForgotPassword')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:actionLogout', this.actionForLogout);
        this.listenTo(model, '', this.restoreData());
      },
      /*
              -- ADD ACTION  --
        Adds Action to the component: redirect to url, or display a message
      */
      restoreData() {
        if (this.model.attributes.sluglofty.includes('mainFormForgotPassword')) {
          const traitActionLogout = {
            type: 'select',
            name: 'actionLogout',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionLogout);
          if (this.model.attributes.actionLogout) {
            restoreResponseLogout(this.model);
          }
        }
      },
      async actionForLogout() {
        try {
          const traitSelection = this.model.getTrait('actionLogout').attributes.value;
          if (traitSelection === 'message') {
            this.model.removeTrait('redirectPage');
            const alertMessage = {
              type: 'text',
              name: 'alertMessage',
              label: 'Mensaje',
              changeProp: 1,
            };
            this.model.addTrait(alertMessage);
          } else if (traitSelection === 'redirect') {
            this.model.removeTrait('alertMessage');
            const options = await getPagesData();
            const hrefRedirect = {
              type: 'select',
              name: 'redirectPage',
              label: 'Redireccionar',
              changeProp: 1,
              options,
            };
            this.model.addTrait(hrefRedirect);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
