/* eslint-disable prefer-promise-reject-errors */
import instanceAxios from '../config/axios';

export const url = '/api/useradmin';

export const urlEditor = '/api/suggestionpage';

export const getAdminLoggedUser = ({token}) => new Promise((resolve, reject) => {
  if (token) {
    instanceAxios.get(`${url}/logged`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const validTokenAdminEditor = ({tokenEditor}) => new Promise((resolve, reject) => {
  if (tokenEditor) {
    instanceAxios.post(`${urlEditor}/admin/editor/valid/token`, {
      tokenEditor,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response.data));
  } else {
    reject({
      status: 'error',
      info: 'Token no encontrado',
    });
  }
});
