import {ADMIN_DELETE_TOKEN, ADMIN_ADD_TOKEN, ADMIN_ADD_USER} from '../types/auth.types';

export const addAdminToken = ({token, user}) => {
  localStorage.setItem('admintoken', token);
  return {
    type: ADMIN_ADD_TOKEN,
    payload: {
      token, user,
    },
  };
};

export const deleteAdmintoken = () => {
  localStorage.removeItem('admintoken');
  return {
    type: ADMIN_DELETE_TOKEN,
  };
};

export const addAdminUser = (user) => ({
  type: ADMIN_ADD_USER,
  payload: {user},
});
