import React, {useState} from 'react';
import toastr from 'toastr';
import {addAssetsProject} from 'api-lofty';
import {makeStyles} from '@material-ui/core/styles';
import {
  Typography, Box, Button, Dialog, DialogTitle,
  DialogContent, TextField,
} from '@material-ui/core';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  formContainer: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: 'rgba(82, 82, 82, 1)',
    backgroundColor: 'rgba(244, 251, 255, 1)',
  },
  uploadLabelTypography: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: '400',
    fontSize: '23px',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'space-between',
  },
  noDataFoundContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '23px',
    height: '200px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  dragElement: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  urlSectionArea: {
    margin: '10px',
    width: '100%',
    padding: '5px',
  },
  videoTitleArea: {
    marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
    color: '#000',
    fontSize: '21px',
    display: 'flex',
    justifyContent: 'center',
  },
  videoArea: {
    width: '100%',
    height: '160px',
    padding: '5px',
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowY: 'scroll',
    backgroundColor: '#F3F3F3',
  },
  videoBoxContainer: {
    margin: '5px',
    width: '240px',
    height: '150px',
    backgroundColor: '#808080',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  buttonSelectVideo: {
    marginTop: '5px',
    width: '120px',
    fontWeight: 'bold',
    fontSize: '12px',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    color: '#fff',
  },
}));

function VideoUploadForm({
  open, handleClose, assetsInfo, selectUrl, updateInfo,
  token, idProject,
}) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);
  const [urlSelection, setUrlSelection] = useState('');
  const classes = useStyle();

  async function toUpload(files) {
    // console.log(files[0]);
    try {
      await addAssetsProject({token, idProject, files: files[0]});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se subieron los archivos exitosamente'), 300);
      updateInfo();
      handleClose();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se logro agregar los datos'), 300);
      }
    }
  }

  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      toUpload(e.dataTransfer.files);
    }
  }

  function handleChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      toUpload(e.target.files);
    }
  }

  const clickUpload = () => {
    inputRef.current.click();
  };

  const changeUrlValue = (index) => {
    setUrlSelection(index.name);
    selectUrl(index);
  };

  return (
    <Dialog open={open} handleClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Subir Video
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input ref={inputRef} type="file" style={{display: 'none'}} multiple={false} onChange={handleChange} />
          <Box component="div" className={classes.formContainer}>
            <Typography className={classes.uploadLabelTypography}>
              Arrastra los archivos para subirlos
            </Typography>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonStyle}
              onClick={clickUpload}
            >
              Subir Archivo
            </Button>
          </Box>
          { dragActive
            && (
            <div
              className={classes.dragElement}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
            )}
        </form>
        <Box className={classes.urlSectionArea}>
          {assetsInfo && assetsInfo.length > 0 && (
            <>
              <TextField label="current URL:" value={urlSelection} disabled variant="outlined" />
              <Box className={classes.videoTitleArea}>VIDEOS:</Box>
              <Box className={classes.videoArea}>
                {assetsInfo.map((index) => (
                  <Box className={classes.videoBoxContainer} onClick={() => changeUrlValue(index)}>
                    <video width="200" height="100" src={index.src} muted controls />
                    <Button
                      onClick={() => changeUrlValue(index)}
                      variant="contained"
                      className={classes.buttonSelectVideo}
                    >
                      Seleccionar
                    </Button>
                  </Box>
                ))}
              </Box>

            </>
          )}
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default VideoUploadForm;
