/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export default (editor, opts) => {
  const c = opts;
  const {blocks} = c;
  const bm = editor.BlockManager;
  const style = `<style>
  .crudContainerDB{
    width: 100%;
    minHeight: 70px;
    padding: 5px;
  }
  </style>
  `;
  const toAdd = (name) => blocks.indexOf(name) >= 0;
  toAdd('Authentication')
    && bm.add('Authentication', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
        <g>
        <path d="M555.167,573.614H146.493c-4.088,0-7.411-3.322-7.411-7.411V369.687
          c0-4.089,3.322-7.411,7.411-7.411c4.14,0,7.437,3.322,7.437,7.411v189.105h393.827V39.671H153.93v200.247
          c0,4.089-3.296,7.411-7.437,7.411c-4.088,0-7.411-3.322-7.411-7.411V32.261c0-4.089,3.322-7.411,7.411-7.411h408.674
          c4.089,0,7.411,3.322,7.411,7.411v533.942C562.578,570.292,559.256,573.614,555.167,573.614z"/>
        <path d="M341.375,312.188H23.677c-4.089,0-7.411-3.322-7.411-7.411c0-4.088,3.322-7.411,7.411-7.411h317.698
          c4.089,0,7.411,3.322,7.411,7.411C348.786,308.865,345.464,312.188,341.375,312.188z"/>
        <path d="M273.706,367.182c-2.351,0-4.6-1.073-6.057-3.117c-2.376-3.322-1.61-7.974,1.738-10.324
          l68.666-48.964l-68.666-48.963c-3.348-2.402-4.114-7.002-1.738-10.324c2.377-3.373,7.028-4.14,10.35-1.737l77.124,54.994
          c1.968,1.38,3.118,3.629,3.118,6.03c0,2.402-1.15,4.651-3.118,6.031l-77.124,54.994
          C276.67,366.722,275.188,367.182,273.706,367.182z"/>
        </g>
      </svg>

      </div>
      <div class="gjs-block-label">
        ${c.labelAuth}
      </div>`,
      category: c.category,
      attributes: {title: 'Autentica una cuenta con tu nombre de usuario y contraseña únicos. ', id: 'component-authlogin-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="AuthenticationUserContainer" data-gjs-name="User Authentication Container" title="AuthenticationUserContainer" style="min-height: 100px; padding: 10px">
      </div>
      ${style}`,
    });

  toAdd('Logout')
    && bm.add('Logout', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
        <g>
        <path d="M555.167,573.614H146.493c-4.088,0-7.411-3.322-7.411-7.411V369.687
          c0-4.089,3.322-7.411,7.411-7.411c4.14,0,7.437,3.322,7.437,7.411v189.105h393.827V39.671H153.93v200.247
          c0,4.089-3.296,7.411-7.437,7.411c-4.088,0-7.411-3.322-7.411-7.411V32.261c0-4.089,3.322-7.411,7.411-7.411h408.674
          c4.089,0,7.411,3.322,7.411,7.411v533.942C562.578,570.292,559.256,573.614,555.167,573.614z"/>
        <path d="M341.375,312.188H23.677c-4.089,0-7.411-3.322-7.411-7.411c0-4.088,3.322-7.411,7.411-7.411h317.698
          c4.089,0,7.411,3.322,7.411,7.411C348.786,308.865,345.464,312.188,341.375,312.188z"/>
        <path d="M273.706,367.182c-2.351,0-4.6-1.073-6.057-3.117c-2.376-3.322-1.61-7.974,1.738-10.324
          l68.666-48.964l-68.666-48.963c-3.348-2.402-4.114-7.002-1.738-10.324c2.377-3.373,7.028-4.14,10.35-1.737l77.124,54.994
          c1.968,1.38,3.118,3.629,3.118,6.03c0,2.402-1.15,4.651-3.118,6.031l-77.124,54.994
          C276.67,366.722,275.188,367.182,273.706,367.182z"/>
        </g>
      </svg>

      </div>
      <div class="gjs-block-label">
        ${c.labelLogout}
      </div>`,
      category: c.category,
      attributes: {title: 'Cerrar la sesion del usuario actual. ', id: 'component-authlogout-lofty-editor'},
      content: `
      <button
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="logoutUserButtonComponent"
        data-gjs-name="Logout Button"
        style="min-height: 50px; padding: 10px"
        data-gjs-draggable="true"
        data-gjs-droppable="true"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Cerrar Sesion</div>
      </button>
      ${style}`,
    });

  toAdd('Register')
    && bm.add('Register', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
        <g>
          <path d="M276.296,293.938c-75.418,0-136.73-61.313-136.73-136.676s61.313-136.676,136.73-136.676
            c75.363,0,136.676,61.313,136.676,136.676S351.659,293.938,276.296,293.938z M276.296,36.021
            c-66.902,0-121.295,54.394-121.295,121.241s54.393,121.241,121.295,121.241c66.848,0,121.24-54.394,121.24-121.241
            S343.144,36.021,276.296,36.021z"/>
          <path d="M347.454,577.668h-235.67c-4.258,0-7.719-3.459-7.719-7.717V454.032
            c0-96.76,78.717-175.529,175.529-175.529c66.635,0,126.723,37.043,156.795,96.6c1.916,3.832,0.371,8.462-3.461,10.378
            s-8.461,0.373-10.324-3.406c-27.41-54.34-82.229-88.137-143.01-88.137c-88.297,0-160.094,71.851-160.094,160.095v108.201h227.953
            c4.258,0,7.717,3.46,7.717,7.718S351.712,577.668,347.454,577.668z"/>
          <g>
            <path d="M515.425,509.597H379.28c-4.258,0-7.717-3.46-7.717-7.718s3.459-7.717,7.717-7.717h136.145
              c4.258,0,7.717,3.459,7.717,7.717S519.683,509.597,515.425,509.597z"/>
            <path d="M447.353,577.668c-4.258,0-7.717-3.459-7.717-7.717V433.807c0-4.258,3.459-7.717,7.717-7.717
              s7.717,3.459,7.717,7.717v136.145C455.069,574.209,451.61,577.668,447.353,577.668z"/>
          </g>
        </g>
      </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelRegister}
      </div>`,
      category: c.category,
      attributes: {title: 'Crea una cuenta o perfil en tu página.', id: 'component-authregister-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="RegisterUserContainer" data-gjs-name="Register User Container" title="RegisterUserContainer" style="min-height: 100px; padding: 10px;">
      </div>
      ${style}`,
    });

  toAdd('RestorePassword')
    && bm.add('RestorePassword', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
    <g>
    <g>
    <g>
      <path d="M306.027,396.942c-25.02,0-50.079-9.297-69.472-28.018
        c-39.667-38.282-40.758-101.708-2.477-141.375c38.282-39.666,101.705-40.778,141.414-2.497
        c39.646,38.301,40.757,101.729,2.477,141.395C358.368,386.742,332.217,396.942,306.027,396.942z M306.027,209.229
        c-23.003,0-45.966,8.962-63.173,26.78c-33.624,34.839-32.659,90.54,2.181,124.164c34.839,33.641,90.54,32.656,124.162-2.182
        c33.622-34.843,32.657-90.521-2.182-124.165C350.015,217.393,328.02,209.229,306.027,209.229z"/>
    </g>
      <g>
        <polygon points="368.904,367.076 233.87,236.723 242.35,227.971 377.362,358.305"/>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path d="M295.195,517.77L141.19,415.474c-5.793-3.86-9.237-10.325-9.237-17.295V149.184
            c0-9.507,5.795-18.028,14.65-21.491l1.555-0.588c101.494-39.687,214.24-39.687,315.778,0l6.632,2.603
            c3.148,1.218,4.68,4.744,3.441,7.892l0,0c-1.218,3.127-4.743,4.66-7.893,3.442l-4.407-1.721
            c-100.108-39.12-211.26-39.12-311.373,0l0,0c-3.734,1.469-6.211,5.08-6.211,9.109V397.53c0,3.294,1.638,6.338,4.366,8.163
            l152.117,101.033c3.275,2.185,7.558,2.185,10.83,0l152.118-101.033c2.728-1.825,4.363-4.869,4.363-8.163V264.867
            c0-3.358,2.729-6.087,6.089-6.087h0.021c3.36,0,6.064,2.729,6.064,6.087v132.726c0,7.324-3.651,14.146-9.738,18.218
            L316.854,517.77C310.307,522.111,301.743,522.111,295.195,517.77z"/>
          </g>
        </g>
      </g>
    </g>
    <g>
      <path d="M575.281,220.035c-4.275,0-7.748,3.494-7.748,7.82v337.169H29.455V30.879h327.454
        c4.273,0,7.748-3.466,7.748-7.793c0-4.299-3.475-7.792-7.748-7.792H21.705c-4.274,0-7.749,3.493-7.749,7.792v549.757
        c0,4.301,3.474,7.795,7.749,7.795h553.577c4.275,0,7.75-3.494,7.75-7.795V227.855C583.031,223.528,579.557,220.035,575.281,220.035
        z"/>
    </g>
  </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelRestore}
      </div>`,
      category: c.category,
      attributes: {title: 'Contiene el formulario para reestablecer la contraseña.', id: 'component-authforgotpass-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="RestorePasswordContainer" data-gjs-name="RestorePassword Container" title="RestorePasswordUserContainer" style="min-height: 100px; padding: 10px;">
      </div>
      ${style}`,
    });

  toAdd('ChangePassword')
    && bm.add('ChangePassword', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
    <g>
    <g>
    <g>
      <path d="M306.027,396.942c-25.02,0-50.079-9.297-69.472-28.018
        c-39.667-38.282-40.758-101.708-2.477-141.375c38.282-39.666,101.705-40.778,141.414-2.497
        c39.646,38.301,40.757,101.729,2.477,141.395C358.368,386.742,332.217,396.942,306.027,396.942z M306.027,209.229
        c-23.003,0-45.966,8.962-63.173,26.78c-33.624,34.839-32.659,90.54,2.181,124.164c34.839,33.641,90.54,32.656,124.162-2.182
        c33.622-34.843,32.657-90.521-2.182-124.165C350.015,217.393,328.02,209.229,306.027,209.229z"/>
    </g>
      <g>
        <polygon points="368.904,367.076 233.87,236.723 242.35,227.971 377.362,358.305"/>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path d="M295.195,517.77L141.19,415.474c-5.793-3.86-9.237-10.325-9.237-17.295V149.184
            c0-9.507,5.795-18.028,14.65-21.491l1.555-0.588c101.494-39.687,214.24-39.687,315.778,0l6.632,2.603
            c3.148,1.218,4.68,4.744,3.441,7.892l0,0c-1.218,3.127-4.743,4.66-7.893,3.442l-4.407-1.721
            c-100.108-39.12-211.26-39.12-311.373,0l0,0c-3.734,1.469-6.211,5.08-6.211,9.109V397.53c0,3.294,1.638,6.338,4.366,8.163
            l152.117,101.033c3.275,2.185,7.558,2.185,10.83,0l152.118-101.033c2.728-1.825,4.363-4.869,4.363-8.163V264.867
            c0-3.358,2.729-6.087,6.089-6.087h0.021c3.36,0,6.064,2.729,6.064,6.087v132.726c0,7.324-3.651,14.146-9.738,18.218
            L316.854,517.77C310.307,522.111,301.743,522.111,295.195,517.77z"/>
          </g>
        </g>
      </g>
    </g>
    <g>
      <path d="M575.281,220.035c-4.275,0-7.748,3.494-7.748,7.82v337.169H29.455V30.879h327.454
        c4.273,0,7.748-3.466,7.748-7.793c0-4.299-3.475-7.792-7.748-7.792H21.705c-4.274,0-7.749,3.493-7.749,7.792v549.757
        c0,4.301,3.474,7.795,7.749,7.795h553.577c4.275,0,7.75-3.494,7.75-7.795V227.855C583.031,223.528,579.557,220.035,575.281,220.035
        z"/>
    </g>
  </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelUpdate}
      </div>`,
      category: c.category,
      attributes: {title: 'Contiene el formulario para actualizar la contraseña.', id: 'component-authupdatepass-lofty-editor'},
      content: `
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="UpdateUserPasswordContainer" data-gjs-name="Actualizar Contraseña" title="UpdatePasswordContainer" style="min-height: 100px; padding: 10px;">
      <form
      data-gjs-slugLofty="UpdatePasswordSection"
      data-gjs-name="Formulario Actualizar Contraseña"
      data-gjs-draggable="[title=UpdatePasswordContainer]"
      style="padding: 10px"
      title="updatePassword"
      data-gjs-resizable="{bc: 1}"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" data-gjs-slugLofty="updatePasswordDiv" data-gjs-name="Seccion Nueva Contraseña" style="padding: 10px">
        <label data-gjs-draggable="false" data-gjs-slugLofty="newPasswordUpdateLabel" data-gjs-name="Etiqueta Nueva Contraseña">Nueva Contraseña</label><br/>
        <input data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" type="password" data-gjs-slugLofty="newUpdatePassword" data-gjs-name="Entrada Nueva Contraseña" name="newUpdatePassword" required />
      </div>
      <div data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" data-gjs-slugLofty="updatePasswordDiv" data-gjs-name="Seccion Confirmar Nueva Contraseña" style="padding: 10px">
        <label data-gjs-draggable="false" data-gjs-slugLofty="confirmPasswordUpdateLabel" data-gjs-name="Etiqueta Confirmar Nueva Contraseña">Confirmar Contraseña</label><br/>
        <input data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" type="password" data-gjs-slugLofty="confirmUpdatePassword" data-gjs-name="Entrada Confirmar Nueva Contraseña" name="confirmUpdatePassword" required />
      </div>
      <button
        type="submit"
        data-gjs-slugLofty="updatePasswordButton"
        data-gjs-name="Boton Actualizar Contraseña"
        data-gjs-draggable="[title=updatePassword]"
        data-gjs-resizable="{bc: 1}"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Actualizar Contraseña</div>
      </button>
    </form>
      </div>
      ${style}`,
    });

  toAdd('UpdateUserInfo')
    && bm.add('UpdateUserInfo', {
      label: `
      <div style="display: flex; justify-content: center" >
      <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
    <g>
    <g>
    <g>
      <path d="M306.027,396.942c-25.02,0-50.079-9.297-69.472-28.018
        c-39.667-38.282-40.758-101.708-2.477-141.375c38.282-39.666,101.705-40.778,141.414-2.497
        c39.646,38.301,40.757,101.729,2.477,141.395C358.368,386.742,332.217,396.942,306.027,396.942z M306.027,209.229
        c-23.003,0-45.966,8.962-63.173,26.78c-33.624,34.839-32.659,90.54,2.181,124.164c34.839,33.641,90.54,32.656,124.162-2.182
        c33.622-34.843,32.657-90.521-2.182-124.165C350.015,217.393,328.02,209.229,306.027,209.229z"/>
    </g>
      <g>
        <polygon points="368.904,367.076 233.87,236.723 242.35,227.971 377.362,358.305"/>
      </g>
    </g>
    <g>
      <g>
        <g>
          <path d="M295.195,517.77L141.19,415.474c-5.793-3.86-9.237-10.325-9.237-17.295V149.184
            c0-9.507,5.795-18.028,14.65-21.491l1.555-0.588c101.494-39.687,214.24-39.687,315.778,0l6.632,2.603
            c3.148,1.218,4.68,4.744,3.441,7.892l0,0c-1.218,3.127-4.743,4.66-7.893,3.442l-4.407-1.721
            c-100.108-39.12-211.26-39.12-311.373,0l0,0c-3.734,1.469-6.211,5.08-6.211,9.109V397.53c0,3.294,1.638,6.338,4.366,8.163
            l152.117,101.033c3.275,2.185,7.558,2.185,10.83,0l152.118-101.033c2.728-1.825,4.363-4.869,4.363-8.163V264.867
            c0-3.358,2.729-6.087,6.089-6.087h0.021c3.36,0,6.064,2.729,6.064,6.087v132.726c0,7.324-3.651,14.146-9.738,18.218
            L316.854,517.77C310.307,522.111,301.743,522.111,295.195,517.77z"/>
          </g>
        </g>
      </g>
    </g>
    <g>
      <path d="M575.281,220.035c-4.275,0-7.748,3.494-7.748,7.82v337.169H29.455V30.879h327.454
        c4.273,0,7.748-3.466,7.748-7.793c0-4.299-3.475-7.792-7.748-7.792H21.705c-4.274,0-7.749,3.493-7.749,7.792v549.757
        c0,4.301,3.474,7.795,7.749,7.795h553.577c4.275,0,7.75-3.494,7.75-7.795V227.855C583.031,223.528,579.557,220.035,575.281,220.035
        z"/>
    </g>
  </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelUpdateUserInfo}
      </div>`,
      category: c.category,
      attributes: {title: 'Contiene el formulario para actualizar la informacion de usuario.', id: 'component-updateuser-lofty-editor'},
      content: `
      <form
      data-gjs-slugLofty="formUpdateUserInfo"
      data-gjs-name="Formulario Actualizar Informacion de Usuario"
      style="min-height: 100px; padding: 10px;"
      title="UpdateMainContainer"
      data-gjs-resizable="{bc: 1}"
    >
    </form>
      ${style}`,
    });
};
