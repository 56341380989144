/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  // eslint-disable-next-line consistent-return
  function searchForReadBucketDB(component) {
    try {
      const parentComponent = component.parent();
      if (parentComponent !== undefined && parentComponent.attributes.sluglofty === 'ReadDBContainer') {
        const componentData = parentComponent.get('components');
        let isGetList = false;
        let hasBucket = false;
        const temp = [];
        componentData.forEach((componentModel) => {
          if (componentModel.attributes.sluglofty.includes('fieldBucket')) {
            hasBucket = true;
          } else if (componentModel.attributes.sluglofty === 'itemListEndpointDatabase') {
            isGetList = true;
            temp.push(componentModel);
          }
        });
        if (isGetList) {
          temp[0].get('components').forEach((componentModel) => {
            if (componentModel.attributes.sluglofty.includes('fieldBucket')) {
              hasBucket = true;
            }
          });
        }
        if (hasBucket) {
          return parentComponent;
        } else {
          return searchForReadBucketDB(parentComponent);
        }
      } else if (parentComponent === undefined) {
        return null;
      }
    } catch (error) {
      // console.log('it broke');
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('dynImage')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
      },
      updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('dynImage')) {
            const parentComponent = this.model.parent();
            const resultSeeker = searchForReadBucketDB(parentComponent);
            if (resultSeeker) {
              let searcher = '';
              const componentData = resultSeeker.get('components');
              componentData.every((index) => {
                if (index.attributes.sluglofty === 'itemListEndpointDatabase') {
                  searcher = 'list';
                  return false;
                // eslint-disable-next-line no-else-return
                } else if (index.attributes.sluglofty.includes('fieldDatabaseContainer')) {
                  searcher = 'fields';
                  return false;
                } else {
                  return true;
                }
              });
              let fieldContainer = [];
              let fieldLoftyUrl = [];
              let fieldData = [];
              switch (searcher) {
                case 'list':
                  fieldContainer = componentData.filter((index) => (index.attributes.sluglofty === 'itemListEndpointDatabase'));
                  fieldData = fieldContainer[0].attributes.components.models;
                  fieldLoftyUrl = fieldData.filter((index) => (index.attributes.valuelofty === 'loftyUrl'));
                  break;
                case 'fields':
                  fieldLoftyUrl = componentData.filter((index) => (index.attributes.valuelofty === 'loftyUrl'));
                  break;
                default:
                  fieldContainer = null;
                  break;
              }
              this.model.addAttributes({loftyUrlData: fieldLoftyUrl[0].attributes.components.models});
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
