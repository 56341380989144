import React, {useState, useEffect} from 'react';
import geditorConfig from '../editorSuggestionConfig/config';
import Sidebar from './EditorNormalComponent.js/Sidebar';
import TopNav from './EditorNormalComponent.js/TopNav';
import LogoImg from '../assets/navbar/logo_sidebar.png';

function EditorAdmin({
  _id, token, url,
}) {
  // eslint-disable-next-line no-unused-vars
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    const editorNew = geditorConfig({
      url,
      token,
      _id,
    });
    setEditor(editorNew);
  }, []);

  return (
    <div className="App">
      <div id="navbar" className="sidenav d-flex flex-column overflow-scroll">
        <nav className="navbar navbar-light">
          <div className="container-fluid" style={{display: 'flex', justifyContent: 'center'}} id="initial-Section-editor-lofty">
            {/*
              <p className="navbar-brand text-center w-100 mb-0 h3 logo">Lofty Apps</p>
            */}
            <img src={LogoImg} alt="Lofty Apps" width="200px" height="75px" />
          </div>
        </nav>
        <Sidebar />
      </div>
      <div className="main-content" id="main-content">
        <TopNav />
        <div id="editor" />
      </div>
    </div>
  );
}

export default EditorAdmin;
