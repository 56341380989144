export const verifyExists = (dataInfo, value) => {
  const searchExists = dataInfo.find((index) => (index.slug === value));
  if (searchExists) {
    return true;
  }
  return false;
};

export const verifyExistsProject = (dataInfo, value) => {
  const searchExists = dataInfo.find((index) => (index.slug === value));
  if (searchExists) {
    return true;
  }
  return false;
};
