/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {
  Box,
  Container,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Blockly from 'blockly';
import 'toastr/build/toastr.min.css';
import defineDBBlocks, {updateVariablesBlockly} from './customFrontendBlockDefinitions';

function RenderViewBlockly({
  localVariables, globalVariables,
  backFunction, variableInfoData,
  infoEndpointData,
}) {
  // blockly
  const blocklyRef = useRef();
  const [renderHelper, setRenderHelper] = useState(0);
  const [toolbox, setToolbox] = useState();
  let blocklyWorkspace = null;
  // const [label, setLabel] = useState('');
  // const [isEditingLabel, setIsEditingLabel] = useState(false);
  // const [errorInSlug, setErrorInSlug] = useState(false);
  // const [errorMessageSlug, setErrorMessageSlug] = useState('');
  // const [suggestionSlug, setSuggestionSlug] = useState('');
  // const [slug, setSlug] = useState('');
  // end blockly

  // let serialization;

  // const handleLoad = async () => {
  //   const mainWorkspace = Blockly.getMainWorkspace();
  //   const serializer = new Blockly.serialization.blocks.BlockSerializer();
  //   serializer.load(serialization, mainWorkspace);
  // };

  const handleLoad = async () => {
    const mainWorkspace = Blockly.getMainWorkspace();
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    if (variableInfoData.length > 0) {
      const variableSerializer = new Blockly.serialization.variables.VariableSerializer();
      variableSerializer.load(variableInfoData, mainWorkspace);
    }
    // const serialBlock = {blocks: infoEndpointData};
    serializer.load(infoEndpointData, mainWorkspace);
  };
  useEffect(() => {
    // setSuggestionSlug('');
    if (!blocklyRef.current) {
      setRenderHelper(renderHelper + 1);
      return;
    }
    const toolbox1 = defineDBBlocks({
      localVariables,
      globalVariables,
    });
    // document.querySelector('#blocklyDiv').children = '';
    blocklyWorkspace = Blockly.inject('blocklyDiv', {toolbox, readOnly: true, maxInstances: {main_block: 1}});
    setToolbox(toolbox1);
    const blankDiv = document.querySelector('.injectionDiv');
    if (blankDiv) {
      blankDiv.style.display = 'none';
    }
    handleLoad();
  }, [blocklyRef.current, renderHelper]);

  // function generateSlugFromValue(value) {
  //   if (isEditingLabel) {
  //     const res = generateSlugSample(listFunctions, value);
  //     setSuggestionSlug(res);
  //     setIsEditingLabel(false);
  //   }
  // }

  // function verifySlugValue(value) {
  //   if (isInvalidSlug(value)) {
  //     setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
  //     setErrorInSlug(true);
  //   } else {
  //     setErrorInSlug(false);
  //     setErrorMessageSlug('');
  //   }
  // }

  useEffect(() => {
    updateVariablesBlockly({
      localVariables, globalVariables, blocklyWorkspace, oldToolbox: toolbox, setToolbox,
    });
  }, [localVariables, globalVariables]);

  return (
    <Container>
      <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <IconButton
          onClick={backFunction}
          style={{color: '#fff'}}
        >
          <ArrowBackIcon />
        </IconButton>
        <Box className="text-center">
          Visualizando función
        </Box>
      </Box>
      {/* <div className="my-8">
        <Grid container>
          <Grid className="pr-3" item md={6}>
            <TextField
              className="w-100"
              name="label"
              label="Etiqueta"
              variant="outlined"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              onFocus={() => setIsEditingLabel(true)}
              onBlur={(e) => generateSlugFromValue(e.target.value)}
              style={{backgroundColor: '#D9D9D9'}}
            />
          </Grid>
          <Grid className="pl-3" item md={6}>
            <TextField
              className="w-100"
              name="slug"
              label="Slug"
              variant="outlined"
              value={suggestionSlug}
              onChange={(e) => setSuggestionSlug(e.target.value)}
              helperText={errorMessageSlug}
              error={errorInSlug}
              onBlur={(e) => verifySlugValue(e.target.value)}
              style={{backgroundColor: '#D9D9D9'}}
            />
          </Grid>
        </Grid>
      </div> */}
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          ref={blocklyRef}
          id="blocklyDiv"
          style={{
            height: 'calc(50vh*.99)', width: '95vw', border: 0,
          }}
        />
      </Box>
      {/* <div className="my-10" style={{display: 'flex', justifyContent: 'center'}}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (addFuntionPageFrontend) {
              const mainWorkspace = Blockly.getMainWorkspace();
              // Blocks
              const serializerBlocks = new Blockly.serialization.blocks.BlockSerializer();
              const stateBlocks = serializerBlocks.save(mainWorkspace);

              // Variables
              const serializeVars = new Blockly.serialization.variables.VariableSerializer();
              const stateVars = serializeVars.save(mainWorkspace);
              addFuntionPageFrontend(label, suggestionSlug, stateBlocks, stateVars);
            }
          }}
        >
          Agregar
        </Button>
      </div> */}
    </Container>
  );
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(RenderViewBlockly);
