/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [
          {
            type: 'select',
            name: 'inputType',
            label: 'Tipo de Entrada',
            changeProp: 1,
            options: [
              {value: 'text', name: 'Texto'},
              {value: 'number', name: 'Numerico'},
            ],
          },
        ],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (
          attr['data-gjs-sluglofty']
          && (attr['data-gjs-sluglofty'].value.includes('formInputContainer'))) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:inputType', this.updateScript);
      },
      updateScript() {
        try {
          if (this.model.attributes.sluglofty === 'formInputContainer') {
            if (this.model.attributes.inputType) {
              this.searchForTypeVariables();
            }
          }
        } catch (error) {
          //
        }
      },
      searchForTypeVariables() {
        try {
          if (this.model.attributes.sluglofty === 'formInputContainer') {
            const traitValue = this.model.getTrait('inputType').attributes.value;
            const localVars = opts.localVars;
            const globalVars = opts.globalVars;
            this.model.removeTrait('variableForInput');
            const varData = [];
            if (traitValue === 'number') {
              localVars.filter((index) => (index.type === 'number'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--local`, value: variable._id});
                });
              globalVars.filter((index) => (index.typeValue === 'number'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--global`, value: variable._id});
                });
            } else {
              localVars.filter((index) => (index.type === 'string'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--local`, value: variable._id});
                });
              globalVars.filter((index) => (index.typeValue === 'string'))
                .forEach((variable) => {
                  varData.push({name: `${variable.label}--global`, value: variable._id});
                });
            }
            const defaultValue = this.model.attributes.variableForInput || '';
            const traitVariableInput = {
              type: 'select',
              name: 'variableForInput',
              label: 'Variable',
              changeProp: 1,
              options: varData,
              default: defaultValue,
            };
            this.model.addTrait(traitVariableInput);
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
