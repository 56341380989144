import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';
import frameComponents from './frameComponents';

export default grapesjs.plugins.add('gjs-modal-container', (editor, opts) => {
  const config = {
    blocks: [
      'modalContainer',
    ],
    name: 'modalContainer',
    stylePrefix: 'gjs-',
    labelScroll: 'Modal',
    defText: 'Modal Ejemplo',
    category: 'Básicos',
    ...opts,
  };
    // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
  frameComponents(editor, opts);
});
