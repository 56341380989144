export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .modalStyle {
    padding: 10px;
    min-height: 100px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg class="icon-lofty-component-block" width="50px" height="50px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z"/>
    <path opacity="0.4" d="M19 9.5C16.52 9.5 14.5 7.48 14.5 5C14.5 4.28 14.69 3.61 14.99 3H7.52C4.07 3 2 5.06 2 8.52V16.47C2 19.94 4.07 22 7.52 22H15.47C18.93 22 20.99 19.94 20.99 16.48V9.01C20.39 9.31 19.72 9.5 19 9.5Z" fill="#292D32"/>
    <path d="M11.75 14H6.75C6.34 14 6 13.66 6 13.25C6 12.84 6.34 12.5 6.75 12.5H11.75C12.16 12.5 12.5 12.84 12.5 13.25C12.5 13.66 12.16 14 11.75 14Z" />
    <path d="M15.75 18H6.75C6.34 18 6 17.66 6 17.25C6 16.84 6.34 16.5 6.75 16.5H15.75C16.16 16.5 16.5 16.84 16.5 17.25C16.5 17.66 16.16 18 15.75 18Z" />
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelScroll}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Modal', id: 'component-modal-lofty-editor'},
    disable: opts.isDisabled,
    content: `
      <div
        data-gjs-slugLofty="mainModalContainer"
        data-gjs-name="Contenedor de Modal"
        data-gjs-resizable="{bc: 1}"
        style="min-height: 100px; padding: 10px"
        data-gjs-droppable=false
      >
        <div
          class="modal show"
          data-gjs-name="Componentede Modal"
          data-gjs-slugLofty="modalComponent"
          data-gjs-draggable=false
          data-gjs-droppable=false
        >
          <div
            class="modal-dialog"
            data-gjs-name="Dialog de Modal"
            data-gjs-slugLofty="modalDialog"
            data-gjs-draggable=false
            data-gjs-droppable=false
          >
            <div
              data-gjs-slugLofty="modalArea"
              data-gjs-name="Modal"
              data-gjs-resizable="{bc: 1}"
              class="modal-content"
              data-gjs-draggable=false
              data-gjs-droppable=false
            >
              <div
                data-gjs-name="Cabecera de Modal"
                data-gjs-slugLofty="modalHeader"
                class="modal-header"
                data-gjs-draggable=false
              >
                <h2>Lorem Ipsum</h2>
              </div>
              <div
                data-gjs-name="Contenido de Modal"
                data-gjs-slugLofty="modalBody"
                class="modal-body"
                data-gjs-draggable=false
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non vehicula leo, vitae consectetur diam.</p>
                <p>${opts.defText}</p>
              </div>
              <div
                data-gjs-name="Pie de Modal"
                data-gjs-slugLofty="modalFooter"
                class="modal-footer"
                data-gjs-draggable=false
              >
                <h3>Lorem Ipsum</h3>
              </div>
            </div>
          </div>
        </div>
        <button
          data-gjs-slugLofty="modalButton"
          data-gjs-name="Boton de Modal"
          data-gjs-resizable="{bc: 1}"
          data-gjs-draggable=false
          data-toggle="modal"
          data-bs-toggle="modal"
          style="padding: 5px"
        >
          <div data-gjs-slugLofty="modalButtonText" data-gjs-name="Boton de Modal">${opts.defText}</div>
        </button>
      </div>
    ${style}`,
  });
};
