/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
import {
  listDataSimulatorDatabaseAdmin, listFieldsDatabase,
  listSimulatorUserProject,
} from 'api-lofty';

export const byTypeFieldFinder = (field) => {
  if (field.type === 'textrich') {
    return 'fieldDatabaseTextRich';
  } else {
    return 'fieldDatabaseText';
  }
};

export const databaseFieldsGetComponent = (component) => {
  let htmlAdder = '';
  component.forEach((index) => {
    htmlAdder += `
      <div
        data-gjs-resizable="{bc: 1}"
        data-gjs-slugLofty="fieldDatabaseContainer"
        data-gjs-valuelofty="${index.name}"
        data-gjs-name="${index.label}"
        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
      >
        <div data-gjs-slugLofty="${byTypeFieldFinder(index)}" data-gjs-name="${index.label} Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
         {{ ${index.name} }}
        </div>
      </div>
    `;
  });
  return htmlAdder;
};

export const getFieldsBucket = () => {
  const htmlAdder = `
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyOriginalName"
      data-gjs-name="Archivo Original"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyOriginalName" data-gjs-name="Archivo Original" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyOriginalName }}
      </div>
    </div>
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyFileName"
      data-gjs-name="Archivo Llave"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyFileName" data-gjs-name="Archivo Llave" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyFileName }}
      </div>
    </div>
    <div
      data-gjs-resizable="{bc: 1}"
      data-gjs-slugLofty="fieldBucketDatabaseContainer"
      data-gjs-valuelofty="loftyUrl"
      data-gjs-name="Url"
      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyUrl" data-gjs-name="URL" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
        {{ loftyUrl }}
      </div>
    </div>
  `;
  return htmlAdder;
};

export const databaseFieldsPostComponent = (component, userInfoData, dataCaptureInfo) => {
  let htmlAdder = '';
  component.forEach((index) => {
    // 'string' | 'number' | 'boolean' | 'date' | 'relation' | 'user';
    if (index.type === 'string') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelStringDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="formInputDatabase" data-gjs-name="${index.name} Texto" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'date') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelDateDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="date" data-gjs-slugLofty="formDateInputDatabase" data-gjs-name="${index.name} Fecha" style="background-color: transparent" value="2018-07-22" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'number') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
        <label data-gjs-slugLofty="formLabelNumberDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <input data-gjs-resizable="{bc: 1}" type="number" data-gjs-slugLofty="formNumberInputDatabase" data-gjs-name="${index.name} Numero" style="background-color: transparent" placeholder="${index.label}"  name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
        </div>
      `;
    } else if (index.type === 'boolean') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelBooleanDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <input type="checkbox" data-gjs-slugLofty="formBooleanInputDatabase" style="background-color: transparent" data-gjs-name="${index.name} Casilla" name="${index.name}"`;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `/>
          <label data-gjs-slugLofty="formLabelCheckbox" data-gjs-name="${index.label} Booleano"> ${index.name} </label>
        </div>
      `;
    } else if (index.type === 'relation') {
      const relationIDSeeker = index.relationDatabase;
      // console.log(dataCaptureInfo[relationIDSeeker].data);
      const selectionData = dataCaptureInfo[relationIDSeeker].data;
      const listFieldsForeignDatabase = dataCaptureInfo[relationIDSeeker].fields;
      const doc = {};
      doc[index.name] = {};
      doc[index.name].data = selectionData;
      doc[index.name].fields = listFieldsForeignDatabase;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${index.name} Select" name="${index.name}" style="background-color: transparent" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      // ADD OPTIONS
      doc[index.name]?.data?.map((keyInfo) => {
        let label = '';
        const fields = doc[index.name].fields;
        const tempData = keyInfo.data;
        fields.map((field) => {
          if (field.isLabel) {
            label += `${tempData[field.name]}`;
          }
        });
        htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${index.name} Opcion" value="${keyInfo._id}">${label}</option>`;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'user') {
      const resUserData = userInfoData.data;
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldUserDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
      `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      resUserData?.map((index) => {
        htmlAdder += `
          <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
        `;
      });
      htmlAdder += `
          </select>
        </div>
      `;
    } else if (index.type === 'textrich') {
      htmlAdder += `
        <div
          data-gjs-resizable="{bc: 1}"
          data-gjs-slugLofty="fieldRichTextDatabaseAddContainer"
          data-gjs-valuelofty="${index.name}"
          data-gjs-name="${index.label}"
          data-gjs-draggable="[title=addFormDatabase]"
          style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
        >
          <label data-gjs-slugLofty="formLabelRichTextDatabaseS" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
          <textarea data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formRichTextDatabase" data-gjs-name="${index.name} Texto Enriquecido" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
      if (index.required) {
        htmlAdder += 'required';
      }
      htmlAdder += `></textarea>
        </div>
      `;
    }
  });
  return htmlAdder;
};

/**
    =========================================================
    * Get Read DB Fields
    =========================================================

    * Gets All the fields from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllReadFieldsForParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.map((index) => {
    selectFields.push({value: index.name, name: index.label});
  });
  return selectFields;
}

/**
    =========================================================
    * Get Read Relation DB Fields
    =========================================================

    * Gets Relation fields from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllReadRelationFieldsForParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.forEach((index) => {
    if (index.type === 'relation') {
      selectFields.push({value: index.name, name: index.label});
    }
  });
  return selectFields;
}

/**
    =========================================================
    * Get Read DB Fields for Custom
    =========================================================

    * Gets Valid fields for Custom from a Read DB Component.
    * Must have verified component before calling,
    * otherwise it will not work.

    =========================================================
  */
export async function getAllFieldsForCustomParams(component, token, idProject) {
  const idDatabase = component.attributes.dbSelect;
  const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
  const selectFields = [];
  resFieldData.data.forEach((index) => {
    if (
      index.type === 'relation' || index.type === 'string' || index.type === 'number'
      || index.type === 'boolean'
    ) {
      selectFields.push({value: index.name, name: index.label, type: index.type});
    }
  });
  return selectFields;
}

export async function getRelationInfo(relationIDSeeker, token, idProject) {
  const selectionData = await listDataSimulatorDatabaseAdmin({
    token,
    idDatabase: relationIDSeeker,
    idProject,
  });
  return selectionData.data;
}

export async function getForeignFields(relationIDSeeker, token, idProject) {
  const listFieldsForeignDatabase = await listFieldsDatabase({
    token,
    idProject,
    idDatabase: relationIDSeeker,
  });
  return listFieldsForeignDatabase.data;
}

/**
    =========================================================
    * Add Single Field to a DB Component
    =========================================================

    * Creates the HTML of the specified field for a DB component.
    * [For now, just relation is available].

    =========================================================
  */
export async function addSingleField(fieldInfo, type, token, idProject) {
  let retVal = '';
  try {
    if (type === 'relation') {
      const selectionData = await getRelationInfo(fieldInfo._id, token, idProject);
      const listFieldsForeignDatabase = await getForeignFields(fieldInfo._id, token, idProject);
      const doc = {};
      doc[fieldInfo.name] = {};
      doc[fieldInfo.name].data = selectionData;
      doc[fieldInfo.name].fields = listFieldsForeignDatabase;
      let htmlAdder = '';
      htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
                    data-gjs-valuelofty="${fieldInfo.name}"
                    data-gjs-name="${fieldInfo.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${fieldInfo.name}">${fieldInfo.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${fieldInfo.name} Select" name="${fieldInfo.name}" style="background-color: transparent" data-gjs-id="${fieldInfo._id}"
                `;
      if (fieldInfo.required) {
        htmlAdder += 'required';
      }
      htmlAdder += '>';
      // ADD OPTIONS
      doc[fieldInfo.name]?.data?.map((keyInfo) => {
        let label = '';
        const fields = doc[fieldInfo.name].fields;
        const tempData = keyInfo.data;
        fields.map((field) => {
          if (field.isLabel) {
            label += `${tempData[field.name]}`;
          }
        });
        htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${fieldInfo.name} Opcion" value="${keyInfo._id}">${label}</option>`;
      });
      htmlAdder += `
                    </select>
                  </div>
                `;
      retVal = htmlAdder;
    } else if (type === 'userfield') {
      // console.log(fieldInfo);
      let htmlAdder = '';
      const userInfoData = await listSimulatorUserProject({token, idProject});
      fieldInfo.forEach((index) => {
        htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldUserDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
                `;
        if (index.required) {
          htmlAdder += 'required';
        }
        htmlAdder += '>';
        userInfoData?.data.map((index) => {
          htmlAdder += `
                    <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
                  `;
        });
        htmlAdder += `
                    </select>
                  </div>
                `;
      });
      retVal = htmlAdder;
    }
    return retVal;
  } catch (error) {
    return null;
  }
}
