export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .listContainer{
    width: 100px;
    padding: 5px;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
    <svg class="icon-lofty-component-block" width="32px" height="32px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 26.016v-20q0-2.496 1.76-4.256t4.256-1.76h20q2.464 0 4.224 1.76t1.76 4.256v20q0 2.496-1.76 4.224t-4.224 1.76h-20q-2.496 0-4.256-1.76t-1.76-4.224zM4 26.016q0 0.832 0.576 1.408t1.44 0.576h20q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-20q-0.832 0-1.44 0.608t-0.576 1.408v20zM8 24v-4h4v4h-4zM8 18.016v-4h4v4h-4zM8 12v-4h4v4h-4zM14.016 24v-4h9.984v4h-9.984zM14.016 18.016v-4h9.984v4h-9.984zM14.016 12v-4h9.984v4h-9.984z"></path>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelList}
    </div> 
    `,
    category: opts.category,
    attributes: {id: 'component-list-lofty-editor'},
    content: `
      <ol data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="listComponent" data-gjs-name="Lista" style="width: 100px; padding: 5px;">
        <li data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="listItem" data-gjs-name="Item" style="padding: 5px; baground: transparent">List Item</li>
        <li data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="listItem" data-gjs-name="Item" style="padding: 5px; baground: transparent">List Item</li>
        <li data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="listItem" data-gjs-name="Item" style="padding: 5px; baground: transparent">List Item</li>
      </ol>
    ${style}`,
  });
};
