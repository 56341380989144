export function verifyExistingComponent(component, searcher) {
  let retVal = false;
  const componentData = component.get('components');
  const searchForData = componentData.filter((index) => (index));
  searchForData.forEach((index) => {
    if (index.attributes.sluglofty === searcher) {
      retVal = true;
    }
  });
  return retVal;
}
