/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {listFieldsDatabase, getOneDatabase} from 'api-lofty';
import {databaseFieldsGetComponent, getFieldsBucket} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function restoreComponent(component) {
    const componentData = component.get('components');
    const eliminateComponent = componentData.filter((index) => (index));
    eliminateComponent.map((index) => (index.collection.remove(index)));
  }

  function typeOfVariable(component, data) {
    const searchVariable = data.find((index) => (index._id === component.value));
    return searchVariable;
  }

  function addTrait(model, type, answerValue) {
    if (type === 'local') {
      const variableData = opts.localVars;
      const optionData = variableData.map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'localVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    } else {
      const variableData = opts.globalVars;
      const optionData = variableData.map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'globalVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [{
          type: 'select', // Type of the trait
          label: 'Tipo de variable', // The label you will see in Settings
          name: 'variableHandle',
          options: [
            {value: 'local', name: 'Local'},
            {value: 'global', name: 'Global'},
          ],
          changeProp: 1,
        }],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('variableComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:variableHandle', this.configureVariableComponent);
        this.listenTo(model, 'change:localVars', this.addVariableToComponent);
        this.listenTo(model, 'change:globalVars', this.addVariableGlobalToComponent);
        this.listenTo(model, '', this.restoreData());
      },
      /*
              -- Configure Type of Variable TRAIT --
        Configures the display of the modal inside the component
      */
      configureVariableComponent() {
        try {
          if (this.model.attributes.sluglofty.includes('variableComponent')) {
            const getTypeVariable = this.model.getTrait('variableHandle').attributes.value;
            if (getTypeVariable === 'local') {
              restoreComponent(this.model);
              this.model.removeTrait('globalVars');
              addTrait(this.model, 'local', '');
            } else {
              restoreComponent(this.model);
              this.model.removeTrait('localVars');
              addTrait(this.model, 'global', '');
            }
          }
        } catch (error) {
          //
        }
      },
      async addVariableToComponent() {
        if (this.model.attributes.sluglofty.includes('variableComponent')) {
          restoreComponent(this.model);
          const getTypeVariable = this.model.getTrait('localVars').attributes.value;
          const getOption = this.model.getTrait('localVars').attributes.options;
          const filterData = getOption.find((obj) => (obj.value === getTypeVariable));
          const searchType = typeOfVariable(filterData, opts.localVars);
          let htmlAdder = '';
          if (searchType.type === 'arrayDatabase') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="listArrayVariable" data-gjs-name="Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
                <div data-gjs-slugLofty="itemListArrayVariable" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
                </div>
              </div>
            `;
          } else if (searchType.type === 'database') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="oneDataVariable" data-gjs-name="Dato de Coleccion de Datos" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                ${resFieldsReact}
              </div>
            `;
          } else if (searchType.type === 'cartArray') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="listCartData" data-gjs-name="Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
                <div data-gjs-slugLofty="itemCartData" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
                  <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                    Cantidad: 
                  </div>
                </div>
              </div>
            `;
          } else {
            htmlAdder = `${filterData.name}`;
          }
          this.model.append(htmlAdder);
        }
      },
      async addVariableGlobalToComponent() {
        if (this.model.attributes.sluglofty.includes('variableComponent')) {
          restoreComponent(this.model);
          const getTypeVariable = this.model.getTrait('globalVars').attributes.value;
          const getOption = this.model.getTrait('globalVars').attributes.options;
          const filterData = getOption.find((obj) => (obj.value === getTypeVariable));
          const searchType = typeOfVariable(filterData, opts.globalVars);
          let htmlAdder = '';
          if (searchType.typeValue === 'arrayDatabase') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="listArrayVariable" data-gjs-name="Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
                <div data-gjs-slugLofty="itemListArrayVariable" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
                </div>
              </div>
            `;
          } else if (searchType.typeValue === 'database') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="oneDataVariable" data-gjs-name="Dato de Coleccion de Datos" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                ${resFieldsReact}
              </div>
            `;
          } else if (searchType.typeValue === 'cartArray') {
            const token = opts.token;
            const idProject = opts.idProject;
            const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: searchType.idDatabase, idProject});
            const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
            let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
            if (typeDB.data.isBucket) {
              const bucketFields = getFieldsBucket();
              resFieldsReact += bucketFields;
            }
            htmlAdder = `
              <div data-gjs-slugLofty="listCartData" data-gjs-name="Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
                <div data-gjs-slugLofty="itemCartData" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
                  <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                    Cantidad: 
                  </div>
                </div>
              </div>
            `;
          } else {
            htmlAdder = `${filterData.name}`;
          }
          this.model.append(htmlAdder);
        }
      },
      restoreData() {
        if (this.model.attributes.sluglofty.includes('variableComponent')) {
          if (this.model.attributes.variableHandle) {
            if (this.model.attributes.variableHandle.includes('local')) {
              addTrait(this.model, 'local', this.model.attributes.localVars);
            } else {
              addTrait(this.model, 'global', this.model.attributes.globalVars);
            }
          }
        }
      },
    }),
  });
};
