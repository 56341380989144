export default [
  {
    name: 'Arial',
    value: 'Arial, Helvetica, sans-serif',
  },
  {
    name: 'WindSong',
    value: 'WindSong',
  },
  {
    name: 'Montserrat',
    value: 'Montserrat',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, sans-serif',
  },
  {
    name: 'Arial Black',
    value: 'Arial Black, Gadget, sans-serif',
  },
  {
    name: 'Brush Script MT',
    value: 'Brush Script MT, sans-serif',
  },
  {
    name: 'Comic Sans MS',
    value: 'Comic Sans MS, cursive, sans-serif',
  },
  // listado
  {
    name: 'Courier New',
    value: 'Courier New, Courier, monospace',
  },
  {
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    name: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  {
    name: 'Impact',
    value: 'Lucida Sans Unicode, Lucida Grande, sans-serif',
  },
  {
    name: 'Lucida Sans Unicode',
    value: 'Comic Sans MS, cursive, sans-serif',
  },
  {
    name: 'Times New Roman',
    value: 'Times New Roman, Times, serif',
  },
  {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS, Helvetica, sans-serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
  },
  {
    name: 'Nanum Gothic',
    value: '\'Nanum Gothic\', sans-serif',
  },
  {
    name: 'Didact Gothic',
    value: '\'Didact Gothic\', sans-serif',
  },
  {
    name: 'Baloo 2',
    value: '\'Baloo 2\', cursive',
  },
  {
    name: 'Italiana',
    value: '"Italiana", sans-serif',
  },
];
