import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-paymentmethod-button', (editor, opts = {}) => {
  const config = {
    blocks: [
      'paymentmethod',
    ],
    name: 'paymentmethod',
    stylePrefix: 'gjs-',
    labelPaymentMethodButton: 'Metodo de Pago',
    defText: 'Metodo de Pago',
    category: 'Pagos',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
