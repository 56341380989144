export const imageIconSVG = `
<svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
<image style="overflow:visible;" width="383" height="636" xlink:href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEAYABgAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAwZAAAbFgAAMYz/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAn0BgAMBIgACEQEDEQH/
xADGAAEAAgMBAQAAAAAAAAAAAAAAAQMCBAUHBgEBAQEBAAAAAAAAAAAAAAAAAAIBAxAAAAQDBgYC
AQQCAwEBAAAAAAECBDESAxAgETMFNTBAExQ0ByIGMlBCFRYhJEFFNmAjEQABAgIGBQkIAgEDBQAA
AAABAAIRAyAhMbFzBBBBEnIzUXGR0TKSspPTMEBhgaEiEzRQI0JSUxTBYoJDYxIAAgEDAgMIAwAA
AAAAAAAAQAEgABFxgQIwcCEQUGCAMaHBEiIyYv/aAAwDAQACEQMRAAAA4GrZqF8Uxc7u/wAnoZty
nsTvNV9A027mc9s5GousNVnrFykXKRcpFykXKRcpFykXKRcpF12ncen2V2GXn3oPnJ84oF6gX2al
hfqbeoImLm7oc/oZrPBOtnWG5Zzxuzojbag2tUAAAAAAAAAF1Nx6dZhmZ+c+h+dnywAFldhfqbeo
ImLm/oc/p5uDJO4shiyGLIYshiyGLIYsxgyGLIYshiyGLIYshiyGN1dx6TdXcYed+j+dHyoAFldh
fqbeoBc3dLm9LNkToAAAAAETEkTEgAAAAAEXU3HpeeNg869H85PlQALK7C/U29QC5v6PO6ObInQO
je452HHHY1NLsHHABExJExIBdu00m5jqd052fL6RzYzwAIupuPTLK8zPzn0bzk+VAAsrsL9Tb1AL
m/o87o5sidA7HH7HHAHY4/YOOACJiSJiQDdpupI7vC7p870ub0jn4Z4AEXU3HpdmFhl5z6N5yfKg
AWV2F+pt6gFzf0ed0c2ROgdK3kDruQOvr6AAAiYkiYkAv3eWOm5g6VnJEwAEXU3HpllVhn5z6N5y
fKgAWV2F+pt6gTFzf0ed0c2ROgOvyB1seXkdLlgABExJExIBs7XMHVjljpY88bOsAEXU3HpdmnZz
rY87+8+A18yLkBZXYX6m3qAm5u6PO6WbCU7CRCRCRCRCRCREhjMiEiEiEiEiEiEjG+q09FvovjXn
voXnt58uABZXYX6m3qEjpN3S53QnSU7CRCRCRCRCRCRExJBJCRCRCRCRCRCRF1Nx6LfRfO4ed+ge
e1nzwAFldhfqbeoTMZdIt6HP6E1InQB1jku7Ucd1OWAARMSRMSACSHa5xrAAAi6m49FvovnaPPfQ
vPaz54ACyuwv1NvUJyxy6Rb0NDfzZEUAyxHa1NvUNLEAAImJImJAGxrj6HY430B8lh9P88UgAi6m
49FvovnaPPfQvPaz54ACyuwv1NvUJyxy6Rdv6G/myIoADv6nMEAAAiYkiYkAAdTlj6PgVgACLqbj
0W+i+dw879H89rPlwALK7C/U29QmYnpF3Q5/QzZEUAmNg13VHKbOsAARMSRMSADaNV0czlulpFQA
IupuPRb6LeV5ee+gefVnzAuQFldhfqbeoTMT0i7oc/oTsoTUoEoEoEoEoEoCYkicZJQJnEWKxZji
JQJQF1F56ZdTcPOfRvOT5UACyuwv1NvUJJ6Td0ed0Z0hOygSgSgSgSgSgSAgSgSgSgSgSgSgTbTc
em203Dzn0bzk+VAAsrsL9Tb1CR0m/oc/oTsidAAAAAAiYkiYkAAAAAAi6m49MupuHnPo3nJ8qABZ
XYX6m3qEjpN/Q5/QnZE6Bv358g6ivSOjq9rSOMACJiSJiQAdA577sfCPu/kjRABF1Nx6bbTcPOfR
vOT5UACyuwv1NvUBFzsdHm9LNkToHYqu7ht8vrj4LpbeoccAETEkTEgDs8bc19xwtrLHR+T7PzOb
qDcAi6m49MupuHnPo3nJ8qABZXYX6m3qCJxudjpczp5sidA6l3FHbcQdrV54AAiYkiYkAAAAAAi6
m49NtpuHnPo3nJ8qABZXYX6m3qDHLG8v6fM6eJE6A6vKk6c8uw3ebMAAETEkTEgCYHQamZZp30AA
EXU3Hpl1Vo859G85PlQALK7C/U29QYZ4XOx0+Z1M2Ep2EiEiEiEiEiEiJDFkMWQxZDFkMWQxZDFk
Mb6rT022m4ec+jecnyoAFldhfqbeoIlc3dPmdLNJTsJEJEJEJEJEJBEkEkJEJEJEJEJEJEXU3Hpl
1Vo859G85PlQALK7C/U29QC5v6PN6WbInQBmYN3TIbFRgACJiSJiQAy3TQb40G/BogAi6m49Mupu
HnPo3nJ8qABZXYX6m3qDHLG82OlzeliROgXdPnWl9ulibujMGsACJiSJiQDdtqxMGuNjqcPrnLwz
wAIupuPTLqrR5z6N5yfKgAWV2F+pt6gwzqvNrp8vqYkToGxuc7eNjT1t8ak6wABExJExIBu44dI4
rrjkddccXCYAIupuPTLqbh5z6N5yfKgAWV2F+pt6gqtxvLupzOniROgDYNdt4GutqAAImJImJDey
Oe6WJz21aaDpYnPdGTmtnWIupuPTLqrR5z6N5yfKgAWV2F+pt6giYub+lzujmyhOygTfrjdy0Bs6
8CUCUBMSROMlsVi5SLJqFqoWzSM8YC6i89MupuHnPo3nJ8qABZXYX6m3qAXN+/z9/NlCdlAlAlAl
AlAlAmcRkxGTEZMRkxGTEZMRkxGV2vceoW1Wjzn0bzk+VAAsrsL9Tb1AReX7/P6GHR521O6+zqbJ
bbRea2S00cNrVAAAAAAAAAAF1Nx6fdTcPOfRvOT5UACyuwv1NvUEThc7O/z+jmwlOwkQkQkQkQkQ
kQkQkQkQkQkQkQkQkRdVcen203Dzn0bzk+VAAsrsL9Tb1BEzc29Pm9LNCdAAEBIhIhIhIhIhIhII
kAAAW1Wnpl1Nw859G85PlQALK7C/U29QknpN3R53RkE0AAiYJAAAAAABExIAAAtqtPTbabh5z6N5
yfKgAWV2F+pt6hMw6Tf0ed0Z0J0ABEwSAAWk1/R0HGq7fEAAImJAAAFtVp6ZdTcPOfRvOT5UACyu
wv1NvUBFzsdLmdPNCdAARMEgAS6BznWrOa62oagAImJAAAFtVp6bbTcPOfRvOT5UACyuwv1NvUGG
dVTtdTldVoToACJgkAAAAAAETEgAAC2q09MuqtHnPo3nJ8qABZXYX6m3qCi/WrNvr8frYyYs3JiM
mIyiBkwGbAZsBmwGbAZsBmwGU4SZMRkxGTEZW0XHp1tNw859G85PlQALK7C/U29Qau1q7m11eV1Q
XZtKdo1G3ga7dpKF0lC+gAAAAAAAAAXU3Hp91Vo859G85PlQALK7C/U29Qau0I6vLHU6ny46u/8A
Nj6nH5gfW4fKj6W75QfUcvljqOWOo5Y6jljqOWOo5Y6jljqOWOo5Y6jljqXcUeyW+LD2nz75cAAL
K7C/U6OqULxQvFC8ULxQvFC8ULxQvFC8ULxQvFC8ULxQvFC8ULxQvFC8ULxQvFC8ULxRZnYf/9oA
CAECAAEFAAgLjyyAuPLIC48sgLjyyAuPLIC48sgLjyyAuPIdamE1EKO4gLjyCPwu0wuPII/C7TC4
8gj8LtML/LhEMP8AFxH4XaYX+XII/C7TC48gR1CJJrM7lMLjy1MLjy1MLjy1MLjyyAuPCIf8nHgo
Co8sgKjyyAqPLIC48sgLjyyAuNpcmgKjyyAqPLIC48sgLjyyAuPLIC48sgLjy1MLjy1MLjyyAuPL
JCo8skKjyxA//hv/2gAIAQMAAQUACgmHLKCYcsoJhyygmHLKCYcsoJhyygmHLKCYch01A0KIrigm
HIK/K6oJhyCvyuqCYcgr8rqwmHDx/wA3FfldWEQ5BX5XVhMOQOQzMk4XFhMOWUEw5ZQTDllBMOWU
Ew4Rj/guEoFDljBQ5ZQTDllBMOWUEw5YwULhckYKHLGC4WPFUEw5ZQTDljBQ5ZQKHLKCYcsoJhyy
gmHLKCYcsoJhyxguXMFzn/H6T//aAAgBAQABBQDVHrxOp989HfPR3z0NXz063dux3bsd27Hdux3b
sd27Hdux3bsd27Hdux3bsd27Hdux3bsd27Hdux3bsd27Hdux3bsd27Hdux3bsd27Hdux3bsd27Hd
ux3bsd27Hdux3bsM3bo3jY1HRIznH35y4pat3z0d89HfPR3z0d89FB89Otqu6WtM6yhoBV2y0pSv
SUkdB3R6lByxImTphSoU6jBM9Jo2rLLT6NRTxsigfKMvNa5JfmPYW73aGfqu6WtM6xNaqmmGjtLd
HfyCpqaFUe/Smh/JGa6b2nSXUeoWHLzrUuUZea1w6JYdQewt3u0M/Vd0ta536Ay81qeNHBc2FQew
Jv5e7Qz9V3S1oWNbpmOmY6ZjpmOmY6ZjpmOmY6ZjpmOmY6ZjpmOmY6ZjpmOmY6ZjpmOmY6ZjpmOm
Y6ZjpmOmY6ZjpmOmY6ZjpmOmYZIMnrdZlR6h4lUMx9/VNq92hn6rulrPPM8BiYxMYmMTGJjExiYx
MYmMTGJjExiYxMEeNmJjExiYxMYmMTGJjExiYxMYmMTGJjExiYaeY1SXRwIYEPYJEWr3aGfqu6Ws
8848YohMOKqDTzGmHRlSdTpoHsBJJ1e7Qz9V3S1nnnHjFEJhxVQaeY1yS/Mewt3u0M/Vd0tZ55xu
MtGqO6H9fH9fH9fH9fGoaXUYFcKITC1q3U5cL0tmhSdMZrVT0hann8axFLR29dSilVaqDTzGuPRL
Gcewt3u0M/Vd0tZ55xuF/wCduattlwohMLdH3F75jTyv+zOOgbhVzbVQaeY1LCiX5j2Fu92hn6ru
lrPPONwv/O3NW2y4UQmFuj7i98xp5X/ZnHQNwq5tqoNPMa4dEsOoPYW73aGfqu6Ws8843C/87c1b
bLhRCYW6PuL3zGnlf9mcdA3Crm2qg08xrkl+Y9hbvdoZ+q7pazzzjcZatTbtf5bTx/LaeP5bTx/L
aeNS1InqLhRCYWs3HbOaj3Sqi0PNJQunq6Se91pAoalpzZajmVaqDTzGuPRL8x7C3e7Qz9V3S1nn
nHjFEJhxVQaeY1w6JYTj2Fu92hn6rulrPPON2kmibOozZqFTT6ODhiyp0LpRCYWsiI1obIqqNk3q
rS1YSk0ZrNbVFN8+z7VQaeY1w6Mqp8Fj2ASi1e7Qz9V3S1nnnG+a1Gm8UQmHFVBp5lB4aaXe/wCe
9Mfe6p1dWu0M/Vd0tZ55kY+Y+Y+Y+Y+Y+Y+Y+Y+Y+Y+Y+Y+Y+YIjs+RD5j5j5j5j5j5j5j5j5j5j
5j5j5j5GGnlsvHs+/wC7XaGfqu6Ws8/9AaeWx8ez7/u12hn6rulrTPMx8h8h8h8h8h8h8h8h8h8h
8h8h8h8gR2FiYwMYGMDGBjAxgYwMYGMDGBjAxgYwMHiQaeWy8cVqnTR93WpeqXaGfqu6WtM848Yo
hMOKqDTy2Xjh3+H3Tc7tDP1XdLWmeceMUQmHFVBp5bLxw7/D7pud2hn6rulrTPON2ijTKGn6XS0z
UK71WmNHT+np6mV0ohMLhEZm2+vGui+YVmNa8qDTy2Pjh3+H3Tc7tDP1XdLWuecbqlrUn6t52t7l
1FyXSiEwuMKlOk81hm4cutWoLRpVSjVpXlQaeWy8cO/w+6bndoZ+q7pa1zzje+redre5XiiEwusd
Zds0ae+OoxQ8aarUdN1NnFxUGnlsvHDv8Pum53aGfqu6Wtc443vrdCpRca43q97eKITC9pOqJbEm
ro2mhxWW4rXFQaeWy8cVqfUR93Qqnql2hn6rulrXOON6dQNSjvlEJhxVQaeWx8ez7/u12hn6rulr
TPON0iMwZGXAKITDiqg08tkf+viQxIfft2u0M/Vd0taZ5xusqx0nBkxq0klp5B4mglV0ohMLmn0k
VXHZUaq06UmoVPSkrDmiVCtcVBp5jdog6XZoHZoH32kVLVrtDP1XdLWmeceMUQmFxKlIM6tVRlWq
kXVqkFKUo7ioNPMbYdGz2Fu92hn6rulrTPMjMYLGCxgsYLGCxgsYLGCxgsYLGCxgsYLGCwRGVhGZ
CYxMYmMTGJjExiYxMYmMTGJjExiYxiZhp5jbJs9hbvdoZ+q7pa0z/wBAaeY2ybPYW73aGfqu6WtM
8zwGJjExiYxMYmMTGJjExiYxMYmMTGJjEwR42f5MfIfIfIfIfIfIfIfIfIfIfIfIfIf5INPMbZNn
sLd7tDP1XdLWmeceMUQmHFVBp5jbDo2ewt3u0M/Vd0taZ5x4xRCYcVUGnmNsmz2Fu92hn6rulrTP
ONxno7h5R/rzkf15yP685H9ech/ptdjdKITDiqg08xtk2ewt3u0M/Vd0taZ5xuEZl9eJSzDvS3DZ
lRxXV1bT9Po6fq22XCiEwuaKzpvHqdNYpImTBQVprFRay0Q0e3FQaeY2ybPYW73aGfqu6WYhnnnG
4X/ndCYd27cUEV6DLQkUmrlFejV1bbLhRCYXPq/nBsw1Qn5Q+zbhcVBp5jbDo2ewt3u0M/Vd0tZZ
5xuJI1fX9Ja0WTQqiDE6R9lZJUWrbZcKITC5pb0mLtP2HTTH8/pgV9h00i1N73ru4qDTzG2TZ7C3
e7Qz9V3S1l5BxuMdVbUWn8tpQLV9LIfzOmBWr6WotT1Kk8RcKITDiqg08xtj0bPYW73aGfqu6Wss
848YohMOKqDTzG2TZ7C3e7Qz9V3S1j5BxuqZs1FS01vUNOl0DKowaIvFEJhcLDFOnJNSNJWtB6ZW
I4XVQaeY2ybPYW73aGfqu6WsvION3ExMohUdV6qcTvFEJhdJ04IifuyHeubyoNPMbZNnsLd7tDP1
XdLMQxzzxGKhioYqGKhioYqGKhioYqGKhioYqGKhioFjYSsBOQnITkJyE5CchOQnITkJyE5CchOQ
NWIaeY2ybPYW73aGfqu6WsfI/QGnmNsmz2Fu92hn6rulmAZF/sGeAxMYmMTGJjExiYxMYmMTGJjE
xiYxMYmCPGzEhMQmITEJiExCYhMQmITEJiExCYhMQxINPMbZNnsLd7tDP1XdLWeeceMUQmHFVBp5
jbHo2ewt3u0M/Vd0tZ55x4xRCYcVUGnmNsmz2Fu92hn6rulmIZn/ALBxu06VWqqppGoU6ZpNJt2D
tydWmqlUuFEJhbTprqr/AIPUx/B6mP4PUx/B6mFaLqSShcVBp5jbDo2ewt3u0M/Vd0sxDLyDjcaI
TUc6hqK2deq/d0m9HU6dek11V24e6l51wohMLdH3LU3rtD/v3o796O/ejQHTis7q5lqoNPMbZNns
Ld7tDP1XdLWXkHG4w83WdzdeE18fTPP1LzrhRCYW6PuWq7hb9c8yrm2qg08xtk2ewt3u0M/Vd0sx
DLyDjcYebqTNy51VzpTpTWhSq06OlsHXcP1JW9uFEJhbo+5aruFv1zzKubaqDTzG2TZ7C3e7Qz9V
3SzEMD/2Djco1Do1XWtvXBor1qa6evO0ocP3bk7pRCYWsHCGzuvU+vuK0v1wS/XBL9cDV3obJSzm
VaqDTzG2HRs9hbvdoZ+q7pZgGHkHHjFEJhxVQaeY2ybPYW73aGfqu6WYBkX+wcbrVopyS9NrJUbB
0lNdtWbndKITANG9FbWlp9NRnpCjItJNSKFGn0S0w1hGl0zNellTV/DrlVpiJHTQmxBUGnmNsmz2
Fu92hn6rulrPPON1s6qtlJ1OuldTVKiydPKjo7pRCYBFerTLrVcTcuDLua4RVqUx3FfAq9ZIVXrK
HcV8O6cYqWtdioNPMbZNnsLd7tDP1XdLCDPPM8DmSJkiZImSJkiZImSJkiZImSJkiZImSJkgjxME
ZkJjExiYxMYmMTGJjExiYxMYmMTGJjBmZhp5jbJs9hbvdoZ+q7pYQanhWnEwmEwmEwmEwmEwmEwm
EwmE4nMTmJzE5icxOYnMTmJzE5icxOYnMTmJzE5hmozeNsejZ7C3e7Qz9V3S1rnfoDLzW2TZ7C3e
7Qz9V3SzENc6ynoOo1aa0GhbJvTWgqVB02r6ZTOuhjMktMrdStTKkvlmXmtsmz2Fu92hn6rulrTO
spak8pNgzcopF129Bub9udWg4oKam+Qpw/cUnFXlmXmtsmz2Fu92hn6rulhBrnfoDLzW2PRs9hbv
doZ+q7pZ/kM8TrSqEqhKoSqEqhKoSqEqhKoSqEqhKoSqEqhKoSqEqhKoSqEqhKoSqEqhKoSqEqhK
oSqEqhKoSqEqgySonrbJs9hbvdoZ+q7pZgGWfwZiGJDEhiQxIYkMSGJDEhiQxIYkMSGJAjI+C08x
th0bPYW73aGfqu6WYBmX/wC/AVDjfu4DTzG2TZ7C3e7Qz9V3S1pn8BUON+7gNPMbZNnsLd7tDP1X
dLWmfwFQ437uA08xtk2ewt3u0M/Vd0taZ/AVC9WaOKCZFCu1rtzu/u4DTzG2HRs9hbvdoZ+q7pa0
z+AqF1tWKhXdfY2xEX2SnjrerN3dG7+7gNPMbZNnsLd7tDP1XdLWefwFQumlRFbgd393AaeY2ybP
YW73aGfqu6Wss/gKhcLDFa6azqnpiVETCoaS0uZ6qgZXP3cBp5jbJs9hbvdoZ+q7pZiGPkcBUON+
7gNPMbZNnsLd7tDP1XdLMQwP/Y4Cocb93AaeY2ybPYW73aGfqu6WYjT/ACeAqHG/dwGnmNsmz2Fu
92hn6rugOA088HE5CchOQnITkJyE5CchOQxQMUDFAxQMUDFAxQMUDFAxQMUDFAxQMUAlJITkJyE5
CchOQnITkJyDNRG8bZNnsLd7tDP1XdAcBp/kfoDLzW2TZ7C3e7Qz9V3QHAaf5FhMnZkpJpNk0pV6
blohFOoxc0ic6bWo1jZOSq1mbiimoyc00LZuUU+UZea2x6NnsLd7tDP1XdAcMDDD/DidInSG/wBi
c0GaqhKVpjilTom+o0yqV29Kmp40VVou21FdF43Q0cvG8tRw2qtp0idInSJ0idInSJ0idInSJ0id
InSJ0idInSJ0idInSJ0idIZrQTxs9ZFR75kO+ZD79VpVdWu0M/Vd0/TqGfqu6fp1DP1RDP8Ak5GQ
kZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCR
kJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQkZCRkJGQoIZdb//aAAgBAgIGPwCmQyGQyGQyGQwv
2qyd3FhbcSYW3EmFtxJ8bMNuJMLbiTCS+i6f1X5bUlm8WQyGQyHxb0uEyGQyGQyHBa9i1CZDIZDI
ZDIZDIZDIfjf/9oACAEDAgY/AOXfpV2h3mSCeZIJ5kgnmSCeZIK/29q6O+kVz1VPT5Iz5N7d1f/a
AAgBAQEGPwDONbPmACfNAAe6AG2fiv2Jvfd1r9ib33da/Ym993WgDmJp/wDN3WuPM77utceZ33da
48zvu61x5nfd1rjzO+7rXHmd93WuPM77utceZ33da48zvu61x5nfd1rjzO+7rXHmd93WuPM77utc
eZ33da48zvu61x5nfd1rjzO+7rXHmd93WuPM77utceZ33da48zvu61x5nfd1rjzO+7rXHmd93WuP
M77utceZ33da48zvu61x5nfd1rjzO+7rXHmd93WuPM77utceZ33day4M6YQZjYjaPLzppcY1C4Ii
NUBAaJTZU18tv4QYNcWi08i/Ym993Wv2Jvfd1r9ib33da/Ym993Wv2Jvfd1qWDmJvab/AJu5edZz
Hm+N1AaRmW5uWJcIuiD9vOnNa7aaDAO5fis26LWubLBa91jTELLOJa5z3FrprLOYwRLCWtkutcAN
qJhtVVqIL3Og0h8AZbo/EWJzpsw/ilymvcQBGuwBOcyY4SZbNuZEDaHw5Fl3S5hEmfERdDaaW6kA
wPBrBEwAWchHuuXxWXptUKh86hWnVahXy26JWCLzSl7zb1nMeb43UBpMtryGOtbGo6J0t7Nts5uy
a4QripLZLNlkl22Gkxifip0psqH5ztOcXEkGMalMlS5ez+Voa6LiQIcgTtpgMt8sS3sjbs60fxyg
JTm7D2ExLo/FSmCUBJkxgyNsbTFMktaWsYSRE7Rifj7rl8Vl6EI6reYJ1sYDm16JWCLzSl7zb1nM
eb43UB/A5fFZem1xqHyqFSJB+0iz4oVj4qVtf7IhDnNKXvNvWcx5vjdQAsXa+i7X0Xa+i7X0Xa+i
7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+i7X0Xa+
i7X0Xa+i7X0Xa+i7X0WXMY/2svVYFQEIH4C1Qhqjam1D7vipeqEoC2Os0pe829ZzHm+N1Bvz0WKx
WKxWKxWKxWKxWKxWKzTUFYrFYrFYrFYrFYrFYrFYqwsvisvQi2EQPnUFYrB8FKh/si80pe829ZzH
m+N1BvzQ9ufccvisvQhHVbzBOJjGA5taFVlilQqjJB+ppS95t6zmPN8bqDfmh7c+45fFZem1xqHy
qFSdXqFXJbolYIvNKXvNvWcx5vjdQb80KJzH5WSmRhF6/ck9K/ck9K/ck9K/ck9Kluc9sxsyOy5v
womkyQ0wLzCJRY7PSw5pgRA2hBrc9LLiYAQNqm5Z0xrRJG055sgv35fQUWSM5LmPgSGgHUi02gwo
5fFZemxhYLOYJ1kIDn16JWCLzSl7zb1nMeb43UGoUTiUchum6iaUneU/fdepW+L1n8M3aBuOuT94
30cvisvTaoWfOoJ1WoV8tuiVgi80pe829ZzHm+N1BqFE4lHIbpuomlJ3lP33XqVvi9Z/DN2gbjrk
/eN9HL4rL0IEmy3mCdbGAq1a9ErBF5pS95t6zmPN8bqDUKJxKOQ3TdRNKTvKfvuvUrfF6z+GbtA3
HXJ+8b6OXxWXptcahZzBOr1Crkt0SsEXmlL3m3rOY83xuoN+aFE5WdIE5kdoRX6DOlfoM6V+gzpX
6DOlSpbJQlS5UYNFE0mT4bWwYwTpjsq4ucST92soPblXbTTEfdrCnZh8uLJw2S2Opfqu7yMyRli2
ZAgEujai7lMemjl8Vl6bUBULOYJ1WoV9OiVgi80pe829ZzHm+N1BqHtz7jl8Vl6EARZbzBOqrgK+
nRKwReaUvebes5jzfG6g1CkzaAAMsxcdmG1E/OKc8kNBidsOEAYw2dlfYNlxB2QXAxgQI9Ce9jy5
zS6sVwhYKRpOsMzZP4w6za+accxsmaA2LGODYAmsnmTdhw2WloeQQPtga0AY7X2gna1uCDG9ppZt
Eu7W0IlS5eXLTEA/eagfjFQ2dkgAGyv41UcvisvQgY2W8wRcHVEQgh91ltVqlRMf6ReaUvebes5j
zfG6g1D2AaTFrbBTPuOXxWXoDYjACEOYLsGEPqh/WfipbiNmEoD6mlL3m3rOY83xuoNUQtS1LUtS
1LUtS1LUtS1LUtS1KJ16IBalqWpalqWpalqWpalqWpalWsvisvQq0ysEXmlL3m3rOY83xuoN/gcv
isvQ0ysEXmlL3m3rOY83xuoN9yjFWq1Wq1Wq1Wq1Wq1Wq1WqMVl8Vl6GiOs1BSy638QvNKXvNvWc
x5vjdQah7c+45fFZehoHOpeELzSl7zb1nMeb43UGoe3PuOXxWXoaBzqXhC80pe829ZzHm+N1BqFK
XOzMszJ0wmABhUE6T/xtjZbtR2idafIOVLgw9raKl5nJsLCXbLwTGFI04CsmxNfmZwkumdhhtRlz
RV/i7UR7DL4rL0NA51LwheaUvebes5jzfG6g1Ck1rjFreyOSKmYZvCnc6/HE7EY7OqNI05Uyb2Gu
BKkz2TI5aYQGuFjI66l+PMH805pAlPArKH5GFkbIiFPL4rL0NA51LwheaUvebes5jzfG6g1CnMwz
eFO56Z9h+NsHy9TXVwX/AC884bLnfbVU1TchPgXRP4JoEIp8h1ZYYRpZfFZehoHOpeELzSl7zb1n
Meb43UGoU5k6bBjCyAJIriVNnARlmBDgQRTPsTlsyNrLTLQdUU/M5d4nTncNv+mKfOf2nmJpZfFZ
ehohrtClh1v4heaUvebes5jzfG6g1CnaelVkmmfccvisvQ0ysEXmlL3m3rOY83xuoNQpVCKr9gfc
cvisvQr0ysEXmlL3m3rOY83xuoNQpNO1stPajyIPc4OnG0kwrjdBOlxZ+MlpjGuEa0z8UNoj7w0x
aDGqFI09mYNpuy4w5h8EdomWIta0BpES7eQgS0htZ1FwJ6kBtkGAc4kVViP2lOlh20BrpZfFZemk
kiIBqPwXad0rtO6VKaCT/SDXzmlL3m3rOY83xuoNQ9uacWktPKDAqLnuJ5SSoB7gDyEoQe77bKzV
zKLiSeU10svisvTeYXDTKwReaUvebes5jzfG6g1VWq0K0K0K0K0K0K0K0K0K0K0K0K0K0Ku3RCC7
K7K7K7K7K7K7K7K7K7K7K7K7KhCCy+Ky9NrjULhplYIvNKXvNvWcx5vjdQb/AAOXxWXpvMLOYaZW
CLzSl7zb1nMeb43UG6LFYrFYrFYrFYrFYrFYrFZ7ll8Vl6bVCoXDTKwReaUvebes5jzfG6g1D259
xy+Ky9NhyC3mGmVgi80pe829ZzHm+N1BqHtz7jl8Vl6bXGoXDTKwReaUvebes5jzfG6g1CiZzHNY
yMIuMFxpXeC40rvBcaV3guNK7wTDNLXNmdktMRVRPuOXxWXpvMLOYaZWCLzSl7zb1nMeb43UGoUT
Cr+xQBPSpWaJJ2+2P9MbE1r3EMJAcY2BGZK+14A2XA1uWQ3TdRNNsuaIywC5w5YKAkM6F9sphhUY
AKBkM6E6XLEGEBzRyRpZfFZem1QqFw0ysEXmlL3m3rOY83xuoNQonEQc4f1Svudz6gnSXj7XCCnS
pwBfNiAeQCxOy80mMswgSshum6iabtw6Js5jzJkl5MDXtDmVdqG4KWXxWXpvMLeYaZWCLzSl7zb1
nMeb43UG/NCjsi0zYBNl7TfyO+55iLSqnDpXaHShnJcIiqZDk5VkN03UTTbOcItrDgOQqt5HyXF+
iqeTzBOnAQbY0fAUsvisvTa41C4aZWCLzSl7zb1nMeb43UG/NCicrmZBnM2toQMF+m7vKrJuH/kv
1H99Qdk3EHUXKVKkyvxS5MYAmNE+45fFZemx5BZzDTKwReaUvebes5jzfG6g35oe3PuOXxWXptUK
hcNMrBF5pS95t6zmPN8bqA+aFJrnEtJa2ppEOzEp/wB5DRDZMRrEa00uc5sdZh932xqRG24uNTbK
qo10jTEbNaNZLXECV8YiKqeDMBG0NTRDarRBc0V7Lf8AuMNpQpZfFZem1xqFw0ysEXmlL3m3rOY8
3xuoN+aFK1VEprZjyWt7IVtI+wYA8/19j4RQAmH7bLk7+w/fWbOanl8Vl6bXGoXDTKwReaUvebes
5jzfG6g35qI1KxWKxWKxWKxWKxWKxWKxWIk6IEFa1rWta1rWta1rWta1rWta1ALL4rL02qFQuGmV
gi80pe829ZzHm+N1AfP+By+Ky9N5hcNMrBF5pS95t6zmPN8bqDfnosVisVisVisVisVisVisVnuW
XxWXptcahcNMrBF5pS95t6zmPN8bqDfmh7c+45fFZem8ws5hplYIvNKXvNvWcx5vjdQah7c+45fF
Zem1QqFw0ysEXmlL3m3rOY83xuoN+aFLZltLjyARQmOlHZ+FZCgRA8hUJMskctgTpbxBzTAj4iia
QlyxtPcYALgH6LgH6LgH6LgH6IuMkwFZUKOXxWXpsOQW8w0ysEXmlL3m3rOY83xuoN+aFGVLfW1z
gCPgSpmUybGyWSzDaA+41cqys1k1224P2jG2vWpk3MZaXMmyQHB0IRrhWpLC4MlbQ+xggFP3zfRN
KRvKe1s1waHGABXHf0rjv6Vx39Ke2bMc9v43GBMU/eN9HL4rL02uNQuGmVgi80pe829ZzHm+N1Bv
zQoyN9t6zG8LgspzPvWZ3BeFI3gp++b6JpSN5T980JmG5P3jfRy+Ky9N5hZzDTKwReaUvebes5jz
fG6g35oUZG+29ZgSZZd9wr1WBSGM2Xvkh22xpBIiYrNMe0tdsioj4hS8w5mxKYQS51Qh81Oc0xaX
mBHPRNKRvKfvmhMw3J+8b6OXxWXptUKhcNMrBF5pS95t6zmPN8bqDfmhRZNbWWEOA5kQHCU02hlX
1W2x7g7lBRa9rJjoVOcK1/bMJH+kVCkaUqc+trDEwT5z3Tdp5iYBdqd0LtTuhdqd0J02QZpeWloD
hVWnO5ST00cvisvTYcgt5hplYIvNKXvNvWcx5vjdQHzQ9ufccvisvTa41C4aZWCLzSl7zb1nMeb4
3UG/NClMLXAGW3ahy/BMYyD3PYHkWQiYQW0WQ1W12wQE1sNqsUjQdNe0OcHhoi4tEIE6kxxf2oEy
xHsl2zatoPgC6ECLBFOmCaNkdmII1Rr5EZr2Ga7a2NkEiHxqUQ7YJJLWOEYAcpTh+XaqcAQCPuaQ
P+qIfOAa1sSYVxiGwh80T+RsYkD4wTYPMRt7boH/ABIFQ+abF+090ftA1DTl8Vl6bqqFw0ysEXml
L3m3rOY83xuoN+aFIulwibYrbg0mEKx8dpNIYA//ACcRbXFN2wBsRhD40jQLWPLQbQFHbMef5oAz
HQFYrR/sd91taOw4tjbBFu26BMSI61U8i3Xy2r7nk6kRtmBtrQd+R0RUDHlUXEnn05fFZem1QqFw
0ysEXmlL3m3rOY83xuoNQJVqtVqtVqtVqtVqtVqtVqtRI0QgrCrCrCrCrCrCrCrCrCrCrCrCrCoQ
WXxWXptcahcNMrBF5pS95t6zmPN8bqAKsVisVisVisVisVisVisVisVisVisVisVisVisVisVisV
isViy4h/7WXptcahcNMrBF5pS95t6zmPN8bqA/gcvisvTaoVC4aZWCLzSl7zb1nMeb43UBpE1jWl
jhEHaFicwwi0wMKxUp2YnVy5DQS0f5EmACmzJbPxTJMHECsFpMFIkyHiMyUHuJ6Yp8z8rRJYQ38h
jAuOoBPYSA2W0PL7QWmwhbIeHiAIc2yv3fL4rL03mFw0ysEXmlL3m3rOY83xuoDS7KsmESn2jq0T
ZM0EyZzdl0LRCsEKZKkEvfOgHPIhBo1BSZtcWSfxObDWAQn5WeS0bYmMeBGuwghbbJj5IYwMluFd
Q5Qmulixoa50NnaI1w93y+Ky9NrjULhplYIvNKXvNvWcx5vjdQH8Dl8Vl6bHkFnMNMrBF5pS95t6
zmPN8bqAAtWpalqWpalqWpalqWpalqWpalqWpalqWpalqWpalqWpalqWpalqWpall4/7rL02qFQu
GmVgi80pe829ZzHm+N1Bvz/gcvisvTYcgt5hplYIvNKXvNvWcx5vjdQb7E+8ZfFZem1xqFw0ysEX
mlL3m3rOY83xuoN9ifeMvisvTbLBZzDTKwReaUvebes5jzfG6g32J94y+Ky9NqhULhplYIvNKXvN
vWcx5vjdQb7E02OmsLRMEW8ysPQmicwt2hFvxB9vl8Vl6bDkFvMNMrBF5pS95t6zmPN8bqDfYmky
aWh4YY7JsKlmXJbN2h9wd/ieRfqMUuTJaCai53J8B7fL4rL02uNQuGmVgi80pe829ZzHm+N1BvsT
S2iCAdcKqEfbZfFZem1QqFw0ysEXmlL3m3rOY83xuoN+fsTREbFEz2iS7ZAlcnLEakCwMdVAjVGP
w+CLz+Nga542axEQ+xNLtgHZ7IhCNVpTWyIbLXOqjGAMNftsvisvTaoVC4aZWCLzSl7zb1nMeb43
UG/P2J94y+Ky9NrjULhplYIvNKXvNvWcx5vjdQHMfYn3jL4rL02uNQuGmVgi80pe829ZzHm+N1Ac
x9ifb/L2OXxWXptUKhcNMrBF5pS95t6zmPN8bqAPwK1rWta1rWta1rWrCrCrCrCrCrCrCrCrCrCr
CrCrCrCrCqgVrWta1rWta1rWtZcf/Vl6bzC4aZWCLzSl7zb1nMeb43UBzH+By+Ky9NrjULhplYIv
NKXvNvWcx5vjdQHMdIIkvINYMCi1wgRUQVPmTS4CS3agyETXDWpU2SXETYgMePuiOZReyAiATyE8
qZJZ/Y57A6r42psrYO2/sAa+ZB0xkATAG2vkX5HsIbVH4RsivyOZBsATywPw91y+Ky9NjyCzmGmV
gi80pe829ZzHm+N2mxAmoQNqtHSu0OlHLBwJhBjya2hFznAk1kkrNM/MyVMmMAllxhXFZUTprZ0y
VM2nOaYwbyLNn87Jv/IP2BpiaztRPInQnNAn5cSmvj2XCHa5FlJTpzXGUXOe8GLRHVFQe8OeJ4fs
xrI1lZmYyZKhPEGhpi4xr+4aoJ5zE2W54lgS3MMHkiwOCtHSrR0q0dKtHSrR0q0dKtHSrR0q0dKt
HSrR0q0dKtHSrR0q0dKtHSrR0q0dKtHSrR0rLkuAAmNiY/FNjPlCof5t5B8V+xK77etfsSu+3rUp
0p7ZjfwgRaQ4WnkpS95t6zmPN8bv4+XvNvWcx5vjd/Hy95t6zm1NmA/nmxAltIjtn/6hcWb5TfVX
Fm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZv
lN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5Tf
VXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31Vx
ZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5TfVXFm+U31VxZvlN9VcWb5
TfVXFm+U31VxZvlN9VcWb5TfVUuE2b2m/wDqby4q/9k=" transform="matrix(0.7499 0 0 0.7499 767.5723 64.3472)">
</image>
<g>
<path d="M585.126,594.751H12.266c-4.494,0-8.145-3.662-8.145-8.226V12.172c0-4.507,3.651-8.168,8.145-8.168
 h572.86c4.495,0,8.147,3.661,8.147,8.168v574.354C593.273,591.089,589.621,594.751,585.126,594.751z M20.414,578.356h556.568
 V20.342H20.414V578.356z"/>
<path d="M136.773,205.745c-30.059,0-54.555-24.563-54.555-54.703c0-30.14,24.496-54.646,54.555-54.646
 c30.06,0,54.5,24.507,54.5,54.646C191.272,181.183,166.833,205.745,136.773,205.745z M136.773,112.733
 c-21.07,0-38.262,17.182-38.262,38.309s17.192,38.31,38.262,38.31s38.205-17.183,38.205-38.31S157.843,112.733,136.773,112.733z"/>
<path d="M12.266,423.036c-2.472,0-4.944-1.127-6.516-3.324c-2.697-3.604-1.968-8.731,1.686-11.437
 l209.906-156.502c2.865-2.197,6.91-2.141,9.777,0.056L368.085,359.32l202.828-213.912c3.089-3.268,8.259-3.379,11.518-0.281
 c3.258,3.099,3.427,8.281,0.28,11.549L374.828,375.882c-2.867,3.042-7.53,3.381-10.789,0.902L222.174,268.562L17.155,421.403
 C15.695,422.53,13.954,423.036,12.266,423.036z"/>
</g>
</svg>
`;
