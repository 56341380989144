export default {
  styleManager: {
    options: {
      'font-weight': {
        100: 'Tenue',
        200: 'Extra Fino',
        300: 'Ligero',
        400: 'Normal',
        500: 'Medio',
        600: 'Semi Fuerte',
        700: 'Fuerte',
        800: 'Extra Fuerte',
        900: 'Acentuado',
      },
      'border-style-sub': {
        none: 'sin estilo',
        solid: 'Solido',
        dotted: 'Punteado',
        dashed: 'Punteado largo',
        double: 'Doble borde',
        groove: 'Ranura',
        ridge: 'Arco',
        inset: 'Recuadro',
        outset: 'Bisel',
      },
      'box-shadow-type': {
        '': 'Exterior',
        inset: 'Interior',
      },
      'background-repeat': {
        repeat: 'repetir',
        'repeat-x': 'Repetir x',
        'repeat-y': 'Repetir y',
        'no-repeat': 'Sin repetir',
        space: 'Espacio',
        round: 'Alrededor',
      },
      'background-position': {
        'left top': 'Izquierda superior',
        'left center': 'Izquierda central',
        'left bottom': 'Izquierda inferior',
        'right top': 'Derecha superior',
        'right center': 'Derecha Central',
        'right bottom': 'Derecha Inferior',
        'center top': 'Centro Superior',
        'center center': 'Centro',
        'center bottom': 'Centro Inferior',
      },
      'background-attachment': {
        scroll: 'Desplazamiento',
        fixed: 'Fijo',
      },
      'background-size': {
        cover: 'Cubrir',
        contain: 'Contener',
      },
      'background-image-gradient-dir': {
        right: 'Derecha',
        bottom: 'Inferior',
        left: 'Izquierda',
        top: 'Superior',
      },
      'background-image-gradient-type': {
        linear: 'Lineal',
        radial: 'Radial',
        'repeating-linear': 'Repetir Lineal',
        'repeating-radial': 'Repetir Radial',
      },
      'transition-property-sub': {
        all: 'Todo',
        width: 'Ancho',
        height: 'Altura',
        'background-color': 'Color de fondo',
        transform: 'Transformar',
        'box-shadow': 'Sombra de caja',
        opacity: 'Opacidad',
      },
      'transition-timing-function-sub': {
        linear: 'Lineal',
        ease: 'Suavizado',
        'ease-in': 'Suavizado interior',
        'ease-out': 'Suavizado exterior',
        'ease-in-out': 'Suavizado de interno a externo',
      },
    },
  },
};
