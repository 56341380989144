export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .standard_button {
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    background-image: radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%);
    border: 1px solid #0077CC;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 5px;
    margin: 4px 2px;
    cursor: pointer;
    color: #fff;
  }
  .standard_button:disabled {
    background-color: #E2E5DE;
    color: gray;
  }
  .standard_button:hover {
    background-image: linear-gradient(#51A9EE, #147BCD);
    border-color: #1482D0;
    color: #fff;
  }
  .standard_button:active {
    background-image: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    border-color: #006DBC;
    color: #fff;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <svg version="1.1" class="icon-lofty-component-block" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
      <path d="M53.689,377.033c-4.745,0-8.598,3.854-8.598,8.6c0,4.801,3.854,8.596,8.598,8.596h513.213
        c4.744,0,8.598-3.795,8.598-8.596V230.808c0-4.803-3.854-8.598-8.598-8.598H21.609c-4.744,0-8.599,3.795-8.599,8.598v79.101
        c0,4.746,3.855,8.6,8.599,8.6c4.743,0,8.598-3.854,8.598-8.6v-70.502h528.096v137.627H53.689z"/>
      </g>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelStandardButton}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Realiza funciones específicas dentro de tu página, como enviar formularios o activar funciones.', id: 'component-standardbutton-lofty-editor'},
    content: `
      <button
        data-gjs-slugLofty="standardButton"
        data-gjs-name="Boton Estandar"
        class="standard_button"
        data-gjs-resizable="{bc: 1}"
      >
        <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto de Boton Estandar">${opts.defText}</div>
      </button>
    ${style}`,
  });
};
