import axios from 'axios';

export const url = process.env.REACT_APP_PAGE_URL || 'https://pages.dev.loftyapps.online';

// export const url = 'http://localhost:8082';

// export const url = 'https://pages.loftyapps.online';

const instancePro = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instancePro;
