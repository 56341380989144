/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-alert */
import React, {useEffect, useState} from 'react';
import {
  validTokenEditor, listAssetsProject, listPluginsProject,
  addStatePage, addGlobalVariable, listStatePage, getGlobalVariables,
  deleteStatePage, deleteGlobalVariables,
} from 'api-lofty';
import toastr from 'toastr';
import {connect} from 'react-redux';
import Editor from '../components/EditorNormal';
import VideoUploadForm from '../components/formUploadFile';
import LoadingPage from './LoadingPage';
// import {validTokenEditor} from '../api/pages.api';
import {addToken} from '../actions/auth.actions';
import {url} from '../config/pages.axios';
import DrawerVariable from '../components/DrawerVariables';
import 'toastr/build/toastr.min.css';
import ModalEditInputRelation from '../components/Modal/ModalEditInputRelation';
import {eliminateInnerComponent, restoreInnerComponent} from '../util/grapeJsCanvasFunctions';
import DrawerLogic from '../components/DrawerLogic';
import ModalLinkParams from '../components/Modal/ModalLinkParams';
import ModalVariableParameter from '../components/Modal/ModalVariableParameter';
import ModalCustomEndpointManager from '../components/Modal/ModalCustomEndpointManager';
import ModalCarouselCustomEndpointManager from '../components/Modal/ModalCarouselEndpointManager';
import ModalGoogleIcons from '../components/Modal/ModalGoogleIcons';

function EditorPage({
  token, addTokenApp, history, match,
}) {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [openInputManager, setOpenInputManager] = useState(false);
  const [paramData, setParamData] = useState([]);
  const [dataInputs, setDataInputs] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [vidRes, setVideoRes] = useState(null);
  const [assetData, setAssetData] = useState([]);
  const [paypalPluginInfo, setPaypalPluginInfo] = useState(null);
  const [plugins, setPlugins] = useState([]);
  // parametros de la página
  const [openVariablesDrawer, setOpenVariablesDrawer] = useState(false);
  const [openLogicDrawer, setOpenLogicDrawer] = useState(false);
  const [localVariableData, setLocalVariableData] = useState([]);
  const [globalVariableData, setGlobalVariableData] = useState([]);
  const [calendarPluginInfo, setCalendarPluginInfo] = useState(null);
  const [hrefLinkParams, setHrefLinkParams] = useState([]);
  const [linkComponent, setLinkComponent] = useState([]);
  const [componentInfoHref, setComponentInfoHref] = useState([]);

  // Payment Method
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [paymentmethodComponent, setPaymentMethodComponent] = useState([]);
  const [variableInfoData, setVariableInfoData] = useState([]);
  const [fieldDataInfo, setFieldDataInfo] = useState([]);

  // Database Custom Endpoint
  const [openDatabaseCustomModal, setOpenDatabaseCustomModal] = useState(false);
  const [databaseCustomEndpointComponent, setDatabaseCustomEndpointComponent] = useState([]);
  const [variableEndpointData, setVariableEndpointData] = useState([]);
  const [databaseFieldData, setDatabaseFieldData] = useState([]);
  const [paramPageData, setParamPageData] = useState([]);
  const [paramsFromEndpoint, setParamsFromEndpoint] = useState([]);

  // Carousel Items
  const [numberOfItems, setNumberOfItems] = useState([]);
  const [openCarouselManager, setOpenCarouselManager] = useState(false);
  const [carouselParamData, setCarouselParamData] = useState([]);
  const [carouselVariableData, setCarouselVariableData] = useState([]);
  const [carouselCustomEndpointComponent, setCarouselCustomEndpointComponent] = useState([]);
  const [carouselSavedData, setCarouselSavedData] = useState([]);

  // Google Icons
  const [componentIconOrigin, setComponentIconOrigin] = useState();
  const [modalIconsGoogle, setModalIconsGoogle] = useState(false);

  const {params} = match;
  const {idProject, idPage} = params;
  useEffect(async () => {
    try {
      if (!token) {
        const params = new URLSearchParams(window.location.search);
        const tokenEditor = params.get('accesstoken');
        const resEditor = await validTokenEditor({tokenEditor});
        addTokenApp(resEditor);
        token = resEditor.token;
      }

      const res = await listAssetsProject({token, idProject});
      const resList = await listPluginsProject({token, idProject});
      const hasPaypal = resList.data.filter((index) => (index.type === 'paypal'));
      setPlugins(resList.data);
      const hasCalendar = resList.data.filter((index) => (index.type === 'calendar'));
      if (hasPaypal.length > 0) {
        setPaypalPluginInfo(hasPaypal);
      }
      if (hasCalendar.length > 0) {
        setCalendarPluginInfo(hasCalendar);
      }
      const filterData = res.data.filter((index) => (index.type === 'video'));
      setAssetData(filterData);
      const resLocalState = await listStatePage({idPage, idProject, token});
      setLocalVariableData(resLocalState.data);
      const resGlobalVar = await getGlobalVariables({idProject, token});
      setGlobalVariableData(resGlobalVar.data);
    } catch (error) {
      history.push('/error404');
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  function handleGoogleModalTrigger(model) {
    setComponentIconOrigin(model);
    setModalIconsGoogle(true);
  }

  function openFormDialog(variable) {
    setVideoRes(variable);
    setOpenDialog(true);
  }

  function handleOpenManagerInputRelations(inputData, componentData) {
    setDataInputs(componentData.attributes.attributes.relationInputs);
    setParamData(inputData);
    setComponentInfo(componentData);
    setOpenInputManager(true);
  }

  function handleOpenDynamicLinkManager(inputData, componentData) {
    setLinkComponent(componentData.attributes.attributes.hrefParams);
    setComponentInfoHref(componentData);
    // setDataInputs(componentData.attributes.attributes.hrefParams);
    setHrefLinkParams(inputData);
    // setComponentInfo(componentData);
    setOpenLinkDialog(true);
  }

  function handleOpenPaymentMethodManager(variables, fields, componentData) {
    // console.log(componentData);
    setPaymentMethodComponent(componentData);
    setVariableInfoData(variables);
    setFieldDataInfo(fields);
    setOpenPaymentMethodModal(true);
  }

  function handleOpenCustomEndpointManager(
    variables,
    fields,
    pageparams,
    componentData,
  ) {
    setVariableEndpointData(variables);
    setDatabaseFieldData(fields);
    setParamPageData(pageparams);
    setParamsFromEndpoint(componentData.attributes.attributes.customParams);
    setDatabaseCustomEndpointComponent(componentData);
    setOpenDatabaseCustomModal(true);
  }

  function handleOpenCarouselCustomEndpointManager(
    variables,
    fields,
    pageparams,
    componentData,
  ) {
    const getModelData = componentData.get('components');
    const seekerItem = [];
    getModelData?.models?.forEach((index, key) => {
      if (index.attributes?.sluglofty === 'booleanDynamicComponent') {
        const tempSeeker = index.get('components');
        const findTrueDiv = tempSeeker?.models.find((modelInfo) => modelInfo?.attributes?.sluglofty === 'booleanTrueDivDynamic');
        // console.log(findTrueDiv);
        const seekerReact = findTrueDiv.get('components');
        const findReactComp = seekerReact?.models.find((modelInfo) => modelInfo?.attributes?.sluglofty === 'reactCarouselComponent');
        if (findReactComp) {
          seekerItem.push({_id: key});
        }
      } else if (index.attributes?.sluglofty === 'reactCarouselComponent') {
        seekerItem.push({_id: key});
      }
    });
    setCarouselVariableData(variables);
    setCarouselParamData(pageparams);
    setParamsFromEndpoint(componentData.attributes?.attributes?.carouselParams);
    setCarouselCustomEndpointComponent(componentData);
    setNumberOfItems(seekerItem);
    if (componentData.attributes?.attributes?.paramCarouselData) {
      setCarouselSavedData(componentData.attributes?.attributes?.paramCarouselData);
    } else {
      setCarouselSavedData([]);
    }
    setOpenCarouselManager(true);
  }

  function functionOpenDrawerVariables(variable) {
    setOpenVariablesDrawer(variable);
  }

  function functionOpenLogic(variable) {
    setOpenLogicDrawer(variable);
  }

  const updateInfo = async (type) => {
    try {
      if (type === 'local') {
        const resLocalState = await listStatePage({idPage, idProject, token});
        setLocalVariableData([...resLocalState.data]);
      } else {
        const resGlobalVar = await getGlobalVariables({idProject, token});
        setGlobalVariableData([...resGlobalVar.data]);
      }
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error al recuperar data actual'), 300);
      }
    }
  };

  async function handleDeleteVariable(variableData) {
    const idVariable = variableData.idVariable;
    const type = variableData.type;
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Eliminar Variable?')) {
      return;
    }
    try {
      if (type === 'local') {
        await deleteStatePage({
          _id: idVariable,
          idPage,
          idProject,
          token,
        });
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        updateInfo('local');
        setTimeout(() => toastr.success('Se elimino la variable'), 300);
      } else {
        await deleteGlobalVariables({_id: idVariable, idProject, token});
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        updateInfo('global');
        setTimeout(() => toastr.success('Se elimino la variable'), 300);
      }
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('No se logro eliminar la data'), 300);
      }
    }
  }

  async function handleAddNewVariable(variableData) {
    try {
      if (variableData.type === 'local') {
        await addStatePage({
          idPage,
          idProject,
          token,
          label: variableData.labelVariable,
          slug: variableData.nameVariable,
          type: variableData.typeField,
          idDatabase: variableData.idDatabase,
        });
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        updateInfo('local');
        setTimeout(() => toastr.success('Se agrego la variable local'), 300);
      } else {
        await addGlobalVariable({
          idPage,
          idProject,
          token,
          name: variableData.nameVariable,
          label: variableData.labelVariable,
          typeValue: variableData.typeField,
          safeLocal: variableData.safeLocal,
          idDatabase: variableData.idDatabase,
        });
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        updateInfo('global');
        setTimeout(() => toastr.success('Se agrego la variable global'), 300);
      }
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se logro agregar los datos'), 300);
      }
    }
  }

  function selectedUrl(urlValue) {
    vidRes.set({src: urlValue.src});
  }

  async function updateData() {
    const res = await listAssetsProject({token, idProject});
    const filterData = res.data.filter((index) => (index.type === 'video'));
    setAssetData(filterData);
  }

  function updateComponentInputType(inputValue, typeState) {
    const temp = dataInputs;
    // const tempComponent = componentInfo;
    const nameValue = inputValue.relationInput.name;
    const indexOfObjectToUpdate = temp.findIndex((obj) => (obj.relationInput.name === nameValue));
    if (indexOfObjectToUpdate !== -1) {
      // Update the name property of the object
      temp[indexOfObjectToUpdate].state = typeState;
    } else {
      // console.log('No data');
    }
    // console.log(temp);
    if (typeState === 'useparams') {
      eliminateInnerComponent(componentInfo, nameValue);
    } else if (typeState === 'input') {
      let comparerValue = '';
      if (componentInfo.attributes.sluglofty === 'AddDBContainer') {
        comparerValue = 'formAddDatabase';
      } else {
        comparerValue = 'formUpdateDatabase';
      }
      restoreInnerComponent(componentInfo, inputValue.relationInput, comparerValue, 'relation', token, idProject);
    }
    componentInfo.addAttributes({relationInputs: temp});
    // console.log(componentInfo);
    setDataInputs(temp.map((item) => item));
  }

  function updateComponentParam(inputValue, paramSelect) {
    const temp = dataInputs;
    const nameValue = inputValue.relationInput.name;
    const indexOfObjectToUpdate = temp.findIndex((obj) => (obj.relationInput.name === nameValue));
    if (indexOfObjectToUpdate !== -1) {
      // Update the name property of the object
      temp[indexOfObjectToUpdate].param = paramSelect;
    } else {
      // console.log('No data');
    }
    // console.log(temp);
    // console.log(componentInfo);
    componentInfo.addAttributes({relationInputs: temp});
    setDataInputs(temp.map((item) => item));
  }

  function updateParamLink(inputValue, paramSelect) {
    const temp = linkComponent;
    const nameValue = inputValue.url;
    const indexOfHrefParams = temp.findIndex((obj) => (obj.url === nameValue));
    if (indexOfHrefParams !== -1) {
      temp[indexOfHrefParams].param = paramSelect;
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      updateInfo('global');
      setTimeout(() => toastr.error('No se actualizo la informacion'), 300);
    }
    componentInfoHref.addAttributes({hrefParams: temp});
    setLinkComponent(temp.map((item) => item));
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se actualizo el valor del parametro'), 300);
  }

  function updateComponentPaymentMethod(selectedParam, typeofParam) {
    try {
      const searchForObject = paymentmethodComponent.getTrait('methodPayFunction').attributes.options;
      const valueOfAttribute = paymentmethodComponent.attributes.methodPayFunction;
      const findData = searchForObject.find((index) => (index.value === valueOfAttribute));
      paymentmethodComponent.addAttributes({
        paramPayment: selectedParam,
        selectedType: typeofParam,
        objectData: findData,
      });
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se actualizo el valor del metodo de pago'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se actualizo la informacion'), 300);
    }
  }

  function updateCustomEndpointComponentInputType(inputValue, typeState) {
    const temp = paramsFromEndpoint;
    const nameValue = inputValue.paramendpoint.value;
    const indexOfObjectToUpdate = temp.findIndex((obj) => (obj.paramendpoint.value === nameValue));
    if (indexOfObjectToUpdate !== -1) {
      temp[indexOfObjectToUpdate].state = typeState;
    }
    databaseCustomEndpointComponent.addAttributes({customParams: temp});
    setParamsFromEndpoint(temp.map((item) => item));
  }

  function updateCustomEndpointComponentParam(inputValue, paramSelect) {
    try {
      const temp = paramsFromEndpoint;
      const nameValue = inputValue.paramendpoint.value;
      const indexOfObjectToUpdate = temp.findIndex(
        (obj) => (obj.paramendpoint.value === nameValue),
      );
      if (indexOfObjectToUpdate !== -1) {
        temp[indexOfObjectToUpdate].param = paramSelect;
      }
      databaseCustomEndpointComponent.addAttributes({customParams: temp});
      setParamsFromEndpoint(temp.map((item) => item));
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se actualizo el valor del parametro personalizado'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se actualizo la informacion'), 300);
    }
  }

  /**
   * ----------------------------------------------------------------------------------------------
   * ---------------------------- FUNCTIONS CAROUSEL CUSTOM ENDPOINT ------------------------------
   * ----------------------------------------------------------------------------------------------
   */

  /**
   * -------------------------------------------
   * -------- Update Input Type Carousel -------
   * -------------------------------------------
   *
   * @param {*Type} typeState
   * @param {*IndexKey} location
   * @param {*param object } paramEndpoint
   *
   */
  function updateCarouselCustomEndpointTypeInput(typeState, location, paramEndpoint) {
    try {
      const temp = carouselSavedData;
      const indexOfObjectToUpdate = temp?.findIndex((obj) => (
        obj.keyIndex === location && obj.keyParam === paramEndpoint.paramendpoint.value));
      if (indexOfObjectToUpdate && indexOfObjectToUpdate !== -1) {
        temp[indexOfObjectToUpdate].state = typeState;
        temp[indexOfObjectToUpdate].value = '';
      } else {
        temp.push({
          keyIndex: location,
          keyParam: paramEndpoint.paramendpoint.value,
          state: typeState,
          paramInfo: paramEndpoint,
          value: '',
        });
      }
      carouselCustomEndpointComponent.addAttributes({paramCarouselData: temp});
      setCarouselSavedData(temp.map((item) => item));
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se actualizo el estado del parametro personalizado'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se actualizo la informacion'), 300);
    }
  }

  /**
   * ------------------------------------
   * ---- Update Param Value Carousel ---
   * ------------------------------------
   *
   * @param {*Index Value*} location
   * @param {*Value for Param*} inputValue
   *
   */
  function updateCarouselCustomEndpointValue(location, inputValue, paramEndpoint) {
    try {
      const temp = carouselSavedData;
      const indexOfObjectToUpdate = temp.findIndex((obj) => (
        obj.keyIndex === location && obj.keyParam === paramEndpoint.paramendpoint.value));
      if (indexOfObjectToUpdate !== -1) {
        temp[indexOfObjectToUpdate].value = inputValue;
      }
      carouselCustomEndpointComponent.addAttributes({paramCarouselData: temp});
      setCarouselSavedData(temp.map((item) => item));
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se actualizo la informacion'), 300);
    }
  }

  function resetCurrentIcon(iconSelect) {
    try {
      componentIconOrigin.empty();
      componentIconOrigin.append(`
        <span style="fontSize: 48px; marginTop: 20px;" data-gjs-sluglofty="googleFontIcon" class="material-icons">${iconSelect}</span>
        <style></style>
      `);
      componentIconOrigin.addAttributes({googleIconSelection: iconSelect});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se actualizo con nuevo icono'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se actualizo el icono'), 300);
    }
  }

  return (
    <div>
      <Editor
        url={url}
        idPage={idPage}
        idProject={idProject}
        token={token}
        openForm={(e) => openFormDialog(e)}
        paypalPlugin={paypalPluginInfo}
        plugins={plugins}
        openVariables={(e) => functionOpenDrawerVariables(e)}
        openLogic={(e) => functionOpenLogic(e)}
        localVars={localVariableData}
        globalVars={globalVariableData}
        calendarPlugin={calendarPluginInfo}
        handleInputManager={(data, model) => handleOpenManagerInputRelations(data, model)}
        handleDynamicLinkManager={(data, model) => handleOpenDynamicLinkManager(data, model)}
        handlePaymentMethodDynamic={
          (vars, fields, model) => handleOpenPaymentMethodManager(vars, fields, model)
        }
        handleCustomEndpointManager={
          (
            vars,
            fields,
            pageparams,
            model,
          ) => handleOpenCustomEndpointManager(vars, fields, pageparams, model)
        }
        handleOpenCarouselCustomEndpointManager={
          (
            vars,
            fields,
            pageparams,
            model,
          ) => handleOpenCarouselCustomEndpointManager(vars, fields, pageparams, model)
        }
        handleCustomGoogleIcons={(model) => handleGoogleModalTrigger(model)}
      />
      <VideoUploadForm
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        assetsInfo={assetData}
        selectUrl={(e) => selectedUrl(e)}
        updateInfo={() => updateData()}
        token={token}
        idProject={idProject}
      />
      <DrawerVariable
        localVariables={localVariableData}
        globalVariables={globalVariableData}
        token={token}
        open={openVariablesDrawer}
        handleClose={() => setOpenVariablesDrawer(false)}
        addNewVariableHandler={(e) => handleAddNewVariable(e)}
        deleteVariable={(e) => handleDeleteVariable(e)}
        idProject={idProject}
      />
      <DrawerLogic
        localVariables={localVariableData}
        globalVariables={globalVariableData}
        token={token}
        open={openLogicDrawer}
        handleClose={() => setOpenLogicDrawer(false)}
        addNewVariableHandler={(e) => handleAddNewVariable(e)}
        deleteVariable={(e) => handleDeleteVariable(e)}
        idPage={idPage}
        idProject={idProject}
      />
      <ModalEditInputRelation
        data={dataInputs}
        editTypeInput={(inputValue, typeState) => updateComponentInputType(inputValue, typeState)}
        editParamInput={(inputValue, paramSelect) => updateComponentParam(inputValue, paramSelect)}
        open={openInputManager}
        onClose={() => setOpenInputManager(false)}
        params={paramData}
      />
      <ModalLinkParams
        open={openLinkDialog}
        onClose={() => setOpenLinkDialog(false)}
        data={linkComponent}
        params={hrefLinkParams}
        editParamValue={(inputValue, paramSelect) => updateParamLink(inputValue, paramSelect)}
      />
      <ModalVariableParameter
        data={paymentmethodComponent}
        open={openPaymentMethodModal}
        onClose={() => setOpenPaymentMethodModal(false)}
        variables={variableInfoData}
        params={fieldDataInfo}
        selected={paymentmethodComponent?.attributes?.attributes?.selectedType}
        editValueHandler={
          (selectedParam, typeofParam) => updateComponentPaymentMethod(selectedParam, typeofParam)
        }
      />
      <ModalCustomEndpointManager
        data={paramsFromEndpoint}
        open={openDatabaseCustomModal}
        onClose={() => setOpenDatabaseCustomModal(false)}
        editParamInput={
          (inputValue, paramSelect) => updateCustomEndpointComponentParam(inputValue, paramSelect)
        }
        editTypeInput={
          (inputValue, typeState) => updateCustomEndpointComponentInputType(inputValue, typeState)
        }
        fields={databaseFieldData}
        params={paramPageData}
        variables={variableEndpointData}
      />
      <ModalCarouselCustomEndpointManager
        data={paramsFromEndpoint}
        open={openCarouselManager}
        onClose={() => setOpenCarouselManager(false)}
        editParamInput={
          (location, inputValue, paramData) => updateCarouselCustomEndpointValue(location, inputValue, paramData)
        }
        editTypeInput={
          (typeState, location, paramData) => updateCarouselCustomEndpointTypeInput(typeState, location, paramData)
        }
        fields={[]}
        params={carouselParamData}
        variables={carouselVariableData}
        numberOfCarousels={numberOfItems}
        savedInfo={carouselSavedData}
      />
      <ModalGoogleIcons
        open={modalIconsGoogle}
        onClose={() => setModalIconsGoogle(false)}
        handleCloseModal={() => setModalIconsGoogle(false)}
        handleEditIcon={(icon) => resetCurrentIcon(icon)}
      />
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addTokenApp: (doc) => dispatch(addToken(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorPage);
