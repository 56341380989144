import axios from 'axios';

// const url = 'https://admin-backoffice.loftyapps.com';
let value;
if (process.env.NODE_ENV !== 'production') {
  value = 'https://adminbackend.dev.loftyapps.online';
} else {
  value = 'https://admin-backoffice.loftyapps.com';
}
const url = value;

export const urlBackend = value;

const instanceAxios = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instanceAxios;
