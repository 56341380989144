export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .href_button {
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
  }
  .href_button:disabled {
    background-color: #E2E5DE;
    color: gray;
  }
  .href_button:hover {
    background-image: linear-gradient(#51A9EE, #147BCD);
    border-color: #1482D0;
    color: #fff;
  }
  .href_button:active {
    background-image: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
    border-color: #006DBC;
    color: #fff;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
        <path d="M53.689,377.033c-4.745,0-8.598,3.854-8.598,8.6c0,4.801,3.854,8.596,8.598,8.596h513.213
          c4.744,0,8.598-3.795,8.598-8.596V230.808c0-4.803-3.854-8.598-8.598-8.598H21.609c-4.744,0-8.599,3.795-8.599,8.598v79.101
          c0,4.746,3.855,8.6,8.599,8.6c4.743,0,8.598-3.854,8.598-8.6v-70.502h528.096v137.627H53.689z"/>
      </g>
      <path d="M29.391,376.507c0.754,0.775,1.992,0.788,2.759,0.035l42.457-41.414L87.61,368.64
        c0.283,0.74,0.996,1.225,1.777,1.245c0.78,0.04,1.508-0.438,1.831-1.164l38.808-87.854c0.309-0.733,0.162-1.582-0.391-2.154
        c-0.377-0.383-0.888-0.592-1.4-0.592c-0.256,0-0.498,0.047-0.74,0.148L38.41,314.755c-0.727,0.303-1.211,1.017-1.211,1.804
        c0,0.794,0.471,1.508,1.198,1.811l33.478,13.966l-42.457,41.413C28.65,374.502,28.637,375.733,29.391,376.507z M124.494,283.72
        l-34.945,79.117l-12.371-31.875l0,0c-0.094-0.235-0.229-0.458-0.417-0.646v-0.014l0,0l-0.027-0.013
        c-0.175-0.182-0.39-0.323-0.619-0.425l-0.014-0.006l0,0l-31.835-13.273L124.494,283.72z"/>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelHrefButton}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Navega fuera de tu página o un recurso externo con un solo clic.', id: 'component-linkbutton-lofty-editor'},
    content: `
      <a
        type="button"
        data-gjs-slugLofty="hrefButton"
        data-gjs-name="Componente Boton con hipervínculo"
        rel="noopener noreferrer"
        style="padding: 10px; text-align: center;display: inline-block; min-width: 50px; min-height: 30px; background-image: linear-gradient(to top, #09203f 0%, #537895 100%);cursor: pointer;color: #fff;"
        href="#"
        data-gjs-resizable="{bc: 1}"
      >
        <div data-gjs-slugLofty="linkButtonText" data-gjs-draggable="false" data-gjs-name="Texto del Boton hipervínculo">${opts.defText}</div>
      </a>
    ${style}`,
  });
};
