export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  // TEMP VARIABLE FOR Z INDEX
  const zIndex = 0;
  const style = `<style>
  .contentwrapperanimated {
    width: 50px;
    height: 50px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .container_animated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100vh;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #D6A419;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: ${zIndex + 1};
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    text-align: center;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }
  .menu__btn {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 40px;
    z-index: ${zIndex + 2};
  }
  .span_middle,
  .span_before,
  .span_after {
    display: block;
    position: relative;
    width: 25px;
    height: 2px;
    margin-top: 1px;
    margin-bottom: 1px;
    background-color: #D6A419;
    transition-duration: .25s;
  }
  .span_before {
    content: '';
    top: -8px;
  }
  .span_after {
    content: '';
    top: 8px;
  }
  .menu__close__btn {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    cursor: pointer;
    width: 25px;
    height: 30px;
    left: -100%;
    z-index: ${zIndex + 2};
  }
  .span__close__left {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: black;
    transform: rotate(45deg);
  }
  .span__close__right {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    top: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: black;
    transform: rotate(135deg);
  }
  .linkhrefs {
    position: fixed;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg class="icon-lofty-component-block" width="35px" height="35px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23 6H1c-.552 0-1-.448-1-1s.448-1 1-1h22c.553 0 1 .448 1 1s-.447 1-1 1zM23 13H1c-.552 0-1-.447-1-1s.448-1 1-1h22c.553 0 1 .447 1 1s-.447 1-1 1zM23 20H1c-.552 0-1-.447-1-1s.448-1 1-1h22c.553 0 1 .447 1 1s-.447 1-1 1z"/>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelHamburgerButton}
    </div>
    `,
    category: opts.category,
    attributes: {title: 'Menú desplegado visible más el menú hamburguesa que te muestra opciones de navegación dentro de tu página.', id: 'component-hamburger-lofty-editor'},
    content: `
    <div data-gjs-slugLofty="hamburgerContainerAnimated" data-gjs-name="Componente Hamburguesa" class="contentwrapperanimated" >
      <div div data-gjs-slugLofty="animhamburgerComponent" data-gjs-name="Seccion Animada del Componente Hamburguesa" class="container_animated">
        <div data-gjs-slugLofty="hamburgerAnimMenu" data-gjs-name="Menu Animado Hamburguesa" class="menu__btn">
          <span data-gjs-name="Barra del Menu Hamburguesa" data-gjs-slugLofty="hamburgerSpan" class="span_before"></span>
          <span data-gjs-name="Barra del Menu Hamburguesa" data-gjs-slugLofty="hamburgerSpan" class="span_middle"></span>
          <span data-gjs-name="Barra del Menu Hamburguesa" data-gjs-slugLofty="hamburgerSpan" class="span_after"></span>
        </div>
        <div data-gjs-slugLofty="hamburgerAnimMenuClose" data-gjs-name="Menu Animado Cerrar Hamburguesa" class="menu__close__btn">
          <span data-gjs-name="Barra del Menu Hamburguesa" data-gjs-slugLofty="hamburgerSpanClose" class="span__close__left"></span>
          <span data-gjs-name="Barra del Menu Hamburguesa" data-gjs-slugLofty="hamburgerSpanClose" class="span__close__right"></span>
        </div>
        <ul data-gjs-name="Menu Lateral" data-gjs-slugLofty="hamburgerSideMenu" class="menu__box">
          <li data-gjs-name="Lista de Link" data-gjs-slugLofty="hamburgerLinksList" class="linkshref">
            <a class="menu__item" data-gjs-name="Link de Item del Menu Hamburguesa" data-gjs-slugLofty="hamburgerItemLink" href="#">Home</a>
          </li>
          <li data-gjs-name="Lista de Link" data-gjs-slugLofty="hamburgerLinksList" class="linkshref">
            <a class="menu__item" data-gjs-name="Link de Item del Menu Hamburguesa" data-gjs-slugLofty="hamburgerItemLink" href="#">About</a>
          </li>
          <li data-gjs-name="Lista de Link" data-gjs-slugLofty="hamburgerLinksList" class="linkshref">
            <a class="menu__item" data-gjs-name="Link de Item del Menu Hamburguesa" data-gjs-slugLofty="hamburgerItemLink" href="#">Team</a>
          </li>
          <li data-gjs-name="Lista de Link" data-gjs-slugLofty="hamburgerLinksList" class="linkshref">
            <a class="menu__item" data-gjs-name="Link de Item del Menu Hamburguesa" data-gjs-slugLofty="hamburgerItemLink" href="#">Contact</a>
          </li>
          <li data-gjs-name="Lista de Link" data-gjs-slugLofty="hamburgerLinksList" class="linkshref">
            <a class="menu__item" data-gjs-name="Link de Item del Menu Hamburguesa" data-gjs-slugLofty="hamburgerItemLink" href="#">Twitter</a>
          </li>
        </ul>
      </div>
    </div>
    ${style}`,
  });
};
