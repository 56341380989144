import grapesjs from 'grapesjs';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjs-block-list', (editor, opts = {}) => {
  const config = {
    blocks: [
      'list',
    ],
    flexGrid: 0,
    stylePrefix: 'gjs-',
    addBasicStyle: true,
    name: 'basicList',
    category: 'Básicos',
    labelList: 'Lista',
    ...opts,
  };
  loadBlocks(editor, config);
});
