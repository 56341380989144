/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import Blockly from 'blockly';
import * as Es from 'blockly/msg/es';
import '@blockly/field-dependent-dropdown'; // Import with side effects.
import {
  general_boolean,
  general_number,
  general_string,
  operation,
  main_block_1,
  assign_variable_2,
  navigator_redirect_1,
  assign_variable_1,
  operation_1,
  operation_2,
  custom_variables,
  db_comparison_inputs,
  db_field_inputs,
  if_statement_inputs,
} from './blockValidationChildren';

Blockly.setLocale(Es);

const defineDBBlocks = ({localVariables, globalVariables}) => {
  const basicBlocksDef = [
    // main_block
    {
      type: 'main_block',
      message0: 'Bloque Principal %1',
      args0: [
        {
          type: 'input_statement',
          name: 'main_block',
          check: main_block_1,
        },
      ],
      colour: 195,
      tooltip: 'Este bloque contiene todas las instrucciones que serán tomadas en cuenta por el Endpoint. Solo puede haber uno de estos bloques en el pizarrón a la vez.',
      helpUrl: '',
    },
    // other
    {
      type: 'operation',
      message0: '%1 %2 %3',
      args0: [
        {
          type: 'input_value',
          name: 'left_value',
          check: operation_1,
        },
        {
          type: 'field_dropdown',
          name: 'arithmetic',
          options: [
            [
              '+',
              'sum',
            ],
            [
              '-',
              'difference',
            ],
            [
              '*',
              'product',
            ],
            [
              '/',
              'division',
            ],
            [
              '^',
              'power',
            ],
            [
              '√',
              'square_root',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'arithmeticoptions',
          check: operation_2,
        },
      ],
      inputsInline: true,
      output: operation,
      colour: 230,
      tooltip: 'Bloque para procesos matematicos',
      helpUrl: '',
    },
    {
      type: 'comparison',
      message0: '%1 %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'comparison_type_select',
          options: [
            [
              '=',
              'equals',
            ],
            [
              '>',
              'more than',
            ],
            [
              '<',
              'less than',
            ],
            [
              '≥',
              'more or equal than',
            ],
            [
              '≤',
              'less or equal than',
            ],
            [
              '≠',
              'different',
            ],
            [
              'Expresión Regular',
              'regex',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'ComparisonBlock',
          check: db_comparison_inputs,
        },
      ],
      output: db_field_inputs[0],
      colour: 215,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'if_condition',
      message0: '%1 %2 %3',
      args0: [
        {
          type: 'input_value',
          name: 'comparison_left_condition',
          check: if_statement_inputs,
        },
        {
          type: 'field_dropdown',
          name: 'comparison_type_select',
          options: [
            [
              '=',
              'equals',
            ],
            [
              '>',
              'more than',
            ],
            [
              '<',
              'less than',
            ],
            [
              '≥',
              'more or equal than',
            ],
            [
              '≤',
              'less or equal than',
            ],
            [
              '≠',
              'different',
            ],
            [
              'Expresión Regular',
              'regex',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'comparison_right_condition',
          check: if_statement_inputs,
        },
      ],
      inputsInline: true,
      output: db_field_inputs[0],
      colour: 65,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'string_input_block',
      message0: '" %1 " %2',
      args0: [
        {
          type: 'field_input',
          name: 'valueInput',
          text: '',
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_string,
      colour: 0,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'number_input_block',
      message0: '# %1 %2',
      args0: [
        {
          type: 'field_number',
          name: 'valueInputNumber',
          value: 0,
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_number,
      colour: 0,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'boolean_input_block',
      message0: 'VoF %1 %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'booleanTypeSelect',
          options: [
            [
              'V',
              'true',
            ],
            [
              'F',
              'false',
            ],
          ],
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_boolean,
      colour: 0,
      tooltip: 'Entrada de tipo booleano V para verdadero (true) y F para falso (false).',
      helpUrl: '',
    },
    {
      type: 'conditional_if_block',
      message0: 'Si %1 entonces %2',
      args0: [
        {
          type: 'input_value',
          name: 'condition',
          check: ['comparison'],
        },
        {
          type: 'input_statement',
          name: 'then',
        },
        // {
        //  type: 'input_statement',
        //  name: 'else_do',
        //  check: 'main_block_1',
        // },
      ],
      previousStatement: main_block_1,
      nextStatement: main_block_1,
      colour: 210,
      tooltip: 'Bloque condicional que ejecuta las instrucciones en el bloque "entonces" si la condición es verdadera',
      helpUrl: '',
    },
  ];
  const localVariablesDef = {
    type: 'local_dynamic_variable',
    message0: 'Variable Local %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'variableNameLocal',
        options: localVariables.map((variable) => (
          [
            `${variable.label}--${variable.type}`,
            variable.slug,
          ]
        )),
      },
    ],
    output: custom_variables,
    colour: 0,
    tooltip: '',
    helpUrl: '',
  };
  const globalVariablesDef = {
    type: 'global_dynamic_variable',
    message0: 'Variable Global %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'variableNameGlobal',
        options: globalVariables.map((variable) => (
          [
            `${variable.label}--${variable.typeValue}`,
            variable.name,
          ]
        )),
      },
    ],
    output: custom_variables,
    colour: 0,
    tooltip: '',
    helpUrl: '',
  };
  // const fieldOptionMapping = [];
  // localVariables
  //   .filter((index) => (index.typeValue === 'arrayDatabase'))
  //   .forEach((variable) => (fieldOptionMapping.push([`${variable.label}--${variable.typeValue}`, variable._id])));
  // globalVariables
  //   .filter((index) => (index.typeValue === 'arrayDatabase'))
  //   .forEach((variable) => (fieldOptionMapping.push([`${variable.label}--${variable.typeValue}`, variable._id])));
  const arrayGlobalFunctionAdd = {
    type: 'global_add_data_to_array_variable',
    message0: 'Global: Agregar en %1 dato %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'addToVariable',
        options: globalVariables
          ?.filter((variable) => (variable.typeValue === 'arrayDatabase'))
          ?.map((variable) => [`${variable.label}--${variable.typeValue}`, variable.name]),
      },
      {
        type: 'input_value',
        name: 'databaseVariableAdd',
        check: custom_variables,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    colour: 130,
    tooltip: 'Bloque para guardar en arreglo',
    helpUrl: 'Bloque para guardar en arreglo',
  };
  const arrayLocalFunctionAdd = {
    type: 'local_add_data_to_array_variable',
    message0: 'Local: Agregar en %1 dato %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'addToVariable',
        options: localVariables
          ?.filter((variable) => (variable.typeValue === 'arrayDatabase'))
          ?.map((variable) => [`${variable.label}--${variable.typeValue}`, variable.slug]),
      },
      {
        type: 'input_value',
        name: 'databaseVariableAdd',
        check: custom_variables,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    colour: 140,
    tooltip: 'Bloque para guardar en arreglo',
    helpUrl: 'Bloque para guardar en arreglo',
  };
  const arrayGlobalVariableDelete = {
    type: 'global_delete_from_array',
    message0: 'Global: Eliminar de %1 : %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'eliminateFromVariable',
        options: globalVariables
          ?.filter((variable) => (variable.typeValue === 'arrayDatabase'))
          ?.map((variable) => [`${variable.label}--${variable.typeValue}`, variable.name]),
      },
      {
        type: 'input_value',
        name: 'databaseVariableDelete',
        check: custom_variables,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    colour: 150,
    // output: general_string,
    tooltip: '',
    helpUrl: '',
  };
  const arrayLocalVariableDelete = {
    type: 'local_delete_from_array',
    message0: 'Local: Eliminar de %1 : %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'eliminateFromVariable',
        options: localVariables
          ?.filter((variable) => (variable.typeValue === 'arrayDatabase'))
          ?.map((variable) => [`${variable.label}--${variable.typeValue}`, variable.slug]),
      },
      {
        type: 'input_value',
        name: 'databaseVariableDelete',
        check: custom_variables,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    colour: 160,
    // output: general_string,
    tooltip: '',
    helpUrl: '',
  };
  const navigatorFunctionsDef = [
    {
      type: 'navigator_redirect',
      message0: 'Redirigir a: %1',
      args0: [
        {
          type: 'input_value',
          name: 'urlInstance',
          check: navigator_redirect_1,
        },
      ],
      previousStatement: main_block_1,
      nextStatement: main_block_1,
      colour: 180,
      tooltip: '',
      helpUrl: '',
    },
  ];
  const variableAssignDef = {
    type: 'assign_variable',
    message0: 'Asignar %1 a: %2',
    args0: [
      {
        type: 'input_value',
        name: 'variableInstance',
        check: assign_variable_1,
      },
      {
        type: 'input_value',
        name: 'definedTo',
        check: assign_variable_2,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    inputsInline: true,
    colour: 20,
    tooltip: '',
    helpUrl: '',
  };
  const variableSetValue = {
    type: 'set_global_variable_page',
    message0: 'Establecer %1 a: %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'variableNameGlobal',
        options: globalVariables.map((variable) => (
          [
            `${variable.label}--${variable.typeValue}`,
            variable.name,
          ]
        )),
      },
      {
        type: 'input_value',
        name: 'definedTo',
        check: assign_variable_1,
      },
    ],
    previousStatement: main_block_1,
    nextStatement: main_block_1,
    inputsInline: true,
    colour: 20,
    tooltip: '',
    helpUrl: '',
  };
  // const variableArrayRender = {
  //   type: 'render_variable_array_content',
  //   message0: 'Listar %1',
  //   args0: [
  //     {
  //       type: 'field_dropdown',
  //       name: 'listArrayVariables',
  //       options: fieldOptionMapping,
  //     },
  //   ],
  //   previousStatement: main_block_1,
  //   nextStatement: main_block_1,
  //   inputsInline: true,
  //   colour: 20,
  //   tooltip: '',
  //   helpUrl: '',
  // };
  const variablesDef = [];
  if (localVariables.length > 0) {
    variablesDef.push(localVariablesDef);
  }
  if (globalVariables.length > 0) {
    variablesDef.push(globalVariablesDef);
    variablesDef.push(variableSetValue);
  }
  if (globalVariables.filter((index) => (index.typeValue === 'arrayDatabase')).length > 0) {
    variablesDef.push(arrayGlobalFunctionAdd);
    variablesDef.push(arrayGlobalVariableDelete);
  }
  if (localVariables.filter((index) => (index.typeValue === 'arrayDatabase')) > 0) {
    variablesDef.push(arrayLocalFunctionAdd);
    variablesDef.push(arrayLocalVariableDelete);
  }
  // if (fieldOptionMapping.length > 0) {
  //   variablesDef.push(variableArrayRender);
  // }
  variablesDef.push(variableAssignDef);
  Blockly.defineBlocksWithJsonArray([...basicBlocksDef, ...variablesDef, ...navigatorFunctionsDef]);
  const basicBlocks = basicBlocksDef.map((block) => ({kind: 'block', type: block.type}));
  const variablesBlocks = variablesDef.map((block) => ({kind: 'block', type: block.type}));
  const navigatorFunctionsBlocks = navigatorFunctionsDef.map((block) => ({kind: 'block', type: block.type}));
  const variableBlocks = {
    kind: 'category',
    name: 'Variables',
    custom: 'VARIABLE',
    categorystyle: 'variable_category',
    tooltip: 'Declaración y manejo de variables',
  };
  const toolbox = {
    kind: 'categoryToolbox',
    contents: [
      {
        kind: 'category', name: 'Bloques Básicos', categorystyle: 'math_category', contents: basicBlocks,
      },
      {
        kind: 'category', name: 'Estados', categorystyle: 'math_category', contents: variablesBlocks,
      },
      variableBlocks,
      {
        kind: 'category', name: 'Funciones de Navegador', categorystyle: 'math_category', contents: navigatorFunctionsBlocks,
      },
      // variableBlocks,
      {
        kind: 'category', name: 'Condiciones', categorystyle: 'logic_category', contents: [{kind: 'block', type: 'conditional_if_block'}],
      },
    ],
  };
  return toolbox;
};

export default defineDBBlocks;

export const updateVariablesBlockly = ({
  globalVariables, localVariables, blocklyWorkspace, oldToolbox, setToolbox,
}) => {
  // Blockly
  const localVariablesDef = {
    type: 'local_dynamic_variable',
    message0: 'Variable Local %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'variable_name',
        options: localVariables.map((variable) => (
          [
            `${variable.label}--${variable.type}`,
            variable.slug,
          ]
        )),
      },
    ],
    output: custom_variables,
    colour: 0,
    tooltip: '',
    helpUrl: '',
  };
  const globalVariablesDef = {
    type: 'global_dynamic_variable',
    message0: 'Variable Global %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'variable_name',
        options: globalVariables.map((variable) => (
          [
            `${variable.label}--${variable.typeValue}`,
            variable.name,
          ]
        )),
      },
    ],
    output: custom_variables,
    colour: 0,
    tooltip: '',
    helpUrl: '',
  };
  Blockly.defineBlocksWithJsonArray([localVariablesDef, globalVariablesDef]);
  const paramsArrayNew = [
    {kind: 'block', type: 'local_dynamic_variable'},
    {kind: 'block', type: 'global_dynamic_variable'},
    {kind: 'block', type: 'assign_variable'},
  ];
  if (oldToolbox) {
    const newToolbox = {...oldToolbox};
    newToolbox.contents[1].contents = paramsArrayNew;
    blocklyWorkspace?.updateToolbox?.(newToolbox);
    // react
    setToolbox(newToolbox);
  }
};
