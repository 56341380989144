/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {getOnePageApiPages} from 'api-lofty';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('verifyUserEmail')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.configureComponent());
      },
      /*
              -- Configure Params Variable TRAIT --
        Configures the params trait to verify the user's email
      */
      async configureComponent() {
        try {
          if (this.model.attributes.sluglofty.includes('verifyUserEmail')) {
            const filterData = [];
            const token = opts.token;
            const idPage = opts.idPage;
            const resPage = await getOnePageApiPages({token, _id: idPage});
            const urlParamsRaw = resPage.data.url.split('/');
            let hasParams = false;
            urlParamsRaw.forEach((index) => {
              if (index.includes(':')) {
                hasParams = true;
                const temp = index.split(':');
                const paramData = temp[1];
                filterData.push({value: paramData, name: paramData});
              }
            });
            if (hasParams) {
              const traitRead = {
                type: 'select',
                name: 'verifyParams',
                label: 'Parametros',
                changeProp: 1,
                options: filterData,
                default: '',
              };
              this.model.addTrait(traitRead);
            }
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
