/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, {useEffect, useState} from 'react';
import {
  Button,
  Box,
  Drawer,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
import CloseIcon from '@material-ui/icons/Close';
import {addFunctionsPage, deleteFunctionsPage, listFunctionsPage} from 'api-lofty';
import CustomFrontendBlockly from './CustomFrontendBlockly/CustomFrontendBlockly';
import ListFunctionPage from './CustomFrontendBlockly/ListFunctionPage';
import RenderViewBlockly from './CustomFrontendBlockly/RenderViewBlockly';

const useStyle = makeStyles(() => ({
  mainBoxContainer: {
    width: '100%',
    minHeight: '50vh',
    padding: 0,
  },
  mainTitleStyle: {
    fontSize: '19px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxVariableContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  titleListStyle: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  boxOptionsContainer: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  buttonOptionStyle: {
    marginRight: '5px',
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    border: '2px solid',
    borderColor: 'rgba(32, 21, 73, 1)',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    textTransform: 'none',
  },
  dynamicFrontendClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    height: '4vh',
    position: 'fixed',
    marginTop: '2px',
  },
  dynamicFrontendBlockly: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '100%',
    paddingTop: '1rem',
    overflowY: 'auto',
  },
  dialogStyle: {
    backgroundColor: '#242424',
    color: '#fff',
  },
  titleArea: {
    backgroundColor: '#242424',
    color: '#fff',
    padding: '10px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function DrawerLogic({
  open, handleClose, localVariables, globalVariables, token,
  idProject, idPage,
}) {
  const classes = useStyle();
  const [urlSection, setUrlSection] = useState('list');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [blocklyData, setBlockData] = useState([]);
  const [blockVariables, setBlockVariables] = useState([]);
  const handleData = async () => {
    try {
      const res = await listFunctionsPage({
        token, idPage, idProject,
      });
      setData(res.data);
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.success('Error al procesar la información, si el error es recurrente comuniquese con soporte técnico');
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);
  const handleRenderItem = (item) => {
    setBlockData(item?.arrayLogic[0]);
    setBlockVariables(item?.variables);
    setUrlSection('render');
  };
  const urlObject = {
    list: {
      component: () => (
        <ListFunctionPage
          loading={loading}
          data={data}
          addFunction={() => {
            setUrlSection('add');
          }}
          viewFunction={(item) => handleRenderItem(item)}
          removeItem={async (item) => {
            try {
              await deleteFunctionsPage({
                token, _id: item._id,
              });
              handleData();
              toastr.success('Se elimino el dato de manera exitosa');
            } catch (error) {
              if (error.info) {
                toastr.error(error.info);
              } else {
                toastr.success('Error al procesar la información, si el error es recurrente comuniquese con soporte técnico');
              }
            }
          }}
        />
      ),
    },
    add: {
      component: () => (
        <CustomFrontendBlockly
          localVariables={localVariables}
          globalVariables={globalVariables}
          backFunction={() => {
            handleData();
            setUrlSection('list');
          }}
          addFuntionPageFrontend={async (label, slug, stateBlocks, stateVars) => {
            try {
              await addFunctionsPage({
                arrayLogic: stateBlocks,
                idPage,
                idProject,
                label,
                slug,
                token,
                variables: stateVars || [],
              });
              toastr.success('Se agrego de manera exitosa la función');
              handleData();
              setUrlSection('list');
            } catch (error) {
              if (error.info) {
                toastr.error(error.info);
              } else {
                toastr.error('Error al procesar la petición, si vuelve a ocurrir contactar con soporte');
              }
            }
          }}
          listFunctions={data}
        />
      ),
    },
    render: {
      component: () => (
        <RenderViewBlockly
          localVariables={localVariables}
          globalVariables={globalVariables}
          backFunction={() => {
            setUrlSection('list');
            setBlockData([]);
            setBlockVariables([]);
          }}
          infoEndpointData={blocklyData}
          variableInfoData={blockVariables}
        />
      ),
    },
  };
  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor="bottom"
      variant="persistent"
      maxWidth="lg"
      fullWidth="lg"
    >
      <div className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Funciones
          </Box>
          <Button onClick={handleClose} style={{color: '#fff'}}>
            <CloseIcon />
          </Button>
        </Box>
      </div>
      <div className={classes.dialogStyle}>
        <div component="div" className={classes.mainBoxContainer}>
          <div className={classes.dynamicFrontendBlockly}>
            {
              urlObject[urlSection]?.component ? urlObject[urlSection]?.component() : (
                <div>
                  Error en la sección de lógica
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default DrawerLogic;
