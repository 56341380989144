/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import {
  videoIconSvg,
} from '../basicBlocks/iconsSVG';

export default (editor, opts) => {
  const c = opts;
  // const {blocks} = c;
  const bm = editor.BlockManager;
  bm.add(opts.name, {
    label: `
      <div style="display: flex; justify-content: center">
      ${videoIconSvg}
      </div>
      <div class="gjs-block-label">
        ${c.labelDynVideo}
      </div>`,
    category: c.category,
    attributes: {title: 'Carga videos desde campos de una coleccion de datos. ', id: 'component-dynvideo-lofty-editor'},
    content: `
      <video data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="dynVideo" data-gjs-name="Video Dinamica" style="padding: 5px; width: 100px; height: auto" />
    `,
  });
};
