/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [
          {
            type: 'select',
            label: 'Tipo de animacion',
            name: 'typeAnimation',
            options: [
              {name: 'Desvanecer hacia arriba', value: 'fade-up'},
              {name: 'Desvanecer hacia abajo', value: 'fade-down'},
              {name: 'Desvanecer hacia izquierda', value: 'fade-left'},
              {name: 'Desvanecer hacia derecha', value: 'fade-right'},
              {name: 'Desvanecer hacia arriba derecha', value: 'fade-up-right'},
              {name: 'Desvanecer hacia arriba izquierda', value: 'fade-up-left'},
              {name: 'Desvanecer hacia abajo derecha', value: 'fade-down-right'},
              {name: 'Desvanecer hacia abajo izquierda', value: 'fade-down-left'},
              {name: 'voltear arriba', value: 'flip-up'},
              {name: 'voltear abajo', value: 'flip-down'},
              {name: 'voltear izquierda', value: 'flip-left'},
              {name: 'voltear derecha', value: 'flip-right'},
              {name: 'Acercar hacia arriba', value: 'zoom-in-up'},
              {name: 'Acercar hacia abajo', value: 'zoom-in-down'},
              {name: 'Acercar a la izquierda', value: 'zoom-in-left'},
              {name: 'Acercar a la derecha', value: 'zoom-in-right'},
              {name: 'Alejar hacia arriba', value: 'zoom-out-up'},
              {name: 'Alejar hacia abajo', value: 'zoom-out-down'},
              {name: 'Alejar a la izquierda', value: 'zoom-out-left'},
              {name: 'Alejar a la derecha', value: 'zoom-out-right'},
            ],
            changeProp: 1,
          },
          {
            type: 'number',
            name: 'durationAnimation',
            min: 0,
            label: 'Duración ms',
            changeProp: 1,
          },
        ],
        // script: function nameScript() {
        //   const initLib = function () {
        //     // eslint-disable-next-line new-cap
        //     const aosdata = new AOS.init();
        //     console.log('aosdata :>> ', aosdata);
        //   };

        //   if (typeof Aosdata === 'undefined') {
        //     const script = document.createElement('script');
        //     const link = document.createElement('link');
        //     script.onload = initLib;
        //     script.src = 'https://unpkg.com/aos@2.3.1/dist/aos.js';
        //     link.href = 'https://unpkg.com/aos@2.3.1/dist/aos.css';
        //     link.rel = 'stylesheet';
        //     document.body.appendChild(script);
        //     document.body.appendChild(link);
        //   } else {
        //     initLib();
        //   }
        // },
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('animatedScrollContainer')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:typeAnimation', this.configureAnimation);
        this.listenTo(model, 'change:durationAnimation', this.configureTime);
      },
      /*
              -- Configure animation TRAIT --
        Configures the type of animation for the container
      */
      configureAnimation() {
        if (this.model.attributes.sluglofty.includes('animatedScrollContainer')) {
          const getSelectionAnimation = this.model.getTrait('typeAnimation').attributes.value;
          this.model.addAttributes({'data-aos': getSelectionAnimation});
        }
      },
      /*
              -- Configure Time TRAIT --
        Configures the time of the animation for the container
      */
      configureTime() {
        if (this.model.attributes.sluglofty.includes('animatedScrollContainer')) {
          const getTimeAnimation = this.model.getTrait('durationAnimation').attributes.value;
          this.model.addAttributes({'data-aos-duration': getTimeAnimation});
        }
      },
    }),
  });
};
