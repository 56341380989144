export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .gjs-stripe-init {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
    .gjs-stripe-resume {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 25px;
    }
    
    .gjs-stripe-info {
    }

    .gjs-stripe-title {
      color: #D6A419;
      font-size: 24px;
      font-weight: 700;
    }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <svg version="1.1" class="icon-lofty-component-block" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
      <g>
      <path d="M 16.515625 4 C 10.735625 4 7 7.1135938 7 11.933594 C 7 16.706594 11.288953 18.129313 14.126953 19.070312 C 15.402953 19.493313 16.989 20.018109 17 20.537109 C 16.992 20.938109 16.042562 21 15.476562 21 C 13.590563 21 10.698359 20.220516 8.4433594 19.103516 L 7 18.388672 L 7 26.185547 L 7.6113281 26.441406 C 9.9583281 27.427406 12.707297 27.995 15.154297 28 C 21.319297 28 25 25.158391 25 20.400391 C 25 15.208391 20.591781 13.797281 17.675781 12.863281 C 16.609781 12.521281 15 12.005219 15 11.574219 C 15 11.345219 15.000469 11 16.230469 11 C 18.079469 11 20.652281 11.765375 22.488281 12.859375 L 24 13.761719 L 24 5.546875 L 23.363281 5.2988281 C 21.207281 4.4608281 18.776625 4 16.515625 4 z M 16.515625 6 C 18.314625 6 20.237 6.3276875 22 6.9296875 L 22 10.361328 C 20.114 9.5223281 17.953469 9 16.230469 9 C 13.315469 9 13 10.800219 13 11.574219 C 13 13.465219 14.975406 14.098578 17.066406 14.767578 C 20.541406 15.880578 23 16.934391 23 20.400391 C 23 25.453391 17.51325 26 15.15625 26 C 13.17525 25.996 10.967 25.576797 9 24.841797 L 9 21.537109 C 10.993 22.334109 13.465563 23 15.476562 23 C 18.530563 23 18.997 21.465969 19 20.542969 L 19 20.537109 L 19 20.53125 C 18.992 18.57525 16.934859 17.893875 14.755859 17.171875 C 11.385859 16.053875 9 15.024594 9 11.933594 C 9 8.2725937 11.879625 6 16.515625 6 z"/>
      </g>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelStripeForm}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Formulario de Stripe. Solo vista general.', id: 'component-stripeform-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="stripeFormContainer"
        data-gjs-name="Formulario de Stripe"
        style="width: 400px; padding: 10px; min-height: 400px"
        data-gjs-resizable="{bc: 1}"
      >
        <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            class="gjs-stripe-title"
            style="text-align: center; width: 100%"
          >
            RESUMEN DE PAGO
          </Box>
        <Box
          data-gjs-stylable="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-resizable="false"
          data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
          data-gjs-slugLofty="stripeResumeContainer"
          data-gjs-name="Resumen de Pago"
          class="gjs-stripe-init"
        >
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            data-gjs-slugLofty="standardButtonText"
            data-gjs-name="Texto de Ejemplo"
            class="gjs-stripe-resume"
          >
            <b>SubTotal:</b> $ {data}
          </Box>
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            data-gjs-slugLofty="standardButtonText"
            data-gjs-name="Texto de Ejemplo"
            class="gjs-stripe-resume"
          >
            <b>SubTotal:</b> $ {data}
          </Box>
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            data-gjs-slugLofty="standardButtonText"
            data-gjs-name="Texto de Ejemplo"
            class="gjs-stripe-resume"
          >
            <b>SubTotal:</b> $ {data}
          </Box>
        </Box>
        <Box
          data-gjs-stylable="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-resizable="false"
          data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
          data-gjs-slugLofty="stripeResumeContainer"
          data-gjs-name="Seccion de Boton"
          class="gjs-stripe-resume"
        >
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            class="gjs-stripe-title"
          >
            PAY WITH
          </Box>
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            style="padding: 10px; display: flex; width: 100px; background: #cce5ff; border-radius: 20px; color: #fff; font-weight: 700; font-size: 18px; align-items: center; justify-content: center" 
          >
            stripe
          </Box>
        </Box>
        <div
          data-gjs-stylable="false"
          data-gjs-draggable="false"
          data-gjs-droppable="false"
          data-gjs-resizable="false"
          data-gjs-editable="false"
          data-gjs-highlightable="false"
          data-gjs-selectable="false"
          data-gjs-slugLofty="stripeFormCreditCard"
          data-gjs-name="Seccion de Tarjeta"
        >
          <Box
            data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
            data-gjs-slugLofty="stripeFormCreditCard"
            data-gjs-name="Seccion de Tarjeta"
            class="gjs-stripe-resume"
          >
            <Box
              data-gjs-stylable="false"
              data-gjs-draggable="false"
              data-gjs-droppable="false"
              data-gjs-resizable="false"
              data-gjs-editable="false"
              data-gjs-highlightable="false"
              data-gjs-selectable="false"
            >
              No. Tarjeta
            </Box>
            <Box
              data-gjs-stylable="false"
              data-gjs-draggable="false"
              data-gjs-droppable="false"
              data-gjs-resizable="false"
              data-gjs-editable="false"
              data-gjs-highlightable="false"
              data-gjs-selectable="false"
            >
              MM/YY CVC
            </Box>
          </Box>
          <div
            data-gjs-slugLofty="sectionButtonPayment"
            data-gjs-name="Contenedor de Boton Pago"
            style="width: 100%; padding: 10px"
          >
            <button
              data-gjs-slugLofty="buttonStripePayment"
              data-gjs-name="Boton Pago Stripe"
            >
              <div
                data-gjs-slugLofty="standardButtonText"
                data-gjs-name="Texto de Ejemplo"
              >
                Pagar con Tarjeta
              </div>
            </button>
          </div>
          <Box
            data-gjs-stylable="false"
              data-gjs-draggable="false"
              data-gjs-droppable="false"
              data-gjs-resizable="false"
              data-gjs-editable="false"
              data-gjs-highlightable="false"
              data-gjs-selectable="false"
              style="background-color: #cce5ff; color: #004085; width: 100%; display:block"
          >
            Toda Informacion que se encuentra en este componente
            se genera de forma automatica. Los datos presentes son solo
            para demostracion. Solo el boton "Pagar con Tarjeta" es editable.
            La informacion dentro de este cuadro es solo una descripcion dentro
            del editor; en produccion se muestra la informacion correspondiente
            de stripe.
          </Box>
        </div>
      </div>
    ${style}`,
  });
};
