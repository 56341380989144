import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-animated-scroll-container', (editor, opts) => {
  const config = {
    blocks: [
      'animatedScrollContainer',
    ],
    name: 'animatedScrollContainer',
    stylePrefix: 'gjs-',
    labelScroll: 'Contenedor con animación',
    defText: 'E.g.',
    category: 'Básicos',
    ...opts,
  };
    // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
