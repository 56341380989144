import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-form-forgotpassword', (editor, opts) => {
  const config = {
    blocks: [
      'formForgotPassword',
    ],
    name: 'formForgotPasswordComponent',
    stylePrefix: 'gjs-',
    labelFormForgot: 'Formulario para Recuperar Contraseña',
    defText: 'Email:',
    category: 'Interacción de Usuario',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
