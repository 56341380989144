/* eslint-disable no-else-return */
export function searchForReadDB(component) {
  const parentComponent = component.parent();
  if (parentComponent !== undefined) {
    if (parentComponent.attributes.sluglofty && parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
      return parentComponent;
    } else {
      return searchForReadDB(parentComponent);
    }
  } else if (parentComponent === undefined) {
    return null;
  } else {
    return searchForReadDB(parentComponent);
  }
}

export function exhaustiveSearchReadDB(component, seekerData) {
  const parentComponent = component.parent();
  if (parentComponent !== undefined) {
    if (parentComponent.attributes.sluglofty && parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
      seekerData.push(
        {
          idDatabse: parentComponent.attributes.dbSelect,
          idEndpoint: parentComponent.attributes.endpointSelect,
        },
      );
      return exhaustiveSearchReadDB(parentComponent, seekerData);
    } else {
      return exhaustiveSearchReadDB(parentComponent, seekerData);
    }
  } else if (parentComponent === undefined) {
    return seekerData;
  } else {
    return exhaustiveSearchReadDB(parentComponent, seekerData);
  }
}

export function searchParentComponentForFields(component) {
  let retVal = [];
  const parentComponent = component.parent();
  if (parentComponent.attributes.sluglofty.includes('ReadDBContainer')) {
    const componentData = parentComponent.get('components');
    let searcher = '';
    componentData.every((index) => {
      if (index.attributes.sluglofty === 'itemListEndpointDatabase') {
        searcher = 'list';
        return false;
        // eslint-disable-next-line no-else-return
      } else if (index.attributes.sluglofty === 'fieldDatabaseContainer') {
        searcher = 'fields';
        return false;
      } else {
        return true;
      }
    });
    let fieldContainer = [];
    let selectFields = [];
    switch (searcher) {
      case 'list':
        fieldContainer = componentData.filter((index) => (index.attributes.sluglofty === 'itemListEndpointDatabase'));
        fieldContainer[0].attributes.components.models.forEach(
          (index) => {
            selectFields.push({value: index.attributes.valuelofty, name: index.attributes.name});
          },
        );
        break;
      case 'fields':
        fieldContainer = componentData.filter((index) => (index.attributes.sluglofty === 'fieldDatabaseContainer'));
        fieldContainer.forEach(
          (index) => {
            selectFields.push({value: index.attributes.valuelofty, name: index.attributes.name});
          },
        );
        break;
      default:
        fieldContainer = null;
        selectFields = null;
    }
    retVal = selectFields;
  } else {
    const res = searchForReadDB(parentComponent);
    if (res !== undefined && res !== null) {
      const componentData = res.get('components');
      let searcher = '';
      componentData.every((index) => {
        if (index.attributes.sluglofty === 'itemListEndpointDatabase') {
          searcher = 'list';
          return false;
          // eslint-disable-next-line no-else-return
        } else if (index.attributes.sluglofty === 'fieldDatabaseContainer') {
          searcher = 'fields';
          return false;
        } else {
          return true;
        }
      });
      let fieldContainer = [];
      let selectFields = [];
      switch (searcher) {
        case 'list':
          fieldContainer = componentData.filter((index) => (index.attributes.sluglofty === 'itemListEndpointDatabase'));
          fieldContainer[0].attributes.components.models.forEach(
            (index) => {
              selectFields.push({value: index.attributes.valuelofty, name: index.attributes.name});
            },
          );
          break;
        case 'fields':
          fieldContainer = componentData.filter((index) => (index.attributes.sluglofty === 'fieldDatabaseContainer'));
          fieldContainer.forEach(
            (index) => {
              selectFields.push({value: index.attributes.valuelofty, name: index.attributes.name});
            },
          );
          break;
        default:
          fieldContainer = null;
          selectFields = null;
      }
      retVal = selectFields;
    }
  }
  return retVal;
}
