import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-google-fonts-icons', (editor, opts = {}) => {
  const config = {
    blocks: [
      'googleIcons',
    ],
    name: 'googleIcons',
    stylePrefix: 'gjs-',
    labelGoogleIcons: 'Iconos de Google Fonts',
    defText: 'Sample',
    category: 'Iconos',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }

  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
