import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import {Delete, Visibility} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Box, IconButton} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  boxItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #585858',
  },
});

function ListFunctionPage({
  loading, data, removeItem,
  addFunction, viewFunction,
}) {
  const classes = useStyles();
  return (
    <Container>
      {
        loading ? (
          <div>
            <h2>Cargando ...</h2>
          </div>
        ) : (
          <div>
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Button
                onClick={() => {
                  if (addFunction) {
                    addFunction();
                  }
                }}
                style={{
                  border: '1px solid #FFFFFF',
                  color: '#fff',
                  background: '#242424 !important',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                // variant="contained"
              >
                Agregar
              </Button>
            </div>
            {
              data?.length > 0 ? (
                <Box style={{padding: '20px'}}>
                  {
                    data.map((item) => (
                      <Box className={classes.boxItem}>
                        <Typography style={{width: '40%'}}>
                          {item.label}
                        </Typography>
                        <Typography style={{width: '40%'}}>
                          {item.slug}
                        </Typography>
                        <Box style={{width: '8%'}}>
                          <IconButton
                            style={{color: '#fff'}}
                            onClick={() => {
                              if (viewFunction) {
                                viewFunction(item);
                              }
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </Box>
                        <Box style={{width: '8%'}}>
                          <IconButton
                            style={{color: '#fff'}}
                            onClick={() => {
                              if (removeItem) {
                                removeItem(item);
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    ))
                  }
                </Box>
              ) : (
                <div>
                  <h2 className="text-center">No hay Datos</h2>
                </div>
              )
            }
          </div>
        )
      }
    </Container>
  );
}

export default ListFunctionPage;
