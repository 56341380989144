/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import uniqid from 'uniqid';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  function runModalDev(model) {
    // const getIDValue = model.getTrait('idModal').attributes.value;
    // const htmlEl = document.getElementById("btn"+getIDValue);
    const componentData = model.get('components');
    const toShow = componentData.filter((index) => (index.attributes.sluglofty === 'modalComponent'));
    toShow.forEach((index) => {
      index.addAttributes({style: 'display: block'});
    });
    // const canvasHTML = editor.Canvas.getDocument();
    // console.log(canvasHTML.getElementById('btndesk'));
    // const modalHTML = canvasHTML.getElementById(getIDValue);
    // let varModal = new Modal(modalHTML, {backdrop: false});
    // varModal.show();
  }
  function hideModal(model) {
    // const getIDValue = model.getTrait('idModal').attributes.value;
    // const htmlEl = document.getElementById("btn"+getIDValue);
    const componentData = model.get('components');
    const toHide = componentData.filter((index) => (index.attributes.sluglofty === 'modalComponent'));
    toHide.forEach((index) => {
      index.addAttributes({style: 'display: none'});
    });
    // const canvasHTML = editor.Canvas.getDocument();
    // const modalHTML = canvasHTML.getElementById(getIDValue);
    // let varModal = new Modal(modalHTML);
    // console.log(varModal);
    // varModal.hide();
  }
  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('mainModalContainer')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.initModal());
      },
      /*
              -- Configure Modal Data --
        Configures the ID, traits and attributes for the modal components
      */
      initModal() {
        if (this.model.attributes.sluglofty.includes('mainModalContainer')) {
          const link = document.createElement('link');
          link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css';
          document.body.appendChild(link);
          if (!this.model.getTrait('showModal')) {
            const traitButton = {
              type: 'button',
              text: 'Abrir Modal',
              name: 'showModal',
              full: true,
              command: () => runModalDev(this.model),
            };
            this.model.addTrait(traitButton);
            const idGeneration = uniqid('modal');
            // console.log(idGeneration);
            const componentData = this.model.get('components');
            const toSetButtonID = componentData.filter((index) => (index.attributes.sluglofty === 'modalButton'));

            /* -- MAIN Attributes to trigger the modal
              data-toggle="modal"
              data-target="#myModal"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            */
            toSetButtonID.forEach((index) => {
              index.setTraits([]);
              index.addAttributes({'data-bs-target': `#${idGeneration}`, 'data-target': `#${idGeneration}`, id: `btn${idGeneration}`});
            });
            const toSetIDModal = componentData.filter((index) => (index.attributes.sluglofty === 'modalComponent'));
            toSetIDModal.forEach((index) => {
              index.setId(idGeneration);
              const traitHideButton = {
                type: 'button',
                text: 'Cerrar Modal',
                name: 'hideModal',
                full: true,
                command: () => hideModal(this.model),
              };
              index.setTraits(traitHideButton);
            });
          }
        }
      },
    }),
  });
};
