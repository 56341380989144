import {getOneDatabase, listFieldsDatabase} from 'api-lofty';
import {restoreComponentToDefault} from '../../util/grapeJsCanvasFunctions';
import {databaseFieldsGetComponent, getFieldsBucket} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  // eslint-disable-next-line consistent-return

  function typeOfVariable(component, data) {
    const searchVariable = data.find((index) => (index._id === component.value));
    return searchVariable;
  }

  function addTrait(model, type, answerValue) {
    if (type === 'local') {
      const variableData = opts.localVars;
      const optionData = variableData.filter((variable) => (variable.type === 'cartArray' || variable.typeValue === 'arrayDatabase')).map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'localVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    } else {
      const variableData = opts.globalVars;
      const optionData = variableData.filter((variable) => (variable.typeValue === 'cartArray' || variable.typeValue === 'arrayDatabase')).map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'globalVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    }
  }

  async function addCarouselHTML(model, type) {
    try {
      // console.log(model);
      let selectGroupOfVars = [];
      if (type === 'localVars') {
        selectGroupOfVars = opts.localVars;
      } else {
        selectGroupOfVars = opts.globalVars;
      }
      const getTypeVariable = model.getTrait(type).attributes.value;
      const getOption = model.getTrait(type).attributes.options;
      const filterData = getOption.find((obj) => (obj.value === getTypeVariable));
      const searchType = typeOfVariable(filterData, selectGroupOfVars);
      const {token, idProject} = opts;
      const listFieldsFromResponse = await listFieldsDatabase({
        token, idDatabase: searchType.idDatabase, idProject,
      });
      const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
      let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
      if (typeDB.data.isBucket) {
        const bucketFields = getFieldsBucket();
        resFieldsReact += bucketFields;
      }

      let htmlAdder = `
                <div data-gjs-slugLofty="itemCartData" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
            `;
      if (searchType.type === 'cartArray' || searchType.typeValue === 'cartArray') {
        htmlAdder += `
        <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
          Cantidad: 
        </div>`;
      }
      htmlAdder += '</div>';
      const htmlCarousel = `
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="cartCarouselListSection"
                data-gjs-name="Seccion de Items del Carrito"
                style="padding: 10px; width: 10%; display: flex; flex-direction: column; justify-content: center; align-items: center;"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
              </div>
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="showCartItemImage"
                data-gjs-name="Multimedia del Item"
                style="padding: 10px; width: 50%"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
              </div>
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="cartItemInfoRender"
                data-gjs-name="Seccion de Informacion del Item del Carrito"
                style="padding: 10px; width: 40%"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
                ${htmlAdder}
              </div>
            `;
      model.append(htmlCarousel);
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [{
          type: 'select', // Type of the trait
          label: 'Tipo de variable', // The label you will see in Settings
          name: 'variableHandle',
          options: [
            {value: 'local', name: 'Local'},
            {value: 'global', name: 'Global'},
          ],
          changeProp: 1,
        }],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === ('carouselCartViewer')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:variableHandle', this.updateComponent);
        this.listenTo(model, 'change:localVars', this.setCarouselComponentsLocal);
        this.listenTo(model, 'change:globalVars', this.setCarouselComponentsGlobal);
        this.listenTo(model, '', this.restoreData());
      },
      restoreData() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            if (this.model.attributes.variableHandle) {
              if (this.model.attributes.variableHandle.includes('local')) {
                addTrait(this.model, 'local', this.model.attributes.localVars);
              } else {
                addTrait(this.model, 'global', this.model.attributes.globalVars);
              }
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            const getTypeVariable = this.model.getTrait('variableHandle').attributes.value;
            if (getTypeVariable === 'local') {
              restoreComponentToDefault(this.model);
              this.model.removeTrait('globalVars');
              addTrait(this.model, 'local', '');
            } else {
              restoreComponentToDefault(this.model);
              this.model.removeTrait('localVars');
              addTrait(this.model, 'global', '');
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async setCarouselComponentsLocal() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            restoreComponentToDefault(this.model);
            addCarouselHTML(this.model, 'localVars');
          }
        } catch (error) {
          //
        }
      },
      async setCarouselComponentsGlobal() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            restoreComponentToDefault(this.model);
            addCarouselHTML(this.model, 'globalVars');
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
