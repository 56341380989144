/* eslint-disable no-await-in-loop */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import {
  listFieldUserProject, getOnePageApiPages, listPage, listUserProject,
  listSimulatorUserProject,
} from 'api-lofty';
import {getRelationInfo, getForeignFields, databaseFieldsPostComponent} from '../../util/databaseFieldsComponent';
import {searchInnerComponentInfo, validDestinationForComponent} from '../../util/grapeJsCanvasFunctions';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function getMainData(relationArray, token, idProject) {
    const retVal = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < relationArray.length; index++) {
      const tempRelation = await getRelationInfo(relationArray[index], token, idProject);
      const tempFields = await getForeignFields(relationArray[index], token, idProject);
      retVal[relationArray[index]] = {};
      retVal[relationArray[index]].fields = tempFields;
      retVal[relationArray[index]].data = tempRelation;
    }
    return retVal;
  }

  function hasInnerComponents(model) {
    let hasExistingInnerComponent = false;
    try {
      const componentData = model.get('components');
      const innerComponentChecker = model.attributes.sluglofty;
      componentData.forEach((index) => {
        if (innerComponentChecker === 'RegisterUserContainer' && index.attributes.sluglofty === 'RegisterSection') {
          hasExistingInnerComponent = true;
        } else if (innerComponentChecker === 'AuthenticationUserContainer' && index.attributes.sluglofty === 'AuthSection') {
          hasExistingInnerComponent = true;
        } else if (innerComponentChecker === 'RestorePasswordContainer' && index.attributes.sluglofty === 'ResetPasswordSection') {
          hasExistingInnerComponent = true;
        } else if (
          innerComponentChecker === 'formUpdateUserInfo'
          && (index.attributes.sluglofty.includes('DatabaseAddContainer') || index.attributes.sluglofty === 'userDefaultFieldRegisterUserContainer')
        ) {
          hasExistingInnerComponent = true;
        }
      });
      return hasExistingInnerComponent;
    } catch (error) {
      // console.log(error);
    }
    return hasExistingInnerComponent;
  }
  async function getPagesData() {
    const token = opts.token;
    const idProject = opts.idProject;
    const resData = await listPage({token, idProject});
    const noParamsPage = resData.data.filter((index) => (!index.url.match(/:|\*/)));
    const retVal = noParamsPage.map((index) => ({value: index.url, name: index.url}));
    return retVal;
  }

  async function restoreResponseLogout(model) {
    if (model.attributes.actionLogout === 'message') {
      const alertMessage = {
        type: 'text',
        name: 'alertMessage',
        label: 'Mensaje',
        changeProp: 1,
      };
      model.addTrait(alertMessage);
    } else {
      const options = await getPagesData();
      const hrefRedirect = {
        type: 'select',
        name: 'redirectPage',
        label: 'Redireccionar',
        changeProp: 1,
        options,
        default: model.attributes.redirectPage,
      };
      model.addTrait(hrefRedirect);
    }
  }

  async function restorePasswordParams(model, value) {
    let paramsExist = false;
    const filterData = [];
    const token = opts.token;
    const idPage = opts.idPage;
    const resPage = await getOnePageApiPages({token, _id: idPage});
    const urlParamsRaw = resPage.data.url.split('/');
    urlParamsRaw.forEach((index) => {
      if (index.includes(':')) {
        paramsExist = true;
        const temp = index.split(':');
        const paramData = temp[1];
        filterData.push({value: paramData, name: paramData});
      }
    });
    if (paramsExist) {
      const traitPassword = {
        type: 'select',
        name: 'filterRestore',
        label: 'Filter',
        changeProp: 1,
        options: filterData,
        default: value,
      };
      model.addTrait(traitPassword);
    }
  }

  async function restoreResponseData(model) {
    try {
      if (model.attributes.actionRegister) {
        const traitActionRegister = {
          type: 'select',
          name: 'actionRegister',
          label: 'Accion',
          changeProp: 1,
          options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
          default: model.attributes.actionRegister,
        };
        model.addTrait(traitActionRegister);
        if (model.attributes.actionRegister === 'message') {
          const alertMessage = {
            type: 'text',
            name: 'alertMessage',
            label: 'Mensaje',
            changeProp: 1,
          };
          model.addTrait(alertMessage);
        } else if (model.attributes.actionRegister === 'redirect') {
          const options = await getPagesData();
          const hrefRedirect = {
            type: 'select',
            name: 'redirectPage',
            label: 'Redireccionar',
            changeProp: 1,
            options,
            default: model.attributes.redirectPage,
          };
          model.addTrait(hrefRedirect);
        }
      } else {
        const traitActionRegister = {
          type: 'select',
          name: 'actionRegister',
          label: 'Accion',
          changeProp: 1,
          options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
          default: '',
        };
        model.addTrait(traitActionRegister);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (
          attr['data-gjs-sluglofty']
          && (
            attr['data-gjs-sluglofty'].value.includes('RegisterUserContainer')
            || attr['data-gjs-sluglofty'].value.includes('RestorePasswordContainer')
            || attr['data-gjs-sluglofty'].value.includes('AuthenticationUserContainer')
            || attr['data-gjs-sluglofty'].value.includes('UpdateUserPasswordContainer')
            || attr['data-gjs-sluglofty'].value.includes('logoutUserButtonComponent')
            || attr['data-gjs-sluglofty'].value.includes('formUpdateUserInfo')
          )
        ) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
        this.listenTo(model, 'change:filterRestore', this.filterAdditionAttribute);
        this.listenTo(model, 'change:actionRegister', this.actionForRegister);
        this.listenTo(model, 'change:actionLogout', this.actionForLogout);
      },
      async updateComponent() {
        const hasExistingInnerComponent = hasInnerComponents(this.model);
        if (hasExistingInnerComponent
          && (this.model.attributes.sluglofty === 'RegisterUserContainer' || this.model.attributes.sluglofty === 'AuthenticationUserContainer')) {
          restoreResponseData(this.model);
        }
        if (this.model.attributes.sluglofty === 'formUpdateUserInfo' && this.model.attributes.actionLogout) {
          const traitActionLogout = {
            type: 'select',
            name: 'actionLogout',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionLogout);
          restoreResponseLogout(this.model);
        }
        if (this.model.attributes.sluglofty === 'RestorePasswordContainer' && this.model.attributes.filterRestore) {
          restorePasswordParams(this.model, this.model.attributes.filterRestore);
          if (this.model.attributes.actionLogout) {
            const traitActionLogout = {
              type: 'select',
              name: 'actionLogout',
              label: 'Accion',
              changeProp: 1,
              options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
              default: '',
            };
            this.model.addTrait(traitActionLogout);
            restoreResponseLogout(this.model);
          }
        }
        if (this.model.attributes.sluglofty === 'AuthenticationUserContainer' && !hasExistingInnerComponent) {
          const htmlAdder = `
            <form
              data-gjs-slugLofty="AuthSection"
              data-gjs-name="Formulario de Autenticacion"
              data-gjs-draggable="[title=AuthenticationContainer]"
              style="padding: 10px;"
              data-gjs-resizable="{bc: 1}"
            >
              <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="AuthInputSection" data-gjs-name="Seccion de Campo de Autenticacion" style="padding: 10px">
                <label data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" data-gjs-slugLofty="inputUserEmailAuthLabel" data-gjs-name="Etiqueta de Correo de Autenticacion">E-Mail</label><br/>
                <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="InputUserAuth" data-gjs-name="Entrada de Correo de Autenticacion" name="loftyEmail" required/>
              </div>
              <div data-gjs-slugLofty="AuthPasswordSection" data-gjs-name="Seccion de Contraseña de Autenticacion" style="padding: 10px">
              <label data-gjs-draggable="false" data-gjs-slugLofty="inputPasswordAuthLabel" data-gjs-name="Etiqueta de Contraseña de Autenticacion">Password</label><br/>
                <input data-gjs-resizable="{bc: 1}" type="password" data-gjs-slugLofty="PasswordUserAuth" data-gjs-name="Entrada de Contraseña de Autenticacion" name="loftyPassword" required/>
              </div>
              <button
                type="submit"
                data-gjs-slugLofty="loginUserButton"
                data-gjs-name="Boton Iniciar Sesion"
                data-gjs-resizable="{bc: 1}"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Login</div>
              </button>
            </form>
          `;
          this.model.append(htmlAdder);
          const traitActionRegister = {
            type: 'select',
            name: 'actionRegister',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionRegister);
        } else if (this.model.attributes.sluglofty === 'RegisterUserContainer' && !hasExistingInnerComponent) {
          // Capture fields required for register / Default Fields
          try {
            const token = opts.token;
            const idProject = opts.idProject;
            const getProjectInfo = await listUserProject({token, idProject});
            const infoProject = getProjectInfo.data.filter((index) => index.type === 'email');
            const resFields = await listFieldUserProject({token, idProject});
            let htmlAdder = `
              <form
                data-gjs-slugLofty="RegisterSection"
                data-gjs-name="Formulario Registrar"
                data-gjs-draggable="[title=RegisterUserContainer]"
                title="RegisterMainContainer"
                style="padding: 10px"
                data-gjs-resizable="{bc: 1}"
              >
            `;
            // Default Fields
            htmlAdder += `
                  <div
                    data-gjs-slugLofty="userDefaultFieldRegisterUserContainer"
                    data-gjs-valuelofty="loftyEmail"
                    data-gjs-name="Lofty Correo"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-draggable="false" data-gjs-slugLofty="formLabelDefaultFieldRegisterUser" data-gjs-name="Etiqueta Lofty Correo">Email</label><br/>
                    <input data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" type="text" data-gjs-slugLofty="formInputRegisterUser" data-gjs-name="Entrada Lofty Correo" placeholder="Lofty Email" name="loftyEmail" required/>
                  </div>

                  <div
                    data-gjs-slugLofty="userDefaultFieldRegisterUserContainer"
                    data-gjs-valuelofty="loftyPassword"
                    data-gjs-name="Lofty Contraseña"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-draggable="false" data-gjs-slugLofty="formLabelDefaultFieldRegisterUser" data-gjs-name="Etiqueta Lofty Contraseña">Password</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="password" data-gjs-draggable="false" data-gjs-slugLofty="formPasswordDefaultFieldRegisterUser" data-gjs-name="Entrada Lofty Contraseña" name="loftyPassword" required/>
                  </div>

                  <div
                    data-gjs-slugLofty="userDefaultFieldRegisterUserContainer"
                    data-gjs-valuelofty="loftyName"
                    data-gjs-name="Lofty Nombre"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelDefaultFieldRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta Lofty Nombre">Name</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-draggable="false" data-gjs-slugLofty="formInputDefaultFieldRegisterUser" data-gjs-name="Entrada Lofty Nombre" placeholder="Name" name="loftyName" required/>
                  </div>

                `;
            if (infoProject[0] && infoProject[0]?.haveUserName) {
              htmlAdder += `
              <div
                    data-gjs-slugLofty="userDefaultFieldRegisterUserContainer"
                    data-gjs-valuelofty="loftyUsername"
                    data-gjs-name="Lofty Username"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelDefaultFieldRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta User Name">UserName</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-draggable="false" data-gjs-slugLofty="formInputDefaultFieldRegisterUser" data-gjs-name="Entrada Lofty UserName" placeholder="UserName" name="loftyUserName" required/>
                  </div>
              `;
            }
            resFields.data.map((index) => {
              if (index.type === 'string') {
                htmlAdder += `
                  <div
                    data-gjs-slugLofty="fieldUserRegisterContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelStringRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="formInputRegisterUser" data-gjs-draggable="false" data-gjs-name="${index.name} Texto" placeholder="${index.label}" name="${index.name}" `;
                if (index.required) {
                  htmlAdder += 'required';
                }
                htmlAdder += `/>
                  </div>
                `;
              } else if (index.type === 'date') {
                htmlAdder += `
                  <div
                    data-gjs-slugLofty="fieldUserRegisterContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="date" data-gjs-slugLofty="formDateInputRegisterUser" data-gjs-draggable="false" data-gjs-name="${index.name} Fecha" value="2018-07-22" name="${index.name}"`;
                if (index.required) {
                  htmlAdder += 'required';
                }
                htmlAdder += `/>
                  </div>
                `;
              } else if (index.type === 'number') {
                htmlAdder += `
                  <div
                    data-gjs-slugLofty="fieldUserRegisterContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelNumberRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="number" data-gjs-slugLofty="formNumberInputRegisterUser" data-gjs-draggable="false" data-gjs-name="${index.name} Numero" placeholder="${index.label}"  name="${index.name}"`;
                if (index.required) {
                  htmlAdder += 'required';
                }
                htmlAdder += `/>
                  </div>
                `;
              } else if (index.type === 'boolean') {
                htmlAdder += `
                  <div
                    data-gjs-slugLofty="fieldUserRegisterContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=RegisterMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelBooleanRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="checkbox" data-gjs-draggable="false" data-gjs-slugLofty="formBooleanInputRegisterUser" data-gjs-name="${index.name} Casilla" name="${index.name}"`;
                if (index.required) {
                  htmlAdder += 'required';
                }
                htmlAdder += `/>
                    <label data-gjs-slugLofty="formLabelRegisterUserCheckbox" data-gjs-draggable="false" data-gjs-name="${index.label} Booleano"> ${index.name} </label>
                  </div>
                `;
              }
            });
            htmlAdder += `
              <button
                type="submit"
                data-gjs-slugLofty="registerUserButton"
                data-gjs-name="Boton Registrar"
                data-gjs-draggable="[title=RegisterMainContainer]"
                data-gjs-resizable="{bc: 1}"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Registrar</div>
              </button>
            </form>`;
            this.model.append(htmlAdder);
            const traitActionRegister = {
              type: 'select',
              name: 'actionRegister',
              label: 'Accion',
              changeProp: 1,
              options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
              default: '',
            };
            this.model.addTrait(traitActionRegister);
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty === 'RestorePasswordContainer' && !hasExistingInnerComponent) {
          const componentData = this.model.get('components');
          try {
            if (componentData.models.length < 3) {
              let paramsExist = false;
              const filterData = [];
              const token = opts.token;
              const idPage = opts.idPage;
              const resPage = await getOnePageApiPages({token, _id: idPage});
              const urlParamsRaw = resPage.data.url.split('/');
              urlParamsRaw.forEach((index) => {
                if (index.includes(':')) {
                  paramsExist = true;
                  const temp = index.split(':');
                  const paramData = temp[1];
                  filterData.push({value: paramData, name: paramData});
                }
              });
              if (paramsExist) {
                const traitPassword = {
                  type: 'select',
                  name: 'filterRestore',
                  label: 'Filter',
                  changeProp: 1,
                  options: filterData,
                  default: '',
                };
                this.model.addTrait(traitPassword);
                const htmlAdder = `
              <form
                data-gjs-slugLofty="ResetPasswordSection"
                data-gjs-name="Formulario Resetear Contraseña"
                data-gjs-draggable="[title=RestorePasswordContainer]"
                style="padding: 10px"
                title="passwordReset"
                data-gjs-resizable="{bc: 1}"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-draggable="[title=passwordReset]" data-gjs-slugLofty="newPasswordDiv" data-gjs-name="Seccion Nueva Contraseña" style="padding: 10px">
                  <label data-gjs-draggable="false" data-gjs-slugLofty="newPasswordLabel" data-gjs-name="Etiqueta Nueva Contraseña">New Password</label><br/>
                  <input data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" type="password" data-gjs-slugLofty="NewPasswordUser" data-gjs-name="Entrada Nueva Contraseña" name="newPassword" required />
                </div>
                <div data-gjs-resizable="{bc: 1}" data-gjs-draggable="[title=passwordReset]" data-gjs-slugLofty="newPasswordDiv" data-gjs-name="Seccion Confirmar Nueva Contraseña" style="padding: 10px">
                  <label data-gjs-draggable="false" data-gjs-slugLofty="confirmPasswordLabel" data-gjs-name="Etiqueta Confirmar Nueva Contraseña">Confirm Password</label><br/>
                  <input data-gjs-resizable="{bc: 1}" data-gjs-draggable="false" type="password" data-gjs-slugLofty="ConfirmPasswordUser" data-gjs-name="Entrada Confirmar Nueva Contraseña" name="confirmPassword"required />
                </div>
                <button
                  type="submit"
                  data-gjs-slugLofty="resetUserButton"
                  data-gjs-name="Boton Resetear Contraseña"
                  data-gjs-draggable="[title=RegisterMainContainer]"
                  data-gjs-resizable="{bc: 1}"
                >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Resetear Contraseña</div>
                </button>
              </form>
            `;
                this.model.append(htmlAdder);
              }
            }
          } catch (error) {
            // console.log(error);
          }
          const traitActionLogout = {
            type: 'select',
            name: 'actionLogout',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionLogout);
          if (this.model.attributes.actionLogout) {
            restoreResponseLogout(this.model);
          }
        } else if (this.model.attributes.sluglofty === 'logoutUserButtonComponent' || this.model.attributes.sluglofty === 'UpdateUserPasswordContainer') {
          const traitActionLogout = {
            type: 'select',
            name: 'actionLogout',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionLogout);
          if (this.model.attributes.actionLogout) {
            restoreResponseLogout(this.model);
          }
        } else if (this.model.attributes.sluglofty === 'formUpdateUserInfo' && !searchInnerComponentInfo(this.model, 'userDefaultFieldRegisterUserContainer')) {
          try {
            const token = opts.token;
            const idProject = opts.idProject;
            // const getProjectInfo = await listUserProject({token, idProject});
            // const infoProject = getProjectInfo.data.filter((index) => index.type === 'email');
            const resFields = await listFieldUserProject({token, idProject});
            const userInfoData = await listSimulatorUserProject({token, idProject});
            const listRelations = [];
            resFields.data.map((index) => {
              if (index.type === 'relation') {
                listRelations.push(index.relationDatabase);
              }
            });
            const dataCaptureInfo = await getMainData(listRelations, token, idProject);
            let htmlAdder = '';
            htmlAdder += `
              <div
                    data-gjs-slugLofty="userDefaultFieldRegisterUserContainer"
                    data-gjs-valuelofty="loftyName"
                    data-gjs-name="Lofty Nombre"
                    data-gjs-draggable="[title=UpdateMainContainer]"
                    style="padding: 10px; margin-top: 5px; margin-bottom: 5px"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <label data-gjs-slugLofty="formLabelDefaultFieldRegisterUser" data-gjs-draggable="false" data-gjs-name="Etiqueta Lofty Name">Lofty Name</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-draggable="false" data-gjs-slugLofty="formInputDefaultFieldUpdateUser" data-gjs-name="Entrada Lofty Name" placeholder="loftyName" name="loftyName" required/>
                  </div>
              `;
            // eslint-disable-next-line max-len
            const userFieldsHtml = databaseFieldsPostComponent(resFields.data, userInfoData, dataCaptureInfo);
            htmlAdder += userFieldsHtml;
            htmlAdder += `<button
            type="submit"
            data-gjs-slugLofty="updateUserInfoButton"
            data-gjs-name="Boton Actualizar Informacion de Usuario"
            data-gjs-draggable="[title=UpdateMainContainer]"
            data-gjs-resizable="{bc: 1}"
          >
            <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Actualizar Informacion</div>
          </button>`;
            this.model.append(htmlAdder);
            const functionSetter = this.model.attributes.components.models;
            functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'formUpdateUserInfo')}));
            const traitActionLogout = {
              type: 'select',
              name: 'actionLogout',
              label: 'Accion',
              changeProp: 1,
              options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
              default: '',
            };
            this.model.addTrait(traitActionLogout);
          } catch (error) {
            // console.log(error);
          }
        }
      },
      filterAdditionAttribute() {
        try {
          const traitSelection = this.model.getTrait('filterRestore');
          const filterValue = traitSelection.attributes.value;
          const {filterParam} = this.model.getAttributes();
          if (filterParam) {
            this.model.removeAttributes('filterParam');
            this.model.setAttributes({filterParam: filterValue});
          } else {
            this.model.addAttributes({filterParam: filterValue});
          }
        } catch (error) {
          //
        }
      },
      async actionForRegister() {
        try {
          const traitSelection = this.model.getTrait('actionRegister').attributes.value;
          if (traitSelection === 'message') {
            this.model.removeTrait('redirectPage');
            const alertMessage = {
              type: 'text',
              name: 'alertMessage',
              label: 'Mensaje',
              changeProp: 1,
            };
            this.model.addTrait(alertMessage);
          } else if (traitSelection === 'redirect') {
            this.model.removeTrait('alertMessage');
            const options = await getPagesData();
            const hrefRedirect = {
              type: 'select',
              name: 'redirectPage',
              label: 'Redireccionar',
              changeProp: 1,
              options,
            };
            this.model.addTrait(hrefRedirect);
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async actionForLogout() {
        try {
          const traitSelection = this.model.getTrait('actionLogout').attributes.value;
          if (traitSelection === 'message') {
            this.model.removeTrait('redirectPage');
            const alertMessage = {
              type: 'text',
              name: 'alertMessage',
              label: 'Mensaje',
              changeProp: 1,
            };
            this.model.addTrait(alertMessage);
          } else if (traitSelection === 'redirect') {
            this.model.removeTrait('alertMessage');
            const options = await getPagesData();
            const hrefRedirect = {
              type: 'select',
              name: 'redirectPage',
              label: 'Redireccionar',
              changeProp: 1,
              options,
            };
            this.model.addTrait(hrefRedirect);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
