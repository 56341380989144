/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {listFieldsDatabase} from 'api-lofty';
import {searchForReadDB} from '../../util/searchParentComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  // eslint-disable-next-line consistent-return

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('dynVideo')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
      },
      async updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('dynVideo')) {
            // console.log('Hey');
            this.model.removeTrait('stringParams');
            const isReadDB = searchForReadDB(this.model);
            // console.log(isReadDB);
            if (isReadDB) {
              // console.log('何か');
              const token = opts.token;
              const idProject = opts.idProject;
              const idDatabase = isReadDB.attributes.dbSelect;
              const resFieldData = await listFieldsDatabase({idDatabase, idProject, token});
              const stringFieldsFromRead = [];
              const defaultValue = this.model.attributes.stringParams || '';
              resFieldData.data.forEach((index) => {
                if (index.type === 'string') {
                  stringFieldsFromRead.push({name: index.label, value: index.name});
                }
              });
              const traitPagesWithParams = {
                type: 'select',
                name: 'stringParams',
                label: 'Valor URL:',
                changeProp: 1,
                options: stringFieldsFromRead,
                default: defaultValue,
              };
              this.model.addTrait(traitPagesWithParams);
            }
          } else if (this.model.getTrait('hrefManager')) {
            this.model.removeTrait('stringParams');
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
