import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-calendar', (editor, opts = {}) => {
  if (opts.calendarPlugin) {
    const config = {
      blocks: [
        'calendarComponent',
      ],
      name: 'calendarComponent',
      stylePrefix: 'gjs-',
      label: 'Calendar Component',
      defText: 'Sample',
      category: 'Calendar',
      ...opts,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in config) {
      if (!(name in opts)) opts[name] = config[name];
    }

    loadBlocks(editor, config);
    loadComponents(editor, opts);
  }
});
