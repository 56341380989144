export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  .item {
    height: 200px;
    margin: 50px auto;
    padding-top: 75px;
    text-align: center;
  }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg class="icon-lofty-component-block" width="50px" height="50px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 18V6C2 4.34315 3.34315 3 5 3H7C8.65685 3 10 4.34315 10 6V18C10 19.6569 8.65685 21 7 21H5C3.34315 21 2 19.6569 2 18Z" stroke="#000000" stroke-width="1.5"/>
    <path d="M16 3H18C20.2091 3 22 4.79086 22 7V17C22 19.2091 20.2091 21 18 21H16" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 12H18M18 12L15 9M18 12L15 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelScroll}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Animated Scroll.', id: 'component-animated-scroll-container-lofty-editor'},
    disable: opts.isDisabled,
    content: `
      <div
        data-gjs-slugLofty="animatedScrollContainer"
        data-gjs-name="Contenedor Animado"
        data-gjs-resizable="{bc: 1}"
        style="min-height: 100px; padding: 10px"
        data-aos="fade-up"
      >
        <span>${opts.defText}</span>
      </div>
    ${style}`,
  });
};
