/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('wrapper');
  const dModel = defaultType.model;
  const modalHtml = `<div
  data-gjs-slugLofty="modalInitPage"
  data-gjs-name="Modal de Inicio de Pagina"
  data-gjs-draggable="false"
  data-gjs-stylable="false"
  data-gjs-droppable="false"
  style="padding: 10px;"
>
  <div
  data-gjs-slugLofty="modalInitContainer"
  data-gjs-name="Modal"
  data-gjs-resizable="{bc: 1}"
  style="min-height: 100px; padding: 10px;"
  data-gjs-draggable="false"
  >
    <div data-gjs-name="Cabecera de Modal" data-gjs-slugLofty="div" style="background-color: rgba(0, 74, 143, 1); padding: 10px; color: #FFF">
      <h2>Lorem Ipsum</h2>
    </div>
    <div data-gjs-name="Contenido de Modal" data-gjs-slugLofty="div" style="padding: 10px;">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non vehicula leo, vitae consectetur diam.</p>
      <p>${opts.defText}</p>
    </div>
    <div data-gjs-name="Pie de Modal" data-gjs-slugLofty="div" style="background-color: rgba(0, 74, 143, 1); padding: 10px; color: #FFF">
      <h3>Lorem Ipsum</h3>
    </div>
  </div>
</div>`;

  function searcherModal(model) {
    const componentData = model.get('components');
    // console.log(componentData.models);
    const hasModal = componentData.filter((index) => (index.attributes.sluglofty === 'modalInitPage'));
    let retVal = false;
    if (hasModal.length > 0) {
      retVal = true;
    }
    return retVal;
  }

  function handleView(model) {
    const componentData = model.get('components');
    const toHide = componentData.filter((index) => (index.attributes.sluglofty === 'modalInitPage'));
    // console.log(componentData, toHide);
    toHide.forEach((index) => {
      const styleData = index.getStyle();
      if (!styleData.display) {
        index.setStyle({display: 'none'});
      } else if (styleData.display === 'none') {
        index.setAttributes({style: 'display: block; padding: 10px;'});
      } else {
        index.setAttributes({style: 'display: none'});
      }
    });
  }

  function removeModal(model) {
    const componentData = model.get('components');
    const eliminateComponent = componentData.filter((index) => (index.attributes.sluglofty === 'modalInitPage'));
    eliminateComponent[0].collection.remove(eliminateComponent[0]);
    model.removeTrait('modalViewHandler');
    model.removeTrait('modalDeletion');
    model.addAttributes({hasModal: false});
    const traitAddModal = {
      type: 'button',
      label: 'Modal de Pagina',
      text: 'Modal', // The label you will see in Settings
      name: 'addModal',
      changeProp1: 1,
      // eslint-disable-next-line no-use-before-define
      command: () => typeTester(this),
    };
    model.addTrait(traitAddModal);
  }

  function typeTester(model) {
    // console.log(model);
    model.removeTrait('addModal');
    model.setAttributes({hasModal: true});
    if (!searcherModal(model)) {
      model.append(modalHtml, {at: 0});
    }
    const traitViewModal = {
      type: 'button',
      label: 'Vista del Modal',
      text: 'Visualizar/Ocultar', // The label you will see in Settings
      name: 'modalViewHandler',
      changeProp1: 1,
      command: () => handleView(model),
    };
    const traitEliminateModal = {
      type: 'button',
      label: 'Eliminar Modal',
      text: 'Eliminar', // The label you will see in Settings
      name: 'modalDeletion',
      changeProp1: 1,
      command: () => removeModal(model),
    };
    model.addTrait(traitViewModal);
    model.addTrait(traitEliminateModal);
  }

  dc.addType('wrapper', {
    model: dModel.extend(
      {
        init() {
          dModel.prototype.init.apply(this);
          this.listenTo(this, 'change:provider', this.updateTypeTraits);
          let typeTrait = this.get('traits').find((el) => el.get('name') === 'type');
          // setInterval(function () {
          //   console.log(this);
          //   console.log(counts);
          //   if (counts > 10) {
          //     clearInterval();
          //   } else {
          //     counts = counts + 1;
          //   }
          //   console.log('Something happening');
          // }, 5000);
          // console.log(`res ${res}`);
          if (!typeTrait) {
            typeTrait = this.get('traits').add({
              type: 'button',
              label: 'Modal de Pagina',
              text: 'Modal', // The label you will see in Settings
              name: 'addModal',
              changeProp1: 1,
              command: () => typeTester(this),
            });
          }
        },
      },
    ),

    // view: defaultView.extend({
    //   init({model}) {
    //     this.listenTo(model, 'change:addModal', this.configureModal);
    //   },
    //   configureModal() {
    //     console.log(this);
    //   },
    // }),
  });
};
