export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
      <svg width="40px" height="40px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 1.007l12.999.17.43.501-1.82 12.872-.57.489-13-.17-.43-.502L1.93 1.495l.57-.488zM1.18 13.885l11.998.157 1.68-11.882L2.86 2.003 1.18 13.885zm5.791-3.49l-.14.991 5 .066.14-.99-5-.066zm1.71-2.457l-3.663-2.93-.692.796 2.636 2.112L3.739 9.95l.465.812L8.68 7.938z"/></svg>
    </div>
    <div class="gjs-block-label">
      ${opts.labelVariableComp}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente de conteo de estados de tipo arreglo.', id: 'component-variable-array-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="variableArrayComponent"
        data-gjs-name="Componente de Conteo de Arreglo"
        style="padding: 10px"
        data-gjs-resizable="{bc: 1}"
      >
      </div>
    ${style}`,
  });
};
